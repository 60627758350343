import React from "react";
import { cp } from "../utils";

export interface DoubleButtonProps {}

const DoubleButton: React.FC<DoubleButtonProps> = (props) => {
  return <div className={cp("double-button")}>{props.children}</div>;
};

export default DoubleButton;
