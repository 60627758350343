import {
  defined,
  makeid,
  useCombinedRef,
  useSecondEffect,
} from "@adv-libs/utils";
import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { findDOMNode } from "react-dom";
import AdvControlLabel from "../AdvControlLabel";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import AdvInputBigTooltip from "./AdvInputBigTooltip";
import EndIcon from "./EndIcon";
import StartIcon from "./StartIcon";
import useAutoWidthMeasure from "./useAutoWidthMeasure";

export interface AdvInputProps extends AdvCommonControlProps {
  type?: string;
  height?: number;
  cleave?: CleaveOptions;
  lowercase?: boolean;
  uppercase?: boolean;
  maxLength?: number;
  minLength?: number;
  fieldName?: string;
  onBlur?: (e?) => any;
  onFocus?: (e?) => any;
  isClearable?: boolean;
  autoWidth?: boolean;
  minWidth?: number;
  preventChars?: string;
}

const name = "input";

const AdvInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvInputProps>
>((props, ref) => {
  const cleaveRef = useRef<any>();
  const inputRef = useRef<HTMLInputElement>();
  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipIsBig =
    typeof props.tooltip === "string" ? false : !!props.tooltip?.isBig;

  const id = useMemo(() => {
    return !props.autocomplete ? makeid(5) : null;
  }, []);

  const fieldName = useMemo(() => {
    return id ? props.fieldName + "$" + id : props.fieldName;
  }, [id, props.fieldName]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value || "";

      if (props.preventChars) {
        const regex = new RegExp(`${props.preventChars}`, "g");
        value = value.replace(regex, "");
      }

      if (props.lowercase) {
        props.onCommit(value.toLowerCase());
        return;
      } else if (props.uppercase) {
        props.onCommit(value.toUpperCase());
        return;
      } else {
        props.onCommit(value);
      }
    },
    [props]
  );

  const value = useMemo(() => {
    return defined(props.value) ? props.value : "";
  }, [props.value]);

  const hasValue = value;

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: hasValue,
    labelRef: labelRef,
    value: value,
  });

  const {
    onBlur,
    onFocus,
    className,
    style,
    onAnimationStart,
    onAnimationEnd,
  } = useControl({
    name,
    value,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    height: props.height,
    notify: props.notify,
  });

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  const handleStopClearClickPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);

      const $input = controlRef.current.querySelector("input");
      if ($input) {
        $input.blur();
      }
    },
    [props.onCommit, onBlur]
  );

  const handleMouseEnter = useCallback((e) => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (tooltipIsBig) return;
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, tooltipIsBig, props.tooltip, hasValue, focused]);

  // Workaround for https://github.com/nosir/cleave.js/issues/523
  useEffect(() => {
    let $input: HTMLInputElement;
    if (cleaveRef.current) {
      $input = findDOMNode(cleaveRef.current) as HTMLInputElement;
      $input.addEventListener("blur", onBlur);
    }
    return () => {
      if ($input) {
        $input.removeEventListener("blur", onBlur);
      }
    };
  }, [onBlur]);

  /**
   * If component comes disabled or readonly, blur it
   */
  useSecondEffect(() => {
    if (props.disabled || props.readOnly) {
      onBlur();
    }
  }, [props.disabled, props.readOnly]);

  const renderAsCleave = useCallback(() => {
    return (
      <Cleave
        ref={useCombinedRef(ref, cleaveRef)}
        options={props.cleave}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        maxLength={props.maxLength}
        minLength={props.minLength}
        tabIndex={props.tabIndex}
      />
    );
  }, [
    props.cleave,
    value,
    handleChange,
    onFocus,
    props.maxLength,
    props.minLength,
    props.tabIndex,
  ]);

  const renderAsInput = useCallback(() => {
    return (
      <input
        ref={useCombinedRef(ref, inputRef)}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}
        type={props.type}
        value={String(value)}
        name={fieldName}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={props.maxLength}
        minLength={props.minLength}
        disabled={props.disabled}
        readOnly={props.readOnly}
        tabIndex={props.tabIndex}
      />
    );
  }, [
    props.type,
    value,
    fieldName,
    handleChange,
    onFocus,
    onBlur,
    props.disabled,
    props.readOnly,
    props.maxLength,
    props.minLength,
    props.tabIndex,
  ]);

  useLayoutEffect(() => {
    (controlRef.current as any).commit = (value: any) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  const handleFocused = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip]);

  const handleBlurred = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip]);

  return (
    <>
      {tooltipIsBig && focused ? (
        <AdvInputBigTooltip
          tooltip={props.tooltip}
          referenceElement={referenceElement}
        />
      ) : props.tooltip && !tooltipIsBig && finalShowTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        style={style}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={useCombinedRef(controlRef, setReferenceElement)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            startIconSize={props.startIconSize}
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.endIcon ? (
          <EndIcon
            endIcon={props.endIcon}
            endIconSpin={props.endIconSpin}
            endIconTooltip={props.endIconTooltip}
            onEndIconClick={props.onEndIconClick}
          />
        ) : null}
        <label>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              props.value ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
                onClick={handleStopClearClickPropagation}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          {props.cleave ? renderAsCleave() : renderAsInput()}
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
      </span>
    </>
  );
});

AdvInput.defaultProps = {
  type: "text",
  autocomplete: false,
};

export default AdvInput;
