import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { AdvCommonControlProps } from "../types";
import { cm, cp } from "../utils";

export interface AdvTogglerProps extends AdvCommonControlProps {
  noHeight?: boolean;
  fieldName?: string;
}

const name = "toggler";

const AdvToggler = React.forwardRef<HTMLInputElement, AdvTogglerProps>(
  (props, ref) => {
    const controlClassName = cp("control");

    const handleChange = useCallback(
      (e) => {
        if (props.readOnly) return;
        props.onCommit(e.target.checked);
      },
      [props]
    );

    const innerElem = useMemo(() => {
      return (
        <span
          data-field={props.fieldName}
          data-testid={name + "|" + props.fieldName}
          className={clsx(
            cp("control"),
            cp("control-" + name),
            cm(cp("control-" + name), {
              "no-label": !props.label,
              readonly: props.readOnly,
              "no-height": props.noHeight
            }),
            cm(controlClassName, {
              disabled: props.disabled,
              danger: props.danger
            })
          )}
        >
        <label>
          {props.label}
          <input
            ref={ref}
            type="checkbox"
            checked={props.value === true}
            onChange={handleChange}
            disabled={props.disabled}
          />
          <div className={cp("control-" + name + "__indicator")}>
            <span className={"checked-mark"} />
            <span className={"unchecked-mark"} />
          </div>
        </label>
      </span>
      );
    }, [props.fieldName, props.label, props.readOnly, props.noHeight, props.disabled, props.danger, props.value]);

    if (props.minimal) {
      return innerElem;
    }

    return <div className={props.minimal ? "r365-control r365-control--disabled" : "r365-control"}>
      {innerElem}
    </div>;
  }
);

export default AdvToggler;
