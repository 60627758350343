import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import { cm, cp } from "../utils";

export interface EndIconProps {
  endIcon?: AdvCommonControlProps["endIcon"];
  endIconSpin?: AdvCommonControlProps["endIconSpin"];
  onEndIconClick?: AdvCommonControlProps["onEndIconClick"];
  endIconTooltip?: AdvCommonControlProps["endIconTooltip"];
  endIconSize?: AdvCommonControlProps["endIconSize"];
}

const EndIcon: React.FC<EndIconProps> = (props) => {
  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const iconRef = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const style = useMemo(() => {
    const style: CSSProperties = {};
    if (props.endIconSize) {
      return {
        fontSize: props.endIconSize,
      };
    }
    return style;
  }, []);

  const handleClick = useCallback(
    (e) => {
      if (props.endIconTooltip) {
        setShowTooltip(true);
      }
      if (typeof props.onEndIconClick === "function") {
        props.onEndIconClick(e);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    [props.endIconTooltip, props.onEndIconClick]
  );

  const handleTooltipClose = useCallback(() => {
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    if (showTooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(iconRef.current);
    }
  }, [showTooltip]);

  return (
    <>
      {props.endIconTooltip && showTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.endIconTooltip}
          onClose={handleTooltipClose}
        />
      ) : null}
      <span
        ref={iconRef}
        className={clsx(
          cp("control__end-icon"),
          cm(cp("control__end-icon"), {
            clickable: !!props.onEndIconClick,
            tooltip: !!props.endIconTooltip,
          })
        )}
        onMouseDown={handleClick}
        style={style}
      >
        <Icon icon={props.endIcon} spin={props.endIconSpin} />
      </span>
    </>
  );
};

export default EndIcon;
