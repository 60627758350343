import React, { memo } from "react";
import { CalendarGroupedEventsType } from "../../types";
import CalenderTimelineBodyGroupsOfEventsGroups from "./CalendarTimelineBodyGroupsOfEventsGroups";

export interface CalendarTimelineBodyEventsScrollableProps {
  groupedEventsObject: CalendarGroupedEventsType;
}

const CalendarTimelineBodyEventsScrollable: React.FC<CalendarTimelineBodyEventsScrollableProps> =
  memo((props) => {
    return (
      <>
        <CalenderTimelineBodyGroupsOfEventsGroups
          groupedEventsObject={props.groupedEventsObject}
        />
      </>
    );
  });

export default CalendarTimelineBodyEventsScrollable;
