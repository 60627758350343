import clsx from "clsx";
import React from "react";
import { cm, cp } from "../utils";

export interface MenuListItemProps {
  preventDropdownClose?: boolean;
  expandable?: boolean;
  onClick?: (e) => any;
}

const MenuListItem: React.FC<MenuListItemProps> = (props) => {
  return (
    <div
      className={clsx(
        cp("menu-list-item"),
        cm(cp("menu-list-item"), {
          expandable: props.expandable,
        }),
        props.preventDropdownClose === false ? cp("close-dropdown") : null
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

MenuListItem.defaultProps = {
  preventDropdownClose: false,
};

export default MenuListItem;
