import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useItemsCount = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.state.items.length, [])
  );
};

export default useItemsCount;
