import { ucfirst } from "@adv-libs/utils";
import { CalendarTimelineMonthType } from "../types";

const getMonthsFromDays = (days: Date[], locale: string = "default") => {
  const months: CalendarTimelineMonthType[] = [];

  let monthBuffer: CalendarTimelineMonthType = null;

  days.forEach((day) => {
    if (
      !monthBuffer ||
      monthBuffer.month !== day.getMonth() ||
      monthBuffer.year !== day.getFullYear()
    ) {
      monthBuffer = {
        year: day.getFullYear(),
        month: day.getMonth(),
        title: ucfirst(day.toLocaleDateString(locale, { month: "long" })),
        days: 1,
      };
      months.push(monthBuffer);
    } else {
      monthBuffer.days++;
    }
  });

  return months;
};

export default getMonthsFromDays;
