import React, { memo } from "react";
import { CalendarEventType, CalendarGroupType } from "../../types";
import CalendarTimelineBodyEventsGroups from "./CalendarTimelineBodyEventsGroups";
import { useCalendarTimelineViewsContext } from "../../CalendarTimeline/CalendarTimelineViewsContext.js";

export interface CalendarTimelineBodyGroupOfEventsGroupsProps {
  group: CalendarGroupType;
  groupedEvents: CalendarEventType[][];
  startDate: Date;
  endDate: Date;
  registerChild: (element: Element) => any;
}

const CalendarTimelineBodyGroupOfEventsGroups: React.FC<CalendarTimelineBodyGroupOfEventsGroupsProps> =
  memo((props) => {
    return (
      <CalendarTimelineBodyEventsGroupsRender
        group={props.group}
        groupedEvents={props.groupedEvents}
        startDate={props.startDate}
        endDate={props.endDate}
        registerChild={props.registerChild}
      />
    );
  });

CalendarTimelineBodyGroupOfEventsGroups.displayName =
  "CalendarTimelineBodyGroupOfEventsGroups";

interface CalendarTimelineBodyEventsGroupsRenderProps {
  group: CalendarGroupType;
  groupedEvents: CalendarEventType[][];
  startDate: Date;
  endDate: Date;
  registerChild: (element: Element) => any;
}

const CalendarTimelineBodyEventsGroupsRender: React.FC<CalendarTimelineBodyEventsGroupsRenderProps> =
  memo((props) => {
    const { groupEventsOverlay: GroupEventsOverlay } =
      useCalendarTimelineViewsContext();

    return (
      <div className="rivile-calendar-body-group-of-events-groups">
        {GroupEventsOverlay ? <GroupEventsOverlay group={props.group} /> : null}
        <div
          className="rivile-calendar-body-group-of-events-groups__measure"
          ref={props.registerChild}
        >
          <CalendarTimelineBodyEventsGroups
            group={props.group}
            groupedEvents={props.groupedEvents}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        </div>
      </div>
    );
  });

export default CalendarTimelineBodyGroupOfEventsGroups;
