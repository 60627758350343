import { useTranslation } from "@adv-libs/l10n";
import React, { useCallback, useImperativeHandle, useRef } from "react";
import { AdvDatePicker } from "../AdvDatePicker";
import { cp } from "../utils";

export interface AdvActiveButtonDatesProps {
  value: Date[];
  onSave?: () => any;
  onChange: (dates: [Date, Date]) => any;
  fieldName?: string[];
}

const AdvActiveButtonDates = React.forwardRef<
  any,
  React.PropsWithChildren<AdvActiveButtonDatesProps>
>((props, ref) => {
  const fromRef = useRef();
  const toRef = useRef();

  const { t } = useTranslation();

  useImperativeHandle(
    ref,
    () => {
      return {
        from: fromRef,
        to: toRef,
      };
    },
    []
  );

  const handleFromChange = useCallback(
    (value) => {
      props.onChange([value, props.value[1]]);
    },
    [props.value, props.onChange]
  );
  const handleToChange = useCallback(
    (value) => {
      props.onChange([props.value[0], value]);
    },
    [props.value, props.onChange]
  );

  return (
    <div className={cp("control-active-button__dates")}>
      <div>
        <AdvDatePicker
          fieldName={props.fieldName[1]}
          ref={fromRef}
          label={t("Active from")}
          value={props.value[0] ?? null}
          onCommit={handleFromChange}
          isClearable
          showIcon={false}
        />
      </div>
      <div>
        <AdvDatePicker
          fieldName={props.fieldName[2]}
          ref={toRef}
          label={t("Active to")}
          value={props.value[1] ?? null}
          onCommit={handleToChange}
          isClearable
          showIcon={false}
        />
      </div>
    </div>
  );
});

export default AdvActiveButtonDates;
