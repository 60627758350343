import React, { useState } from "react";
import { useCalendarSharedContext } from "../../CalendarSharedContext";
import { useCalendarTimelineViewsContext } from "../../CalendarTimeline/CalendarTimelineViewsContext";
import { CalendarEventType, CalendarGroupType } from "../../types";
import getDayKey from "../../utils/getDayKey";
import CalendarTimelineBodyEventsGroup from "./CalendarTimelineBodyEventsGroup";

export interface CalendarTimelineBodyEventsGroupsProps {
  group: CalendarGroupType;
  groupedEvents: CalendarEventType[][];
  startDate: Date;
  endDate: Date;
}

const CalendarTimelineBodyEventsGroups: React.FC<
  CalendarTimelineBodyEventsGroupsProps
> = (props) => {
  const { days } = useCalendarSharedContext();
  const { cellView: CellView } = useCalendarTimelineViewsContext();

  return (
    <div className="rivile-calendar-body-events-groups">
      {CellView ? (
        <div className="rivile-calendar-body-events-groups__cells-container">
          {days.map((day) => {
            const key = getDayKey(day);

            return (
              <CalendarTimelineBodyEventsGroupsCell
                key={key}
                date={day}
                group={props.group}
              />
            );
          })}
        </div>
      ) : null}
      {props.groupedEvents.map((events, index) => {
        return (
          <CalendarTimelineBodyEventsGroup
            key={index}
            group={props.group}
            events={events}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        );
      })}
    </div>
  );
};

interface CalendarTimelineBodyEventsGroupsCellProps {
  group: CalendarGroupType;
  date: Date;
}

const CalendarTimelineBodyEventsGroupsCell: React.FC<
  CalendarTimelineBodyEventsGroupsCellProps
> = (props) => {
  const { cellView: CellView } = useCalendarTimelineViewsContext();
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className="rivile-calendar-body-events-groups__cell"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CellView isHover={isHover} group={props.group} date={props.date} />
    </div>
  );
};

export default CalendarTimelineBodyEventsGroups;
