import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useIsSingleSelect = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.multiple && value.singleSelect, [])
  );
};

export default useIsSingleSelect;
