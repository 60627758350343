import Editor, { EditorProps } from "@monaco-editor/react";
import React, { useCallback, useMemo, useRef } from "react";

export interface CodeEditorProps {
  value: any;
  onCommit: (value: any) => any;
  disabled?: boolean;
  language?: string;
  width?: any;
  height?: any;
}

const CodeEditor: React.FC<CodeEditorProps> = (props) => {
  const finalLanguage = props.language === "jsonc" ? "json" : props.language;
  const valueRef = useRef(props.value);

  valueRef.current = props.value;

  const options = useMemo<EditorProps["options"]>(() => {
    return {
      readOnly: props.disabled,
      minimap: {
        enabled: false,
      },
    };
  }, [props.disabled]);

  const handleEditorWillMount = useCallback(
    (monaco) => {
      if (props.language === "jsonc") {
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          comments: "ignore",
          allowComments: true,
        });
      }
    },
    [props.language]
  );

  const handleChange = useCallback(
    (value) => {
      if (props.onCommit && valueRef.current !== value) {
        props.onCommit(value);
      }
    },
    [props.onCommit, props.value]
  );

  return (
    <Editor
      options={options}
      width={props.width}
      height={props.height}
      language={finalLanguage}
      value={props.value}
      onChange={handleChange}
      beforeMount={handleEditorWillMount}
    />
  );
};

CodeEditor.defaultProps = {
  language: "javascript",
};

export default CodeEditor;
