import { Icon } from "@adv-libs/icons";
import { useTranslation } from "@adv-libs/l10n";
import {
  KeyCode,
  dateToString,
  makeid,
  stringToDate,
  useCombinedRef,
  useSecondEffect,
} from "@adv-libs/utils";
import { format as dnsDateFormat } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  default as DatePicker,
  default as ReactDatePicker,
  ReactDatePickerProps,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { findDOMNode } from "react-dom";
import { Portal } from "react-overlays";
import AdvControlLabel from "../AdvControlLabel";
import { AdvInput } from "../AdvInput";
import StartIcon from "../AdvInput/StartIcon";
import { AdvNumber } from "../AdvNumber";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import {
  AdvDatePickerContextProvider,
  useAdvDatePickerContext,
} from "./AdvDatePickerContext";
import dateParser from "./dateParser";
import {
  closeDatePickers,
  registerDatePicker,
  unregisterDatePicker,
} from "./datePickerRegister";
import {
  __getGlobalLocale,
  __setGlobalDateFormat,
  __setGlobalLocale,
  getDateTimeDisplayFormat,
} from "./globalState";
import useAutoWidthMeasure from "./useAutoWidthMeasure";

export interface AdvDatePickerStaticMethods {
  setFormatCallback: (getFormat: (time?: boolean) => string | null) => any;
  setLocaleCallback: (getLocale: () => string) => any;
  formatDate: (dateString: string, displayFormat?: string) => any;
  formatDateTime: (dateString: string, displayFormat?: string) => any;
}

export type AdvDatePickerFastRange = {
  label: string;
  onSelect: (currentDate: Date) => Date;
};
export interface AdvDatePickerProps extends AdvCommonControlProps {
  format?: string;
  displayFormat?: string;
  time?: boolean;
  defaultIsCurrentDate?: boolean;
  defaultTime?: string;
  pickerProps?: Omit<ReactDatePickerProps, "onChange">;
  isClearable?: boolean;
  fieldName?: string;
  showIcon?: boolean;
  height?: number;
  minWidth?: number;
  autoWidth?: boolean;
  ranges?: AdvDatePickerFastRange[];
  customRange?: (currentDate: Date, value: number) => Date;
}

const name = "date";

const isValidTime = (time: string) => {
  try {
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return regex.test(time);
  } catch (err) {
    return false;
  }
};

const AdvDatePicker: React.ForwardRefExoticComponent<
  AdvDatePickerProps & {
    children?: React.ReactNode;
  } & React.RefAttributes<DatePicker>
> &
  AdvDatePickerStaticMethods = React.forwardRef<
  ReactDatePicker,
  React.PropsWithChildren<AdvDatePickerProps>
>((props, ref) => {
  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const format = props.format ?? (props.time ? "iso" : "iso-short");

  const displayFormat = useMemo(() => {
    return getDateTimeDisplayFormat(props.time, props.displayFormat);
  }, [props.time, props.displayFormat]);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { pt } = useTranslation();

  const id = useMemo(() => {
    return makeid(5);
  }, []);

  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const timeValueRef = useRef<string>(props.defaultTime);
  const refocusRef = useRef(false);
  const blurByTabRef = useRef(false);
  const clickOutsideRef = useRef(false);
  const startIconClickRef = useRef(false);
  const datePickerRef = useRef<any>();
  const selectedRef = useRef(false);
  const lastValueRef = useRef(props.value);

  const deferredCalendarClose = useCallback(() => {
    setTimeout(() => {
      setCalendarIsOpen(false);
    }, 0);
  }, []);

  const handleChange = useCallback(
    (date: Date) => {
      if (!date) {
        props.onCommit(null);
      } else {
        if (props.time && timeValueRef.current) {
          let [hour, minute] = timeValueRef.current.split(":");
          if (hour) {
            hour = hour.padEnd(2, "0");
            date.setHours(parseInt(hour));
          }
          if (minute) {
            minute = minute.padEnd(2, "0");
            date.setMinutes(parseInt(minute));
          }
        }

        let dateString: string = null;

        dateString = dateToString(date, format);

        props.onCommit(dateString);
      }
    },
    [props.onCommit, format]
  );

  const handleRangeSelect = useCallback(
    (date: Date) => {
      handleChange(date);
      deferredCalendarClose();
    },
    [handleChange, deferredCalendarClose]
  );

  const getInputComponent = useCallback(() => {
    const $datePicker = findDOMNode(datePickerRef.current) as HTMLDivElement;
    const $input = $datePicker.querySelector("input");
    return $input;
  }, []);

  const inputIsFocused = useCallback(() => {
    const $input = getInputComponent();
    return document.activeElement === $input;
  }, [getInputComponent]);

  const refocus = useCallback(() => {
    refocusRef.current = true;
    const $input = getInputComponent();
    $input.focus();
    onFocus();
  }, [getInputComponent]);

  const handleCalendarClose = useCallback(() => {
    if (!clickOutsideRef.current && !blurByTabRef.current) {
      refocus();
    }
    clickOutsideRef.current = false;
    blurByTabRef.current = false;
  }, [refocus]);

  const dateValue = useMemo<Date>(() => {
    if (props.value) {
      return stringToDate(props.value, format, false);
    } else return null;
  }, [props.value, format]);

  const hasValue = dateValue;

  const { onBlur, onFocus, style, className } = useControl({
    name,
    value: dateValue,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    notify: props.notify,
    disabled: props.disabled,
    className: props.className,
    autocomplete: props.autocomplete,
    nullIsValue: false,
    readOnly: props.readOnly,
    height: props.height,
  });

  const handleMouseEnter = useCallback((e) => {
    if (e.target.closest(".react-datepicker")) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, props.tooltip, hasValue, focused, calendarIsOpen]);

  const handleCalendarInputClick = useCallback(() => {
    closeDatePickers(id);

    if (props.disabled || props.readOnly) return;
    if (selectedRef.current) {
      selectedRef.current = false;
      deferredCalendarClose();
    } else {
      if (!startIconClickRef.current) {
        setCalendarIsOpen(true);
      }
    }
    startIconClickRef.current = false;
  }, [id, props.disabled, props.readOnly]);

  const handleFocus = useCallback(
    (e) => {
      if (!refocusRef.current && !startIconClickRef.current) {
        setCalendarIsOpen(true);
      }
      startIconClickRef.current = false;
      blurByTabRef.current = false;
      refocusRef.current = false;
      return onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      refocusRef.current = false;
      return onBlur(e);
    },
    [onBlur]
  );

  const handleCalendarClickOutside = useCallback(
    (e) => {
      // Skip if clicked on input
      const $parent = findDOMNode(datePickerRef.current);
      if (e && $parent.contains(e.target)) {
        return;
      }

      clickOutsideRef.current = true;

      deferredCalendarClose();
    },
    [deferredCalendarClose]
  );

  const handleSelect = useCallback(() => {
    if (!blurByTabRef.current) {
      selectedRef.current = true;
      deferredCalendarClose();
    }
  }, [deferredCalendarClose]);

  const handleChangeRaw = useCallback(
    (e) => {
      setCalendarIsOpen(true);

      if (typeof e.target.value === "string") {
        try {
          const date = dateParser(e.target.value, props.time);

          if (date) {
            e.preventDefault();

            if (props.time) {
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const formattedTime = `${hours
                .toString()
                .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
              timeValueRef.current = formattedTime;
            }

            datePickerRef.current.setState({
              inputValue: e.target.value,
              lastPreSelectChange: "input",
            });

            datePickerRef.current.setSelected(date, e, true);
          }
        } catch (err) {
          /* istanbul ignore next */
          console.error(err);
        }
      }
    },
    [props.time]
  );

  const handleFakeInputKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KeyCode.KEY_RETURN) {
        e.preventDefault();

        if (inputIsFocused()) {
          if (calendarIsOpen) {
            deferredCalendarClose();
          } else {
            setTimeout(() => {
              refocus();
            }, 0);
          }
        }
      } else if (e.keyCode === KeyCode.KEY_TAB) {
        blurByTabRef.current = true;

        if (datePickerRef.current) {
          const copied = new Date(
            datePickerRef.current.state.preSelection.getTime()
          );
          datePickerRef.current.setSelected(copied, e, false);
        }

        deferredCalendarClose();
      } else if (e.keyCode === KeyCode.KEY_ESCAPE) {
        e.preventDefault();
        e.stopPropagation();

        props.onCommit(lastValueRef.current);

        deferredCalendarClose();
      } else if (e.keyCode === KeyCode.KEY_DOWN) {
        if (!calendarIsOpen) {
          e.preventDefault();
          e.stopPropagation();
          setCalendarIsOpen(true);
        }
      }
    },
    [
      calendarIsOpen,
      deferredCalendarClose,
      inputIsFocused,
      refocus,
      props.onCommit,
    ]
  );

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();
      startIconClickRef.current = true;

      // closeMenu();
      //
      // handleFakeBlur();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  // const handleStopClearClickPropagation = useCallback((e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   return false;
  // }, []);

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);
      setCalendarIsOpen(false);
      setShowTooltip(false);
      datePickerRef.current.setBlur(false);
    },
    [props.onCommit, onBlur]
  );

  useEffect(() => {
    if (!props.value && props.defaultIsCurrentDate) {
      const dateString = dateToString(new Date(), format);

      props.onCommit(dateString, { silent: true, ignoreDiff: true });
    }
  }, []);

  useSecondEffect(() => {
    if (dateValue) {
      datePickerRef.current.setPreSelection(dateValue);
    }
  }, [dateValue]);

  const timeProps = useMemo(() => {
    if (props.time) {
      return {
        showTimeInput: true,
        customTimeInput: <CustomTimeInput timeValueRef={timeValueRef} />,
      };
    } else {
      return {};
    }
  }, [props.time, props.defaultTime]);

  useLayoutEffect(() => {
    (controlRef.current as any).commit = (value) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  useSecondEffect(() => {
    if (!calendarIsOpen) {
      handleCalendarClose();
      setShowTooltip(false);
      lastValueRef.current = props.value;
    } else {
      lastValueRef.current = props.value;
    }
  }, [calendarIsOpen]);

  useEffect(() => {
    registerDatePicker(id, {
      close: handleCalendarClickOutside,
    });
    return () => {
      unregisterDatePicker(id);
    };
  }, [id, handleCalendarClickOutside]);

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: !!props.value,
    labelRef: labelRef,
    value: props.value,
    label: props.label,
  });

  const handleFocused = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip]);

  const handleBlurred = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip]);

  const AdvDateCalendarContainerHOC = useMemo(() => {
    return ({ children, className }) => {
      if (
        !props.ranges ||
        (Array.isArray(props.ranges) && props.ranges.length === 0)
      ) {
        return <div className={className}>{children}</div>;
      }
      return (
        <AdvDateCalendarContainer
          children={children}
          className={className}
          ranges={props.ranges}
          customRange={props.customRange}
          onSelect={handleRangeSelect}
        />
      );
    };
  }, [props.ranges, props.customRange, handleRangeSelect]);

  useEffect(() => {
    // Workaround for https://github.com/Hacker0x01/react-datepicker/issues/2832
    if (datePickerRef.current) {
      if (!datePickerRef.current.calendar) {
        datePickerRef.current.calendar = {
          componentNode: document.createElement("div"),
        };
      }
    }
  });

  return (
    <AdvDatePickerContextProvider value={dateValue}>
      {props.tooltip && finalShowTooltip && !calendarIsOpen ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        onClick={handleCalendarInputClick}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={controlRef}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            onStartIconClick={
              typeof props.onStartIconClick === "function"
                ? handleStartIconClick
                : props.onStartIconClick
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.showIcon && !props.startIcon ? (
          <StartIcon
            startIcon={"calendar"}
            onStartIconClick
            startIconActive={calendarIsOpen}
          />
        ) : null}
        <label onBlur={handleBlur}>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              props.value ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
                // onClick={handleStopClearClickPropagation}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          {/* Fake tab focus control */}
          {/* <input
          ref={fakeInputRef}
          type="text"
          onFocus={onFocus}
          onBlur={handleFakeInputBlur}
          onKeyDown={handleFakeInputKeyDown}
          style={{
            width: 0,
            height: 0,
            display: "block",
            padding: 0,
            margin: 0,
          }}
        /> */}
          <DatePicker
            onChangeRaw={handleChangeRaw}
            locale={__getGlobalLocale()}
            ref={useCombinedRef(datePickerRef, ref)}
            onClickOutside={handleCalendarClickOutside}
            popperPlacement="bottom-start"
            popperContainer={AdvDatePoppperContainer}
            calendarContainer={AdvDateCalendarContainerHOC}
            // popperModifiers={
            //   {
            //     preventOverflow: {
            //       enabled: true,
            //       escapeWithReference: false,
            //       boundariesElement: "viewport",
            //     },
            //   } as any
            // }
            onKeyDown={handleFakeInputKeyDown}
            {...timeProps}
            open={calendarIsOpen}
            openToDate={dateValue || currentDate}
            // Disable tab focus on DatePicker
            tabIndex={props.tabIndex}
            dateFormat={displayFormat}
            readOnly={props.readOnly}
            selected={dateValue}
            onChange={handleChange}
            onSelect={handleSelect}
            // onCalendarClose={handleCalendarClose}
            disabled={props.disabled || props.readOnly}
            {...props.pickerProps}
            todayButton={pt("datepicker", "Today")}
            timeInputLabel={pt("datepicker", "Time")}
            onFocus={handleFocus}
          />
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
      </span>
    </AdvDatePickerContextProvider>
  );
}) as any;

const AdvDatePoppperContainer = ({ children }) => {
  const el = document.body;

  return (
    <Portal container={el}>
      <div>{children}</div>
    </Portal>
  );
};

const AdvDateCalendarContainer: React.FC<{
  className: string;
  ranges: AdvDatePickerFastRange[];
  customRange: (currentDate: Date, value: number) => Date;
  onSelect: (date: Date) => any;
}> = ({ children, className, ranges, customRange, onSelect }) => {
  const { pt } = useTranslation();

  const [customRangeValue, setCustomRangeValue] = useState(null);

  const { value: date } = useAdvDatePickerContext();

  const handleCustomRangeInput = useCallback((value) => {
    setCustomRangeValue(value);
  }, []);

  const submit = useCallback(() => {
    if (customRangeValue) {
      const dateToSelect = customRange(date, customRangeValue);
      onSelect(dateToSelect);
    }
  }, [customRangeValue, date, onSelect]);

  const hadleSubmitKeyDown = useCallback(
    (e) => {
      e.preventDefault();
      submit();
    },
    [submit]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KeyCode.KEY_RETURN) {
        e.preventDefault();
        e.stopPropagation();
        submit();
      }
    },
    [submit]
  );

  return (
    <div className="react-datepicker-container">
      <div className="react-datepicker-ranges-container">
        <div className="react-datepicker-ranges">
          {ranges.map((range, index) => {
            return (
              <AdvDateCalendarRangeItem
                key={index}
                range={range}
                date={date}
                onSelect={onSelect}
              />
            );
          })}
        </div>
        {customRange ? (
          <div
            className="react-datepicker-custom-range"
            onKeyDown={handleKeyDown}
          >
            <AdvNumber
              onCommit={handleCustomRangeInput}
              value={customRangeValue}
              minimal
              integer
              allowEmpty
              label={pt("datepicker", "Enter")}
            />
            <div
              className="react-datepicker-custom-range__confirm"
              onClick={hadleSubmitKeyDown}
            >
              <Icon icon={"chevron-right"} />
            </div>
          </div>
        ) : null}
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

interface AdvDateCalendarRangeItemProps {
  range: AdvDatePickerFastRange;
  date: Date;
  onSelect: (date: Date) => any;
}

const AdvDateCalendarRangeItem: React.FC<AdvDateCalendarRangeItemProps> = (
  props
) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      const dateToSelect = props.range.onSelect(props.date);
      props.onSelect(dateToSelect);
    },
    [props.range.onSelect, props.onSelect, props.date]
  );

  return (
    <div className="react-datepicker-ranges__range" onClick={handleClick}>
      {props.range.label}
    </div>
  );
};

const CLEAVE_OPTIONS = {
  time: true,
  timePattern: ["h", "m"],
};

const CustomTimeInput: React.FC<any> = ({ value, timeValueRef, onChange }) => {
  const handleTimeChange = useCallback(
    (timeValue) => {
      timeValueRef.current = timeValue;
      if (isValidTime(timeValueRef.current)) {
        onChange(timeValueRef.current);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    if (isValidTime(timeValueRef.current)) {
      onChange(timeValueRef.current);
    } else {
      const timeToReset = isValidTime(value) ? value : "00:00";
      timeValueRef.current = timeToReset;
      onChange(timeToReset);
    }
  }, [onChange, value]);

  return (
    <AdvInput
      value={timeValueRef.current}
      onCommit={handleTimeChange}
      onBlur={handleBlur}
      cleave={CLEAVE_OPTIONS}
    />
  );
};

AdvDatePicker.defaultProps = {
  autocomplete: false,
  defaultTime: "00:00",
  showIcon: true,
  pickerProps: {
    showMonthDropdown: true,
    showYearDropdown: true,
  },
};

AdvDatePicker.setFormatCallback = (
  formatCallback: (time?: boolean) => string | null
) => {
  __setGlobalDateFormat(formatCallback);
  // globalFormatCallback = formatCallback;
};

AdvDatePicker.formatDate = (dateString: string, displayFormat?: string) => {
  const date = new Date(dateString);
  const format = getDateTimeDisplayFormat(false, displayFormat);
  return dnsDateFormat(date, format);
};

AdvDatePicker.formatDateTime = (dateString: string, displayFormat?: string) => {
  const date = new Date(dateString);
  const format = getDateTimeDisplayFormat(true, displayFormat);
  return dnsDateFormat(date, format);
};

AdvDatePicker.setLocaleCallback = (getLocale: () => string) => {
  __setGlobalLocale(getLocale);
};

export default AdvDatePicker;
