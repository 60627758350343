import React, {
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";

export type CalendarTimelineScrollableContextType = {
  bodyGridRef: React.RefObject<HTMLDivElement>;
  headerRef: React.RefObject<HTMLDivElement>;
  eventsRef: React.RefObject<HTMLDivElement>;
  sidebarRef: React.RefObject<HTMLDivElement>;
};

const CalendarTimelineScrollableContext =
  React.createContext<CalendarTimelineScrollableContextType>({
    bodyGridRef: null,
    headerRef: null,
    eventsRef: null,
    sidebarRef: null,
  });

export interface CalendarTimelineScrollableContextProviderProps {}

const CalendarTimelineScrollableContextProvider: React.FC<CalendarTimelineScrollableContextProviderProps> =
  memo((props) => {
    const bodyGridRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const eventsRef = useRef<HTMLDivElement>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);

    const handleEventsScroll = useCallback((e) => {
      const $bodyGrid = bodyGridRef.current;
      const $header = headerRef.current;
      const $sidebar = sidebarRef.current;
      if ($bodyGrid) {
        $bodyGrid.scrollLeft = e.target.scrollLeft;
      }
      if ($header) {
        $header.scrollLeft = e.target.scrollLeft;
      }
      if ($sidebar) {
        $sidebar.scrollTop = e.target.scrollTop;
      }
    }, []);

    const handleBodyScroll = useCallback((e) => {
      const $header = headerRef.current;
      const $events = eventsRef.current;
      if ($header) {
        $header.scrollLeft = e.target.scrollLeft;
      }
      if ($events) {
        $events.scrollLeft = e.target.scrollLeft;
      }
    }, []);

    const handleSidebarScroll = useCallback((e) => {
      const $bodyGrid = bodyGridRef.current;
      const $events = eventsRef.current;
      if ($bodyGrid) {
        $bodyGrid.scrollTop = e.target.scrollTop;
      }
      if ($events) {
        $events.scrollTop = e.target.scrollTop;
      }
    }, []);

    const handleHeaderScroll = useCallback((e) => {
      const $bodyGrid = bodyGridRef.current;
      const $events = eventsRef.current;
      if ($bodyGrid) {
        $bodyGrid.scrollLeft = e.target.scrollLeft;
      }
      if ($events) {
        $events.scrollLeft = e.target.scrollLeft;
      }
    }, []);

    useLayoutEffect(() => {
      const $events = eventsRef.current;
      const $body = bodyGridRef.current;
      const $header = headerRef.current;
      const $sidebar = sidebarRef.current;

      if ($events) {
        $events.addEventListener("scroll", handleEventsScroll);
      }
      if ($body) {
        $body.addEventListener("scroll", handleBodyScroll);
      }
      if ($header) {
        $header.addEventListener("scroll", handleHeaderScroll);
      }
      if ($sidebar) {
        $sidebar.addEventListener("scroll", handleSidebarScroll);
      }

      return () => {
        if ($events) {
          $events.removeEventListener("scroll", handleEventsScroll);
        }
        if ($body) {
          $body.removeEventListener("scroll", handleBodyScroll);
        }
        if ($header) {
          $header.removeEventListener("scroll", handleHeaderScroll);
        }
        if ($sidebar) {
          $sidebar.removeEventListener("scroll", handleSidebarScroll);
        }
      };
    }, [
      handleEventsScroll,
      handleBodyScroll,
      handleHeaderScroll,
      handleSidebarScroll,
    ]);

    const value = useMemo(() => {
      return {
        bodyGridRef,
        headerRef,
        eventsRef,
        sidebarRef,
      };
    }, []);

    return (
      <CalendarTimelineScrollableContext.Provider value={value}>
        {props.children}
      </CalendarTimelineScrollableContext.Provider>
    );
  });

const useCalendarTimelineScrollableContext = () => {
  return useContext(CalendarTimelineScrollableContext);
};

export {
  CalendarTimelineScrollableContextProvider,
  useCalendarTimelineScrollableContext
};

