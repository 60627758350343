import { useTranslation } from "@adv-libs/l10n";
import { useMemo } from "react";

const useWeekDayNames = () => {
  const { i18n } = useTranslation();

  const locale = i18n.language;

  const weekDayNames = useMemo(() => {
    if (locale === 'lt') {
      return ["Pr", "A", "T", "K", "Pn", "Š", "S"];
    } else {
      return ["Mo", "Tu", "We", "Th", "Fr", "Sa", 'Su'];
    }
  }, [locale]);

  return weekDayNames;
};

export default useWeekDayNames;
