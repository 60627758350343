import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import React, { CSSProperties, useMemo } from "react";
import { cp } from "../utils";
import ToastProgressBar from "./ToastProgressBar";
import { ToastContent } from "./types";

export interface ToastProps {
  content: ToastContent;
  intent?: "error" | "info" | "warning" | "success";
  progressBar?: number | false;
  icon?: any;
}

const Toast: React.FC<ToastProps> = (props) => {
  const title =
    typeof props.content === "string"
      ? props.content
      : props.content.title
      ? props.content.title
      : props.content.description;

  const description =
    typeof props.content === "string"
      ? null
      : !props.content.description
      ? null
      : props.content.title
      ? props.content.description
      : null;

  const details =
    typeof props.content === "string" ? null : props.content.details;

  const headerStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (description || (details && details.length)) {
      style["borderBottomLeftRadius"] = 0;
      style["borderBottomRightRadius"] = 0;
    }
    return style;
  }, [description]);

  const descriptionStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (details && details.length) {
      style["borderBottomLeftRadius"] = 0;
      style["borderBottomRightRadius"] = 0;
      style["paddingBottom"] = 0;
    }
    return style;
  }, [details]);

  const filteredDetails = useMemo(() => {
    if (Array.isArray(details) && details.length > 0) {
      return details.filter(Boolean);
    }
    return [];
  }, [details]);

  return (
    <div
      className={clsx(
        cp("toast"),
        props.intent ? cp(`toast--${props.intent}`) : null
      )}
      data-testid="toast"
    >
      <div className={clsx(cp("toast__header"))} style={headerStyle}>
        {props.icon ? (
          <div className={clsx(cp("toast__header__icon"))}>
            <Icon icon={props.icon} />
          </div>
        ) : null}
        <div className={clsx(cp("toast__header__content"))}>{title}</div>
        {props.progressBar ? (
          <ToastProgressBar duration={props.progressBar}></ToastProgressBar>
        ) : null}
      </div>
      {description ? (
        <div
          className={clsx(cp("toast__description"))}
          style={descriptionStyle}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      ) : null}
      {filteredDetails.length ? (
        <div className={clsx(cp("toast__details"))}>
          <div className={clsx(cp("toast__details__content"))}>
            <ul>
              {filteredDetails.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Toast;
