import React from "react";
import CalendarMobileBodyWeekHeaderDay from "./CalendarMobileBodyWeekHeaderDay";

export interface CalendarMobileBodyWeekHeaderProps {
  week: Date[];
}

const CalendarMobileBodyWeekHeader: React.FC<CalendarMobileBodyWeekHeaderProps> =
  (props) => {
    return (
      <div className="rivile-calendar-body-week-header">
        {props.week.map((day, index) => {
          return <CalendarMobileBodyWeekHeaderDay key={index} day={day} />;
        })}
      </div>
    );
  };

export default CalendarMobileBodyWeekHeader;
