import groupEventsByPropertyIfNotOverlaping from "./groupEventsByPropertyIfNotOverlaping";

const groupEventsByPropertyIfNotOverlapingFlatten = <T>(
  items: T[],
  groupsIds: any[],
  groupByProperty: (item: T) => any,
  startDate: (item: T) => Date,
  endDate: (item: T) => Date
) => {
  const groupedItemsObject = groupEventsByPropertyIfNotOverlaping<T>(
    items,
    groupsIds,
    groupByProperty,
    startDate,
    endDate
  );

  // Flatten groups of groups into a single array of groups
  const groupedItems: T[][] = [];

  for (const groups of Object.values(groupedItemsObject)) {
    groupedItems.push(...groups);
  }

  return groupedItems;
};

export default groupEventsByPropertyIfNotOverlapingFlatten;
