import { defined, makeid, roundNumber } from "@adv-libs/utils";
import clsx from "clsx";
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  NumericFormat,
  NumericFormatProps,
  numericFormatter,
} from "react-number-format";
import invariant from "tiny-invariant";
import AdvControlLabel from "../AdvControlLabel";
import EndIcon from "../AdvInput/EndIcon";
import StartIcon from "../AdvInput/StartIcon";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import useAutoWidthMeasure from "./useAutoWidthMeasure";

export interface AdvNumberProps extends AdvCommonControlProps {
  autoWidth?: boolean;
  thousandSeparator?: string | false;
  decimalSeparator?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  integer?: boolean;
  currency?: boolean;
  allowEmpty?: boolean;
  align?: "left" | "right";
  fieldName?: string;
  onFocus?: (e?: any) => any;
  isClearable?: boolean;
  height?: number;
  minWidth?: number;
}

const name = "number";

const AdvNumber = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvNumberProps>
>((props, ref) => {
  invariant(
    props.decimalSeparator !== props.thousandSeparator,
    `Do not use decimalSeparator same as thousandSeparator`
  );
  const [rawValue, setRawValue] = useState(props.value);
  const tooltipRef = useRef<AdvTooltipInstance>(null);

  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();

  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const id = useMemo(() => {
    return !props.autocomplete ? makeid(5) : null;
  }, []);

  const fieldName = useMemo(() => {
    return id ? props.fieldName + "$" + id : props.fieldName;
  }, [id, props.fieldName]);

  const handleChange = useCallback(
    (e) => {
      if ("persist" in e) {
        e.persist();
      }
    },
    [props]
  );

  const handleValueChange = useCallback(
    (value) => {
      if (typeof value.floatValue === "number") {
        if (props.value !== value.floatValue) {
          let silent = false;
          if (
            typeof props.decimalScale === "number" &&
            props.fixedDecimalScale &&
            typeof props.value === "number"
          ) {
            /**
             * Sanitize rounded value. If rounded values is the same, commit new value silently
             */
            const roundedValue = roundNumber(props.value, props.decimalScale);
            const roundedFloatValue = roundNumber(
              value.floatValue,
              props.decimalScale
            );
            if (roundedValue === roundedFloatValue) {
              silent = true;
            }
          }

          props.onCommit(value.floatValue, { silent });
        }
      } else if (value.formattedValue === "" && props.allowEmpty) {
        props.onCommit(null);
      }

      if (props.autoWidth) {
        setRawValue(value.value);
      }
    },
    [props.value, props.onCommit, props.allowEmpty, props.fieldName]
  );

  const value = useMemo(() => {
    const stringValue = defined(props.value) ? String(props.value) : "";

    return roundStringNumber(stringValue, {
      fixedDecimalScale: props.fixedDecimalScale,
      decimalScale: props.decimalScale,
      thousandSeparator: props.thousandSeparator,
      decimalSeparator: props.decimalSeparator,
    });
  }, [
    props.value,
    props.fixedDecimalScale,
    props.decimalScale,
    props.decimalSeparator,
    props.thousandSeparator,
  ]);

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: rawValue,
    labelRef: labelRef,
    value: rawValue,
    label: props.label,
  });

  const hasValue = typeof value === "number";

  const {
    onBlur,
    onFocus,
    onAnimationStart,
    onAnimationEnd,
    className,
    style,
  } = useControl({
    name,
    value,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    onFocus: props.onFocus,
    height: props.height,
    notify: props.notify,
  });

  const handleMouseEnter = useCallback((e) => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, props.tooltip, hasValue, focused]);

  const handleFocus = useCallback(
    (e) => {
      if (!props.readOnly && !props.disabled) {
        setTimeout(() => {
          e.target?.select();
        }, 10);
      }
      return onFocus(e);
    },
    [onFocus, props.readOnly, props.disabled]
  );

  const handleBlur = useCallback(
    (e) => {
      if (!e.target.value && !props.allowEmpty && !props.readOnly) {
        props.onCommit(0);
      }
      return onBlur(e);
    },
    [onBlur, props.onCommit, props.allowEmpty, props.readOnly]
  );

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  const handleEndIconClick = useCallback((e) => {
    e.preventDefault();

    if (typeof props.onEndIconClick === "function") {
      props.onEndIconClick(props.value, e);
    }
  }, []);

  const handleStopClearClickPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!props.allowEmpty) {
        props.onCommit(0);
      } else {
        props.onCommit(null);
      }

      const $input = controlRef.current.querySelector("input");
      if ($input) {
        $input.blur();
      }
    },
    [props.onCommit, onBlur]
  );

  const inputOptions = useMemo(() => {
    if (props.currency) {
      return {
        decimalScale: props.integer ? 0 : props.decimalScale ?? 2,
        thousandSeparator: props.thousandSeparator
          ? props.thousandSeparator
          : DEFAULT_THOUSAND_SEPARATOR,
        fixedDecimalScale: true,
        decimalSeparator: props.decimalSeparator,
        allowedDecimalSeparators: [",", "."],
      };
    }
    if (props.integer) {
      return {
        decimalScale: 0,
        allowNegative: false,
        thousandSeparator: props.thousandSeparator,
        allowedDecimalSeparators: [",", "."],
      };
    }
    return {
      thousandSeparator: props.thousandSeparator,
      decimalSeparator: props.decimalSeparator,
      decimalScale: props.decimalScale,
      fixedDecimalScale: props.fixedDecimalScale,
      allowedDecimalSeparators: [",", "."],
      allowNegative: props.allowNegative ?? true,
    };
  }, [
    props.thousandSeparator,
    props.decimalSeparator,
    props.fixedDecimalScale,
    props.decimalScale,
    props.integer,
    props.currency,
  ]);

  const legacyRef = useCallback(($element) => {
    if (typeof ref === "function") {
      ref($element);
    } else if (ref) {
      (ref as any).current = $element;
    }
  }, []);

  useLayoutEffect(() => {
    (controlRef.current as any).commit = (value) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  const handleFocused = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip]);

  const handleBlurred = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip]);

  return (
    <>
      {props.tooltip && finalShowTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={controlRef}
        style={style}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
        className={clsx(className, {
          currency: props.currency,
          "align-left": props.align === "left",
          "align-right": props.align === "right",
        })}
      >
        {props.startIcon ? (
          <StartIcon
            onStartIconClick={handleStartIconClick}
            startIcon={props.startIcon}
            startIconActive={props.startIconActive}
            startIconCount={props.startIconCount}
            startIconSize={props.startIconSize}
            startIconSpin={props.startIconSpin}
            value={props.value}
          />
        ) : null}
        <label>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              typeof props.value === "number" ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
                onClick={handleStopClearClickPropagation}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          <IsolatedNumberFormat
            name={fieldName}
            onAnimationStart={onAnimationStart}
            onAnimationEnd={onAnimationEnd}
            getInputRef={legacyRef}
            {...inputOptions}
            value={value}
            onChange={handleChange}
            onValueChange={handleValueChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={props.readOnly}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
          />
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
        {props.endIcon ? (
          <EndIcon
            onEndIconClick={handleEndIconClick}
            endIcon={props.endIcon}
            endIconSpin={props.endIconSpin}
            endIconSize={props.endIconSize}
            endIconTooltip={props.endIconTooltip}
          />
        ) : null}
      </span>
    </>
  );
});

const IsolatedNumberFormat: React.FC<NumericFormatProps<any>> = memo(
  (props) => {
    return <NumericFormat {...props} />;
  }
);

const DEFAULT_THOUSAND_SEPARATOR = " ";

AdvNumber.defaultProps = {
  thousandSeparator: DEFAULT_THOUSAND_SEPARATOR,
  decimalSeparator: ".",
  autocomplete: false,
  allowNegative: true,
};

const roundStringNumber = (
  value: string,
  options: {
    thousandSeparator?: string | false;
    decimalSeparator?: string;
    fixedDecimalScale?: boolean;
    decimalScale?: number;
  } = {}
) => {
  let thousandSeparator =
    options.thousandSeparator ?? DEFAULT_THOUSAND_SEPARATOR;

  if (thousandSeparator) {
    value = value.replace(
      new RegExp(escapeRegExpString(thousandSeparator), "g"),
      ""
    );
  }

  if (options.decimalScale) {
    const parsedFloat = parseFloat(value);
    if (!isNaN(parsedFloat)) {
      value = roundNumber(parsedFloat, options.decimalScale).toString();
    }
  }

  value = numericFormatter(value, { ...options, thousandSeparator });

  return value;
};

export const formatNumber = (
  value: any,
  options: {
    thousandSeparator?: string;
    decimalSeparator?: string;
    fixedDecimalScale?: boolean;
    decimalScale?: number;
    allowEmpty?: boolean;
  } = {}
) => {
  if (options.allowEmpty && !defined(value)) {
    return "";
  } else if (!value) {
    value = 0;
  }
  return roundStringNumber(String(value), options);
};

const escapeRegExpString = (str: string) => {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
};

export default AdvNumber;
