import React, { useCallback } from "react";
import { CalendarEventInWeekType } from "../../../types";
import CalendarMobileBodyWeekCloseButton from "./CalendarMobileBodyWeekCloseButton";
import CalendarMobileBodyWeekEventsGroups from "./CalendarMobileBodyWeekEventsGroups";
import CalendarMobileBodyWeekExpandButtons from "./CalendarMobileBodyWeekExpandButtons";
import CalendarMobileBodyWeekGridCells from "./CalendarMobileBodyWeekGridCells";

export interface CalendarMobileBodyWeekGridProps {
  week: Date[];
  weekIndex: number;
  onWeekClose: () => any;
  onWeekExpand: (weekIndex: number) => any;
  isExpanded: boolean;
  weekGroups: {
    weekStart: Date;
    weekEnd: Date;
    events: CalendarEventInWeekType[][];
  };
}

const CalendarMobileBodyWeekGrid: React.FC<CalendarMobileBodyWeekGridProps> = (
  props
) => {
  const { week, onWeekClose, onWeekExpand, weekIndex, isExpanded, weekGroups } =
    props;

  const allWeekEvents = weekGroups.events;

  const visbileWeekEvents = isExpanded
    ? allWeekEvents
    : [...allWeekEvents].slice(0, 3);

  const handleWeekClose = useCallback(() => {
    onWeekClose();
  }, [onWeekClose]);

  const handleWeekExpand = useCallback(() => {
    onWeekExpand(weekIndex);
  }, [onWeekExpand, weekIndex]);

  return (
    <div className="rivile-calendar-body-week-grid">
      <CalendarMobileBodyWeekGridCells week={week} />
      <CalendarMobileBodyWeekEventsGroups
        isExpanded={isExpanded}
        weekGroups={weekGroups}
        visibleWeekEvents={visbileWeekEvents}
      />
      {isExpanded ? (
        <CalendarMobileBodyWeekCloseButton onClose={handleWeekClose} />
      ) : (
        <CalendarMobileBodyWeekExpandButtons
          onExpand={handleWeekExpand}
          week={week}
          allWeekEvents={allWeekEvents}
          visibleWeekEvents={visbileWeekEvents}
        />
      )}
    </div>
  );
};

export default CalendarMobileBodyWeekGrid;
