import { stringToDate } from "@adv-libs/utils";
import { dateAllowedInputFormats } from "./constants";

const dateRangeParser = (range: string) => {
  range = range.trim();

  /**
   * Due the fact that dates delimiter is dash, we need to parse dates in dash format specifically
   */

  /**
   * Try to parse entered only one day to current month selected day range
   */
  if (range.length < 3 && !range.startsWith("-") && !range.endsWith("-")) {
    const day = parseInt(range);

    if (!isNaN(day) && day > 0 && day <= 31) {
      const date = new Date();
      date.setDate(day);

      return [date, date];
    }

    return [null, null];
  }

  /**
   * Try to parse days range
   */
  let regex = /^(\d{1,2})\s*\-\s*(\d{1,2})$/g;
  let matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dayFromString = matched[0][1];
    const dayToString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    const dateFromInt = parseInt(dayFromString);
    const dateToInt = parseInt(dayToString);

    if (dateFromInt > 0 && dateFromInt <= 31) {
      dateFrom = new Date();
      dateFrom.setDate(dateFromInt);
    }

    if (dateToInt > 0 && dateToInt <= 31) {
      dateTo = new Date();
      dateTo.setDate(dateToInt);
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse day ended with dash to current month selected day to current month current day range
   */
  regex = /^(\d{1,2})\s*\-\s*$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dayFromString = matched[0][1];

    let dateFrom = null;
    let dateTo = new Date();

    const dateFromInt = parseInt(dayFromString);

    if (dateFromInt > 0 && dateFromInt <= 31) {
      dateFrom = new Date();
      dateFrom.setDate(dateFromInt);
    }

    if (dateFrom) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse day started with dash to current month current day to current month selected day range
   */
  regex = /^\s*\-\s*(\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dayToString = matched[0][1];

    let dateFrom = new Date();
    let dateTo = null;

    const dateToInt = parseInt(dayToString);

    if (dateToInt > 0 && dateToInt <= 31) {
      dateTo = new Date();
      dateTo.setDate(dateToInt);
    }

    if (dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [yyyy-MM-dd]-[dd] format to selected date and current month selected day range
   */
  regex = /^(\d{4}\-\d{1,2}\-\d{1,2})\s*\-\s*(\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateString = matched[0][1];
    const dayString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    dateFrom = stringToDate(dateString, "yyyy-MM-dd", false);

    const day = parseInt(dayString);
    if (!isNaN(day) && day > 0 && day <= 31) {
      dateTo = new Date();
      dateTo.setDate(day);
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [dd]-[yyyy-MM-dd] format to current month selected day and selected date range
   */
  regex = /^(\d{1,2})\s*\-\s*(\d{4}\-\d{1,2}\-\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dayString = matched[0][1];
    const dateString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    dateTo = stringToDate(dateString, "yyyy-MM-dd", false);

    const day = parseInt(dayString);
    if (!isNaN(day) && day > 0 && day <= 31) {
      dateFrom = new Date();
      dateFrom.setDate(day);
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [yyyy-MM-dd]- format to selected date and current month current day range
   */
  regex = /^(\d{4}\-\d{1,2}\-\d{1,2})\s*\-\s*$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateString = matched[0][1];

    let dateFrom = null;
    let dateTo = new Date();

    dateFrom = stringToDate(dateString, "yyyy-MM-dd", false);

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse -[yyyy-MM-dd] format to current month current day and selected date range
   */
  regex = /^\s*\-\s*(\d{4}\-\d{1,2}\-\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateString = matched[0][1];

    let dateFrom = new Date();
    let dateTo = null;

    dateTo = stringToDate(dateString, "yyyy-MM-dd", false);

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [yyyy-MM-dd]-[yyyy-MM-dd] format to selected date range
   */
  regex = /^(\d{4}\-\d{1,2}\-\d{1,2})\s*\-\s*(\d{4}\-\d{1,2}\-\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateFromString = matched[0][1];
    const dateToString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    dateFrom = stringToDate(dateFromString, "yyyy-MM-dd", false);
    dateTo = stringToDate(dateToString, "yyyy-MM-dd", false);

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try parse [yyyy-MM-dd] format to selected date same day range
   */
  regex = /^(\d{4}\-\d{1,2}\-\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateString = matched[0][1];

    let dateFrom = null;
    let dateTo = null;

    dateFrom = stringToDate(dateString, "yyyy-MM-dd", false);
    dateTo = stringToDate(dateString, "yyyy-MM-dd", false);

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [yyyy-MM-dd]-[any] format to the range
   */
  regex = /^(\d{4}\-\d{1,2}\-\d{1,2})\s*\-\s*([^\-]+)$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateFromString = matched[0][1];
    const dateToString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    dateFrom = stringToDate(dateFromString, "yyyy-MM-dd", false);

    for (const format of dateAllowedInputFormats) {
      dateTo = stringToDate(dateToString, format, false);
      if (dateTo) {
        break;
      }
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [any]-[yyyy-MM-dd] format to the range
   */
  regex = /^([^\-]+)\s*\-\s*(\d{4}\-\d{1,2}\-\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateFromString = matched[0][1];
    const dateToString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    dateTo = stringToDate(dateToString, "yyyy-MM-dd", false);

    for (const format of dateAllowedInputFormats) {
      dateFrom = stringToDate(dateFromString, format, false);
      if (dateFrom) {
        break;
      }
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [any]-[dd] format to the range
   */
  regex = /^([^\-]+)\s*\-\s*(\d{1,2})$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateString = matched[0][1];
    const dayString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    for (const format of dateAllowedInputFormats) {
      dateFrom = stringToDate(dateString, format, false);
      if (dateFrom) {
        break;
      }
    }

    const day = parseInt(dayString);
    if (!isNaN(day) && day > 0 && day <= 31) {
      dateTo = new Date();
      dateTo.setDate(day);
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [dd]-[any] format to the range
   */
  regex = /^(\d{1,2})\s*\-\s*([^\-]+)$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dayString = matched[0][1];
    const dateString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    const day = parseInt(dayString);
    if (!isNaN(day) && day > 0 && day <= 31) {
      dateFrom = new Date();
      dateFrom.setDate(day);
    }

    for (const format of dateAllowedInputFormats) {
      dateTo = stringToDate(dateString, format, false);
      if (dateTo) {
        break;
      }
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [any]-[any] format to the range
   */
  regex = /^([^\-]+)\s*\-\s*([^\-]+)$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateFromString = matched[0][1];
    const dateToString = matched[0][2];

    let dateFrom = null;
    let dateTo = null;

    for (const format of dateAllowedInputFormats) {
      dateFrom = stringToDate(dateFromString, format, false);
      if (dateFrom) {
        break;
      }
    }

    for (const format of dateAllowedInputFormats) {
      dateTo = stringToDate(dateToString, format, false);
      if (dateTo) {
        break;
      }
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [any]- format to the selected date and current month current day range
   */
  regex = /^([^\-]+)\s*\-\s*$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateFromString = matched[0][1];

    let dateFrom = null;
    let dateTo = new Date();

    for (const format of dateAllowedInputFormats) {
      dateFrom = stringToDate(dateFromString, format, false);
      if (dateFrom) {
        break;
      }
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse -[any] format to the current month current day and selected date range
   */
  regex = /^\s*\-\s*([^\-]+)$/g;
  matched = Array.from(range.matchAll(regex));

  if (matched[0]) {
    const dateToString = matched[0][1];

    let dateFrom = new Date();
    let dateTo = null;

    for (const format of dateAllowedInputFormats) {
      dateTo = stringToDate(dateToString, format, false);
      if (dateTo) {
        break;
      }
    }

    if (dateFrom && dateTo) {
      return [dateFrom, dateTo];
    }

    return [null, null];
  }

  /**
   * Try to parse [any] format to the selected date same day range
   */
  for (const format of dateAllowedInputFormats) {
    const date = stringToDate(range, format, false);
    if (date) {
      return [date, date];
    }
  }

  return [null, null];
};

export default dateRangeParser;
