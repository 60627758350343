import { useCallback, useEffect } from "react";
import { useCalendarSharedConfig } from "../CalendarSharedConfigContext";
import { useCalendarTimelineConfig } from "../CalendarTimeline/CalendarTimelineConfigContext";
import { useCalendarTimelineContext } from "../CalendarTimeline/CalendarTimelineContext";
import { useCalendarTimelineDOMReferencesContext } from "../CalendarTimeline/CalendarTimelineDOMReferencesContext";

export interface UseCalendarTimelineMeasureOptions {
  daysCount: number;
}

const useCalendarTimelineMeasure = (
  options: UseCalendarTimelineMeasureOptions
) => {
  const domRefs = useCalendarTimelineDOMReferencesContext();

  const { update } = useCalendarTimelineContext();

  const sharedConfig = useCalendarSharedConfig();
  const timelineConfig = useCalendarTimelineConfig();

  const calculateAndUpdateWidth = useCallback(
    (groupsWidth: number) => {
      const containerWidth =
        domRefs.calendarRef.current.offsetWidth - groupsWidth;

      // Compensate borders 2px and scrollbar
      const minWidth =
        options.daysCount * timelineConfig.minCellWidth +
        2 +
        sharedConfig.scrollBarWidth;

      const width = Math.max(containerWidth, minWidth);

      if (domRefs.calendarRef.current) {
        requestAnimationFrame(() => {
          domRefs.calendarRef.current.style.setProperty(
            "--rivile-calendar-groups-width",
            `${groupsWidth}px`
          );
          domRefs.calendarRef.current.style.setProperty(
            "--rivile-calendar-width",
            `${width}px`
          );
          update();
        });
      }
    },
    [domRefs.calendarRef, options.daysCount, update]
  );

  const onGroupsResize = useCallback(
    (groupsWidth: number) => {
      calculateAndUpdateWidth(groupsWidth);
    },
    [calculateAndUpdateWidth]
  );

  const handleResize = useCallback(() => {
    const groupsWidth = domRefs.groupsSidebarRef?.current?.clientWidth || 0;
    calculateAndUpdateWidth(groupsWidth);
  }, [calculateAndUpdateWidth, domRefs.groupsSidebarRef]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    // // @TODO fix to be consistent
    setTimeout(() => {
      handleResize();
    }, 100);
  }, [
    domRefs.calendarRef,
    domRefs.groupsSidebarRef,
    handleResize,
    options.daysCount,
  ]);

  return { onGroupsResize };
};

export default useCalendarTimelineMeasure;