import { CalendarEventType } from "../types";
import isDateRangesOverlaping from "./isDateRangesOverlaping";

const getEventsCountInDay = (day: Date, events: CalendarEventType[]) => {
  return events.reduce((accum, event) => {
    if (isDateRangesOverlaping(day, day, event.startDate, event.endDate)) {
      accum++;
    }
    return accum;
  }, 0);
};

export default getEventsCountInDay;
