import { useCallback } from "react";
import {
  AdvSelectCommonProps,
  AdvSelectMultipleValueProps,
  AdvSelectOption,
} from "../../types";

export interface UseCreateAddItemOptions {
  onBeforeSelect: AdvSelectCommonProps["onBeforeSelect"];
  multiple: AdvSelectCommonProps["multiple"];
  singleSelect: AdvSelectMultipleValueProps["singleSelect"];
  onCommit: AdvSelectCommonProps["onCommit"];
  value: AdvSelectCommonProps["value"];
}

const useCreateAddItem = (
  options: UseCreateAddItemOptions,
) => {
  const addItem = useCallback(
    async (item: AdvSelectOption) => {
      if (typeof options.onBeforeSelect === "function") {
        item = await options.onBeforeSelect(item);
      }
      if (options.multiple) {
        if (options.singleSelect) {
          options.onCommit([item]);
        } else {
          if (!options.value) {
            options.onCommit([item]);
          } else {
            options.onCommit([...options.value, item]);
          }
        }
      } else {
        options.onCommit(item as any);
      }
    },
    [
      options.value,
      options.multiple,
      options.onCommit,
      options.onBeforeSelect,
      options.multiple && options.singleSelect,
    ]
  );

  return addItem;
};

export default useCreateAddItem;
