import React from "react";
import { CalendarEventInWeekType } from "../../../types";
import CalendarMobileBodyWeekEvent from "./CalendarMobileBodyWeekEvent";

export interface CalendarMobileBodyGridEventsGroupProps {
  weekStart: Date;
  weekEnd: Date;
  weekEvents: CalendarEventInWeekType[];
}

const CalendarMobileBodyWeekEventsGroup: React.FC<
  CalendarMobileBodyGridEventsGroupProps
> = (props) => {
  return (
    <div className="rivile-calendar-body-week-events-group">
      {props.weekEvents.map((event, index) => {
        return (
          <CalendarMobileBodyWeekEvent
            key={index}
            weekEvent={event}
            weekStart={props.weekStart}
            weekEnd={props.weekEnd}
          />
        );
      })}
    </div>
  );
};

export default CalendarMobileBodyWeekEventsGroup;
