import { AdvStatusTemplateDefinition } from "./types";

let _templatesRegister = {};

export const addStatusTemplate = (
  templateName: string,
  template: AdvStatusTemplateDefinition
) => {
  _templatesRegister[templateName] = template;
};

export const clearStatusTemplates = () => {
  _templatesRegister = {};
};

export const getStatusTemplate = (templateName: string) => {
  return _templatesRegister[templateName];
};