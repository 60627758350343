import clsx from "clsx";
import { isToday, isWeekend } from "date-fns";
import React from "react";
import getDayKey from "../../utils/getDayKey";

export interface CalendarTimelineBodyGridCellProps {
  day: Date;
}

const CalendarTimelineBodyGridCell: React.FC<
  CalendarTimelineBodyGridCellProps
> = (props) => {
  const isDayWeekend = isWeekend(props.day);
  const isDayToday = isToday(props.day);

  return (
    <div
      key={getDayKey(props.day)}
      className={clsx(
        "rivile-calendar-body-grid-cell",
        isDayWeekend && "is-weekend",
        isDayToday && "is-today"
      )}
    ></div>
  );
};

export default CalendarTimelineBodyGridCell;
