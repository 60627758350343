import { makeid } from "@adv-libs/utils";
import React, { useCallback, useEffect, useRef } from "react";
import { AdvSelect2State, AdvSelectCommonProps } from "../../types";
import { StateActions } from "./useCreateSelectState";

interface UseCreateFetchItemsOptions {
  getItems: AdvSelectCommonProps["getItems"];
  filter: AdvSelectCommonProps["filter"];
  params: AdvSelectCommonProps["params"];
  paginated: AdvSelectCommonProps["paginated"];
  debouncedQuery: string;
  state: AdvSelect2State;
  dispatch: React.Dispatch<any>;
  detectResponse: AdvSelectCommonProps["detectResponse"];
}

const useCreateFetchItems = (options: UseCreateFetchItemsOptions) => {
  const dispatch = options.dispatch;
  const state = options.state;
  const debouncedQuery = options.debouncedQuery;

  const raceVersion = useRef<string>(null);
  const mountedRef = useRef<boolean>(true);

  const fetchItems = useCallback(
    async (replaceItems?: boolean) => {
      if (typeof options.getItems === "function") {
        /**
         * If 'getItems' is a function
         */

        dispatch(StateActions.beforeDataFetch());

        try {
          const currentRaceVersion = makeid(5);
          raceVersion.current = currentRaceVersion;

          /**
           * Fetch items
           */
          const result = await options.getItems({
            query: debouncedQuery,
            params: options.params,
            page: options.paginated ? state.page : undefined,
            size: options.paginated ? options.paginated.size ?? 30 : undefined,
          });

          /**
           * Avoid race condition
           */
          if (raceVersion.current !== currentRaceVersion) {
            return;
          }

          /**
           * Do not set state if dropdown is unmounted
           */
          if (!mountedRef.current) {
            return;
          }

          if (
            (options.paginated && "content" in result) ||
            (options.detectResponse && "content" in result)
          ) {
            /**
             * Handle paginated result
             */
            dispatch(
              StateActions.afterDataFetch({
                items: result.content || [],
                totalElements: result.totalElements,
                replace: replaceItems,
                // cache only the null or empty query
                cache: !debouncedQuery,
              })
            );
          } else if (Array.isArray(result) && !("content" in result)) {
            /**
             * Handle direct result (not paginated)
             */
            dispatch(
              StateActions.afterDataFetch({
                items: result,
                replace: true,
                cache: !debouncedQuery,
              })
            );
          } else {
            throw new Error("[AdvSelect] Unsupported fetch callback return");
          }
        } catch (err) {
          dispatch(StateActions.dataFetchError(err));
        }
      } else if (Array.isArray(options.getItems)) {
        /**
         * If the 'getItems' is an array, clone array for mutability and set to the state
         */
        const items = [...options.getItems];
        dispatch(StateActions.setItems(items));
      }
    },
    [
      options.getItems,
      options.params,
      options.filter,
      debouncedQuery,
      state.page,
      options.paginated,
    ]
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return fetchItems;
};

export default useCreateFetchItems;
