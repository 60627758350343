import { makeid } from "@adv-libs/utils";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import hasValue from "./hasValue";
import cm from "./utils/cm";
import cp from "./utils/cp";

const useControl = (options: {
  name: string;
  value: any;
  nullIsValue?: boolean;
  label: string;
  minimal?: boolean;
  required?: boolean;
  disabled?: boolean;
  danger?: boolean;
  notify?: string;
  warning?: boolean;
  success?: boolean;
  className?: string;
  autocomplete?: boolean | string; // TODO added string because of 'autocomplete="new-password"' is needed
  readOnly?: boolean;
  onBlur?: (e?: any) => any;
  onFocus?: (e?: any) => any;
  height?: number;

  // prevent label flickering
  focusDebounce?: number;
}) => {
  const mounted = useRef(true);
  const [focused, setFocused] = useState(false);
  const justFocused = useRef(performance.now());
  const [persistentNotify, setPersistentNotify] = useState(null);
  const [persistentNotifyBorderColor, setPersistentNotifyBorderColor] =
    useState(null);

  const onBlur = useCallback(
    (e?) => {
      if (e && typeof e.preventDefault === "function") {
        e.preventDefault();
        if (options.onBlur) {
          options.onBlur(e);
        }
      }

      // prevent label flickering
      if (typeof options.focusDebounce === "number") {
        setTimeout(() => {
          const isFocusedAgain =
            performance.now() - justFocused.current < options.focusDebounce;
          if (!isFocusedAgain) {
            if (mounted.current === true) {
              setFocused(false);
            }
          }
        }, options.focusDebounce);
      } else {
        setFocused(false);
      }
    },
    [options.focusDebounce]
  );

  const filled = useMemo(() => {
    return hasValue(options.value, options.nullIsValue);
  }, [options.value, options.nullIsValue]);

  const labeled = useMemo(() => {
    return hasValue(options.label, options.nullIsValue);
  }, [options.label, options.nullIsValue]);

  const autoCompleteKey = useMemo(() => {
    return options.name + "-" + makeid();
  }, [options.name]);

  const onFocus = useCallback(
    (e?) => {
      if (e && typeof e.preventDefault === "function") {
        e.preventDefault();
      }

      if (options.onFocus) {
        options.onFocus(e);
      }

      justFocused.current = performance.now();
      if (e && e.target) {
        e.target.setAttribute(
          "autocomplete",
          options.autocomplete ? "on" : "new-" + autoCompleteKey
        );
      }
      setFocused(true);
    },
    [options.autocomplete, autoCompleteKey]
  );

  const controlClassName = cp("control");

  const className = useMemo(() => {
    return clsx(
      controlClassName,
      cp("control-" + options.name),
      options.className,
      cm(controlClassName, {
        focused: focused,
        filled: filled,
        labeled: labeled,
        height: typeof options.height === "number",
        minimal: options.minimal,
        required: options.required,
        disabled: options.disabled,
        success: options.success,
        warning: options.warning,
        danger: options.danger,
        notify: !!persistentNotify,
        "read-only": options.readOnly,
      })
    );
  }, [
    filled,
    focused,
    labeled,
    options.danger,
    options.warning,
    options.success,
    options.className,
    options.minimal,
    options.name,
    options.disabled,
    options.readOnly,
    options.height,
    persistentNotify,
  ]);

  const style = useMemo(() => {
    const style = {};
    if (typeof options.height === "number") {
      style["--control-height"] = options.height + "px";
    }
    if (typeof persistentNotifyBorderColor === "string") {
      style["borderColor"] = persistentNotifyBorderColor;
      style["boxShadow"] = `1px 2px 5px ${persistentNotifyBorderColor}88`;
    }
    return style;
  }, [options.height, persistentNotifyBorderColor]);

  useEffect(() => {
    if (typeof options.notify === "string") {
      setPersistentNotify(!!options.notify);
      setPersistentNotifyBorderColor(options.notify);
    } else if (persistentNotify) {
      setPersistentNotifyBorderColor(null);
      setTimeout(() => {
        setPersistentNotify(null);
      }, 250);
    }
  }, [options.notify]);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const autofilledRef = useRef(false);

  const onAnimationStart = useCallback(
    (e) => {
      if (options.autocomplete && options.autocomplete !== "off") {
        if (e.target.matches(":-webkit-autofill")) {
          autofilledRef.current = true;
          const parent = e.target.closest(".r365-control");
          parent.classList.add("r365-control--is-autofilled");
        }
      }
    },
    [className, options.autocomplete]
  );

  const onAnimationEnd = useCallback(
    (e) => {
      if (options.autocomplete && options.autocomplete !== "off") {
        setTimeout(() => {
          if (autofilledRef.current) return;
          if (e.target.matches(":-webkit-autofill")) {
            return onAnimationStart(e);
          }
          const parent = e.target.closest(".r365-control");
          parent.classList.remove("r365-control--is-autofilled");
        }, 200);
      }
    },
    [className, onAnimationStart, options.autocomplete]
  );

  return {
    onFocus,
    onBlur,
    onAnimationStart,
    onAnimationEnd,
    className,
    focused,
    style,
  };
};

export default useControl;
