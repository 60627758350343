import { differenceInDays } from "date-fns";

const getEndDateDaysOffset = (
  rangeEnd: Date,
  itemEnd: Date,
  onlyPositive?: boolean
) => {
  const diff = differenceInDays(rangeEnd, itemEnd);

  if (onlyPositive) {
    return diff < 0 ? 0 : diff;
  }

  return diff;
};

export default getEndDateDaysOffset;
