import { CalendarEventInWeekType, CalendarEventType } from "../types";
import isDateRangesOverlaping from "./isDateRangesOverlaping";

/** Maps event to week range. If event is not in the week range, returns null */
export const getEventRangeForWeek = (
  event: CalendarEventType,
  week: { startDate: Date; endDate: Date }
): CalendarEventInWeekType => {
  if (
    isDateRangesOverlaping(
      event.startDate,
      event.endDate,
      week.startDate,
      week.endDate
    )
  ) {
    return {
      event,
      eventStartInWeek:
        event.startDate > week.startDate ? event.startDate : week.startDate,
      eventEndInWeek:
        event.endDate < week.endDate ? event.endDate : week.endDate,
    };
  }
  return null;
};
