import React from "react";

const TabsContext = React.createContext<{
  onTabClick: (id: string) => any;
  activeTab: string;
  isContent?: boolean;
  flushContent?: boolean;
  fullHeight?: boolean;
}>({
  onTabClick: () => {},
  activeTab: null,
});

export default TabsContext;
