import clsx from "clsx";
import React, { CSSProperties } from "react";

export interface CalendarContainerProps {
  className?: string;
  style?: CSSProperties;
}

const CalendarContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CalendarContainerProps>
>((props, ref) => {
  return (
    <div
      ref={ref}
      className={clsx("rivile-calendar", props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  );
});

export default CalendarContainer;
