import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useQuery = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.state.query, [])
  );
};

export default useQuery;
