import { isPrimitive } from "@adv-libs/utils";
import { useCallback, useLayoutEffect } from "react";
import {
  AdvSelect2State,
  AdvSelectCommonProps,
  ItemManipulation
} from "../../types";

export interface UseDetectPrimitiveOptions {
  prependItems: AdvSelectCommonProps["prependItems"];
  multiple: AdvSelectCommonProps["multiple"];
  detectPrimitive: AdvSelectCommonProps["detectPrimitive"];
  onCommit: AdvSelectCommonProps["onCommit"];
  paginated: AdvSelectCommonProps["paginated"];
  getItems: AdvSelectCommonProps["getItems"];
  value: AdvSelectCommonProps["value"];
  items: AdvSelect2State["items"];
  itemManipulation: ItemManipulation;
  reloadItem: (id: any, updateSilent?: boolean) => any;
}

const useDetectPrimitive = (options: UseDetectPrimitiveOptions) => {
  const itemManipulation = options.itemManipulation;
  const reloadItem = options.reloadItem;

  const detectPrimitive = useCallback(async () => {
    const items = [];
    if (Array.isArray(options.prependItems)) {
      items.push(...options.prependItems);
    }
    if (Array.isArray(options.items)) {
      items.push(...options.items);
    }

    if (
      options.detectPrimitive &&
      items.length > 0 &&
      isPrimitive(options.value)
    ) {
      // @TODO detect with multiple also
      if (!options.multiple) {
        let matchedItem = items.find((item) => {
          return (
            itemManipulation.getItemValue(item) === options.value
          );
        });
        if (matchedItem) {
          options.onCommit(matchedItem as any, {
            silent: true,
            ignoreDiff: true,
          });
        } else if (
          typeof options.getItems === "function" &&
          options.paginated
        ) {
          reloadItem(options.value, true);
        }
      }
    }
  }, [options.items, options.prependItems, options.value, reloadItem]);

  useLayoutEffect(() => {
    detectPrimitive();
  }, [detectPrimitive]);
};

export default useDetectPrimitive;
