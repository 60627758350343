import React, { useContext, useEffect, useMemo, useState } from "react";
import { useCalendarTimelineDOMReferencesContext } from "./CalendarTimelineDOMReferencesContext";

export type CalendarTimelineGroupsResizeContextType = {
  onGroupsResize: (groupsWidth: number) => any;
  isOver: boolean;
  setIsOver: (isOver: boolean) => any;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => any;
};

const CalendarTimelineGroupsResizeContext =
  React.createContext<CalendarTimelineGroupsResizeContextType>(null);

export interface CalendarTimelineGroupsResizeContextProviderProps {
  onGroupsResize: (groupsWidth: number) => any;
}

const CalendarTimelineGroupsResizeContextProvider: React.FC<
  CalendarTimelineGroupsResizeContextProviderProps
> = (props) => {
  const [isOver, setIsOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const { calendarRef } = useCalendarTimelineDOMReferencesContext();

  const value = useMemo(() => {
    return {
      onGroupsResize: props.onGroupsResize,
      isOver: isOver,
      setIsOver: setIsOver,
      isDragging: isDragging,
      setIsDragging: setIsDragging,
    };
  }, [isDragging, isOver, props.onGroupsResize]);

  useEffect(() => {
    if (isDragging) {
      calendarRef.current.classList.add("is-dragging");
    } else {
      calendarRef.current.classList.remove("is-dragging");
    }
  }, [calendarRef, isDragging]);

  return (
    <CalendarTimelineGroupsResizeContext.Provider value={value}>
      {props.children}
    </CalendarTimelineGroupsResizeContext.Provider>
  );
};

const useCalendarTimelineGroupsResizeContext = () => {
  return useContext(CalendarTimelineGroupsResizeContext);
};

export {
  CalendarTimelineGroupsResizeContextProvider,
  useCalendarTimelineGroupsResizeContext,
};
