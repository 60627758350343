import { defined } from "@adv-libs/utils";
import { AdvSelectProps2 } from "./types";

const validateProps = (props: AdvSelectProps2) => {
  if (props.filter !== "server" && props.paginated) {
    throw new Error(
      `[AdvSelect] Usage of pagination and client side filter (${props.filter}) is not normal behaviour. If you want to use client side filter, please disable the pagination`
    );
  }

  if (defined(props.selectByDefault) && props.detectPrimitive) {
    throw new Error(
      "[AdvSelect] Usage of both 'selectByDefault' and 'detectPrimitive' is not supported."
    );
  }
};

export default validateProps;
