import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useRemoveItem = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.removeItem, [])
  );
};

export default useRemoveItem;
