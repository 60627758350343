import React, { memo, useCallback, useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import {
  AutoSizer,
  CellMeasurer,
  Index,
  List,
} from "react-virtualized/dist/commonjs";
import { useCalendarSharedContext } from "../../CalendarSharedContext";
import { useCalendarTimelineContext } from "../../CalendarTimeline/CalendarTimelineContext";
import { useCalendarTimelineGroupsHeightSyncContext } from "../../CalendarTimeline/CalendarTimelineGroupsHeightSyncContext";
import { useCalendarTimelineScrollableContext } from "../../CalendarTimeline/CalendarTimelineScrollableContext";
import { CalendarGroupedEventsType } from "../../types";
import CalendarTimelineBodyGroupOfEventsGroups from "./CalendarTimelineBodyGroupOfEventsGroups";

export interface CalenderTimelineBodyGroupsOfEventsGroupsProps {
  groupedEventsObject: CalendarGroupedEventsType;
}

const CalenderTimelineBodyGroupsOfEventsGroups: React.FC<CalenderTimelineBodyGroupsOfEventsGroupsProps> =
  memo((props) => {
    const { data } = useCalendarSharedContext();

    const { startDate, endDate } = useCalendarTimelineContext();

    const { eventsRef } = useCalendarTimelineScrollableContext();
    const listRef = useRef<List>(null);

    const { eventsMeasureCache, getMaxRowHeight, iteration } =
      useCalendarTimelineGroupsHeightSyncContext();

    const rowRerender = useCallback(
      ({ key, index, isScrolling, isVisible, style, parent }) => {
        const groupedEvents = props.groupedEventsObject[index];
        return (
          <CellMeasurer
            key={key}
            cache={eventsMeasureCache}
            rowIndex={index}
            parent={parent}
          >
            {({ registerChild }) => {
              return (
                <div style={style}>
                  <CalendarTimelineBodyGroupOfEventsGroups
                    registerChild={registerChild}
                    group={groupedEvents.group}
                    groupedEvents={groupedEvents.groupedEvents}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
              );
            }}
          </CellMeasurer>
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        eventsMeasureCache,
        props.groupedEventsObject,
        startDate,
        endDate,
        iteration,
      ]
    );

    const rowMeasure = useCallback(
      (rowIndex: Index) => {
        return getMaxRowHeight(rowIndex.index);
      },
      [getMaxRowHeight]
    );

    const handleScroll = useCallback(() => {
      requestAnimationFrame(() => {
        if (listRef.current) {
          listRef.current.recomputeRowHeights();
        }
      });
    }, []);

    useEffect(() => {
      listRef.current.forceUpdate();
      listRef.current.recomputeRowHeights();
    }, [startDate, endDate, data]);

    useEffect(() => {
      if (listRef.current) {
        listRef.current.scrollToRow(0);
      }
    }, [startDate, endDate]);

    return (
      <div className="rivile-calendar-body-groups-of-events-groups">
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                ref={(node) => {
                  (eventsRef as any).current = findDOMNode(node);
                  listRef.current = node;
                }}
                width={width}
                height={height}
                rowCount={props.groupedEventsObject.length}
                onScroll={handleScroll}
                deferredMeasurementCache={eventsMeasureCache}
                rowHeight={rowMeasure}
                rowRenderer={rowRerender}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  });

export default CalenderTimelineBodyGroupsOfEventsGroups;
