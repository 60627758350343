import { CalendarEventInWeekType, CalendarEventType } from "../types";
import { getEventRangeForWeek } from "./getEventRangeForWeek";
import groupEventsByPropertyIfNotOverlapingFlatten from "./groupEventsByPropertyIfNotOverlapingFlatten";

const mapEventsToWeeks = (
  events: CalendarEventType[],
  weeks: { startDate: Date; endDate: Date }[]
): {
  weekStart: Date;
  weekEnd: Date;
  events: CalendarEventInWeekType[][];
}[] => {
  return weeks.map((week) => {
    const weekEvents = (events || []).reduce((accum, event) => {
      const eventInWeekRange = getEventRangeForWeek(event, week); // Get range only for this week
      if (eventInWeekRange) {
        accum.push(eventInWeekRange);
      }
      return accum;
    }, [] as CalendarEventInWeekType[]);

    const groupedEvents =
      groupEventsByPropertyIfNotOverlapingFlatten<CalendarEventInWeekType>(
        weekEvents,
        [],
        (weekEvent) => weekEvent.event.groupId,
        (weekEvent) => weekEvent.eventStartInWeek,
        (weekEvent) => weekEvent.eventEndInWeek
      );

    return {
      weekStart: week.startDate,
      weekEnd: week.endDate,
      events: groupedEvents,
    };
  });
};

export default mapEventsToWeeks;
