import {
  defined,
  makeid,
  useCombinedRef,
  useSecondEffect,
} from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AdvControlLabel from "../AdvControlLabel";
import EndIcon from "../AdvInput/EndIcon";
import StartIcon from "../AdvInput/StartIcon";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import useAutoWidthMeasure from "./useAutoWidthMeasure";

export interface AdvPhoneNumberProps extends AdvCommonControlProps {
  type?: string;
  height?: number;
  lowercase?: boolean;
  uppercase?: boolean;
  maxLength?: number;
  minLength?: number;
  fieldName?: string;
  onBlur?: (e?) => any;
  onFocus?: (e?) => any;
  isClearable?: boolean;
  autoWidth?: boolean;
  minWidth?: number;
  preventChars?: string;
}

const name = "phone";

let defaultCountryCallback = () => undefined;

const AdvPhoneNumberInput: React.ForwardRefExoticComponent<
  AdvPhoneNumberProps & { children?: React.ReactNode }
> & {
  formatPhoneNumber: typeof formatPhoneNumberIntl;
  setDefaultCountryCallback: (callback: () => string) => void;
} = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvPhoneNumberProps>
>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>();
  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const lastValueRef = useRef<string>(props.value);
  const rawValueRef = useRef<string>(props.value);
  const inputChangedRef = useRef<boolean>(false);
  const countryChangedRef = useRef<"automatically" | "manually" | false>(false);
  const lastCountryRef = useRef<any>(defaultCountryCallback());
  const countryRef = useRef<any>(defaultCountryCallback());

  const tooltipIsBig =
    typeof props.tooltip === "string" ? false : !!props.tooltip?.isBig;

  const id = useCallback(() => {
    return !props.autocomplete ? makeid(5) : null;
  }, []);

  const fieldName = useCallback(() => {
    return id ? props.fieldName + "$" + id : props.fieldName;
  }, [id, props.fieldName]);

  const value = useMemo(() => {
    return defined(props.value) ? props.value : "";
  }, [props.value]);

  const hasValue = value;

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: hasValue,
    labelRef: labelRef,
    value: value,
  });

  const {
    onBlur,
    onFocus,
    className,
    style,
    onAnimationStart,
    onAnimationEnd,
  } = useControl({
    name,
    value,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    height: props.height,
    notify: props.notify,
  });

  const handleValueChange = useCallback(
    (value) => {
      let finalValue = value;

      if (!value) {
        finalValue = rawValueRef.current;
      }

      if (countryChangedRef.current === "manually") {
        if (rawValueRef.current && lastCountryRef.current) {
          const parsed = parsePhoneNumber(
            rawValueRef.current,
            lastCountryRef.current
          );
          if (parsed) {
            parsed.country = countryRef.current;
            finalValue = parsed.formatInternational();
          } else {
            if (inputChangedRef.current === false) {
              const countryCallingCode = getCountryCallingCode(
                countryRef.current
              );
              finalValue = `+${countryCallingCode}`;
            }
          }
        } else if (!rawValueRef.current) {
          if (inputChangedRef.current === false) {
            const countryCallingCode = getCountryCallingCode(
              countryRef.current
            );
            finalValue = `+${countryCallingCode}`;
          }
        }
      } else if (countryChangedRef.current === "automatically") {
      }

      inputChangedRef.current = false;
      countryChangedRef.current = false;

      lastCountryRef.current = countryRef.current;

      rawValueRef.current = finalValue;

      if (props.onCommit) {
        props.onCommit(finalValue);
      }
    },
    [props.onCommit]
  );

  const handleCountryChange = useCallback(
    (country) => {
      if (!inputChangedRef.current) {
        countryChangedRef.current = "manually";
        if (!country) {
        } else {
          countryRef.current = country;
        }
        countryChangedRef.current = "manually";
      } else {
        countryRef.current = country;
        countryChangedRef.current = "automatically";
      }
    },
    [props.value]
  );

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  const handleStopClearClickPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);

      const $input = controlRef.current.querySelector("input");
      if ($input) {
        $input.blur();
      }
    },
    [props.onCommit, onBlur]
  );

  const handleMouseEnter = useCallback((e) => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (tooltipIsBig) return;
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, tooltipIsBig, props.tooltip, hasValue, focused]);

  /**
   * If component comes disabled or readonly, blur it
   */
  useSecondEffect(() => {
    if (props.disabled || props.readOnly) {
      onBlur();
    }
  }, [props.disabled, props.readOnly]);

  useLayoutEffect(() => {
    (controlRef.current as any).commit = (value: any) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  const handleFocused = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip]);

  const handleBlurred = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip]);

  const InputComponent = useCallback(
    React.forwardRef<any, any>((internalProps, ref) => {
      const handleOnChange = useCallback(
        (e) => {
          const rawValue = e.target?.value || "";

          rawValueRef.current = rawValue;
          inputChangedRef.current = true;

          internalProps.onChange(e);
        },
        [internalProps.onChange]
      );

      return (
        <input
          ref={useCombinedRef(ref, inputRef)}
          onFocus={internalProps.onFocus}
          onBlur={internalProps.onBlur}
          value={internalProps.value}
          onChange={handleOnChange}
          disabled={internalProps.disabled}
          readOnly={internalProps.readOnly}
        />
      );
    }),
    []
  );

  return (
    <>
      {props.tooltip && !tooltipIsBig && finalShowTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        style={style}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={useCombinedRef(controlRef, setReferenceElement)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            startIconSize={props.startIconSize}
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.endIcon ? (
          <EndIcon
            endIcon={props.endIcon}
            endIconSpin={props.endIconSpin}
            endIconTooltip={props.endIconTooltip}
            onEndIconClick={props.onEndIconClick}
          />
        ) : null}
        <label>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              props.value ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
                onClick={handleStopClearClickPropagation}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          <PhoneInput
            defaultCountry={countryRef.current}
            onAnimationStart={onAnimationStart}
            onAnimationEnd={onAnimationEnd}
            type={props.type}
            name={fieldName}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={props.disabled}
            readOnly={props.readOnly}
            tabIndex={props.tabIndex}
            onCountryChange={handleCountryChange}
            onChange={handleValueChange}
            inputComponent={InputComponent}
            value={props.value}
          />
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
      </span>
    </>
  );
}) as any;

AdvPhoneNumberInput.defaultProps = {
  type: "text",
  autocomplete: false,
};

AdvPhoneNumberInput.formatPhoneNumber = formatPhoneNumberIntl;
AdvPhoneNumberInput.setDefaultCountryCallback = (callback: () => string) => {
  defaultCountryCallback = callback;
};

export default AdvPhoneNumberInput;
