import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import React, { useCallback } from "react";
import { AdvCheckbox } from "../../AdvCheckbox";
import { cm } from "../../utils";
import { StateActions } from "../hooks/create/useCreateSelectState";
import useAddItem from "../hooks/useAddItem";
import useItemIcon from "../hooks/useItemIcon";
import useItemIsDisabled from "../hooks/useItemIsDisabled";
import useItemRender from "../hooks/useItemRender";
import useRefs from "../hooks/useRefs";
import useRemoveItem from "../hooks/useRemoveItem";
import useSelectDispatch from "../hooks/useSelectDispatch";
import { AdvSelectOption } from "../types";
import ccMenu from "./../ccMenu";

export interface AdvSelectMenuItemProps {
  item: AdvSelectOption;
  label: string;
  index: number;
  highlighted?: boolean;
  multiple?: boolean;
  isCheckboxes?: boolean;
  isSingleSelect?: boolean;
  selected?: boolean;
  isMobile?: boolean;
  singleResult?: boolean;
}

const AdvSelectMenuItem: React.FC<AdvSelectMenuItemProps> = (props) => {
  const addItem = useAddItem();
  const removeItem = useRemoveItem();
  const dispatch = useSelectDispatch();
  const itemRender = useItemRender();
  const itemIsDisabled = useItemIsDisabled();
  const ItemIcon = useItemIcon();
  const refs = useRefs();

  const disabled =
    typeof itemIsDisabled === "function" ? itemIsDisabled(props.item) : false;

  const handleMouseDown = useCallback(
    (e) => {
      if (disabled) return;

      if (props.multiple) {
        /** Prevent event bubbling to root component, to prevent refocus */
        e.stopPropagation();
        /** Prevent to blur. Keep focus where it is */
        e.preventDefault();
        if (!props.selected) {
          addItem(props.item);
          /** If component is single select, close menu after add item */
          if (props.isSingleSelect) {
            dispatch(StateActions.closeMenu());
          }
        } else if (props.index !== undefined) {
          removeItem(props.index);
        }

        if (props.isMobile) {
          refs.multipleQueryInputRef.current.blur();
        }
      } else {
        /** Prevent event bubbling to root component, to prevent refocus */
        e.stopPropagation();
        /** Prevent to blur. Keep focus where it is */
        e.preventDefault();
        /** Add item and close menu */
        addItem(props.item);
        dispatch(StateActions.closeMenu());

        if (props.isMobile) {
          /** Blur input if mobile (to hide keyboard) */
          refs.singleQueryInputRef.current.blur();
        }
      }
    },
    [
      props.item,
      addItem,
      props.index,
      props.selected,
      props.multiple,
      props.isMobile,
    ]
  );

  return (
    <div
      data-testid="select2-menu-item"
      className={clsx(
        ccMenu("item"),
        cm(ccMenu("item"), {
          highlighted: props.highlighted,
          selected: props.selected,
          checkbox: props.isCheckboxes,
          "single-result": props.singleResult,
          disabled: disabled,
        })
      )}
      onMouseDown={handleMouseDown}
      tabIndex={-1}
    >
      {props.isCheckboxes ? (
        <AdvCheckbox value={props.selected} preventFocus disabled={disabled} />
      ) : null}
      {itemRender ? (
        itemRender({
          item: props.item,
          label: props.label,
          multiple: props.multiple,
          disabled,
        })
      ) : (
        <>
          {ItemIcon ? (
            typeof ItemIcon === "function" ? (
              <ItemIcon value={props.item} />
            ) : (
              <Icon icon={ItemIcon} />
            )
          ) : null}
          <div
            className={ccMenu("item-label")}
            title={props.singleResult ? undefined : props.label}
          >
            {props.label}
          </div>
          {props.multiple && !props.isCheckboxes && props.selected ? (
            <div className={ccMenu("item-remove")}>
              <Icon icon="close" />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AdvSelectMenuItem;
