import React, { useContext, useMemo } from "react";
import {
  CalendarDataType,
  CalendarEventType,
  CalendarGroupType,
  type CalendarEventStyleType,
} from "./types";

export type CalendarSharedContextType = {
  isLoading: boolean;
  days: Date[];
  weekDayNames: string[];
  weekStartsOn: number;
  data: CalendarDataType;
  groupsMap: Record<string, CalendarGroupType>;
  onDayClick?: (date: Date) => any;
  onEventClick?: (event: CalendarEventType) => any;
  allowEventClick?: (event: CalendarEventType) => boolean;
  eventTitle?: (event: CalendarEventType) => string;
  eventStyle?: (
    event: CalendarEventType,
    group: CalendarGroupType
  ) => CalendarEventStyleType;
  tooltip?: boolean;
};

const CalendarSharedContext =
  React.createContext<CalendarSharedContextType>(null);

export interface CalendarSharedContextProviderProps {
  isLoading: boolean;
  days: Date[];
  weekDayNames: string[];
  weekStartsOn: number;
  data: CalendarDataType;
  onDayClick?: (date: Date) => any;
  onEventClick?: (event: CalendarEventType) => any;
  allowEventClick?: (event: CalendarEventType) => boolean;
  eventTitle?: (event: CalendarEventType) => string;
  eventStyle?: (
    event: CalendarEventType,
    group: CalendarGroupType
  ) => CalendarEventStyleType;
  tooltip?: boolean;
}

const CalendarSharedContextProvider: React.FC<
  CalendarSharedContextProviderProps
> = (props) => {
  const value = useMemo<CalendarSharedContextType>(() => {
    const groupsMap = props.data.groups.reduce((acc, group) => {
      acc[group.id] = group;
      return acc;
    }, {});

    return {
      isLoading: props.isLoading,
      days: props.days,
      weekDayNames: props.weekDayNames,
      weekStartsOn: props.weekStartsOn,
      data: props.data,
      groupsMap: groupsMap,
      onDayClick: props.onDayClick,
      onEventClick: props.onEventClick,
      allowEventClick: props.allowEventClick,
      eventTitle: props.eventTitle,
      eventStyle: props.eventStyle,
      tooltip: props.tooltip,
    };
  }, [
    props.isLoading,
    props.days,
    props.weekDayNames,
    props.weekStartsOn,
    props.data,
    props.onDayClick,
    props.onEventClick,
    props.allowEventClick,
    props.eventTitle,
    props.eventStyle,
    props.tooltip,
  ]);

  return (
    <CalendarSharedContext.Provider value={value}>
      {props.children}
    </CalendarSharedContext.Provider>
  );
};

const useCalendarSharedContext = () => {
  return useContext(CalendarSharedContext);
};

export { CalendarSharedContextProvider, useCalendarSharedContext };
