import React from "react";
import CalendarMobileBodyWeekGridCell from "./CalendarMobileBodyWeekGridCell";

export interface CalendarMobileBodyWeekGridCellsProps {
  week: Date[];
}

const CalendarMobileBodyWeekGridCells: React.FC<CalendarMobileBodyWeekGridCellsProps> =
  (props) => {
    return (
      <div className="rivile-calendar-body-week-grid-cells">
        {props.week.map((day, index) => {
          return <CalendarMobileBodyWeekGridCell key={index} day={day} />;
        })}
      </div>
    );
  };

export default CalendarMobileBodyWeekGridCells;
