import clsx from "clsx";
import { isToday, isWeekend } from "date-fns";
import React from "react";
import { useCalendarMobileContext } from "../../../CalendarMobile/CalendarMobileContext";

export interface CalendarMobileBodyWeekGridCellProps {
  day: Date;
}

const CalendarMobileBodyWeekGridCell: React.FC<
  CalendarMobileBodyWeekGridCellProps
> = (props) => {
  const { currentMonth } = useCalendarMobileContext();

  const monthDay = props.day.getDate();
  const month = props.day.getMonth();
  const isDayWeekend = isWeekend(props.day);
  const isDayToday = isToday(props.day);
  const isOutsideMonth = month !== currentMonth;

  return (
    <div
      key={monthDay}
      className={clsx(
        "rivile-calendar-body-week-grid-cell",
        isDayWeekend && "is-weekend",
        isDayToday && "is-today",
        isOutsideMonth && "is-outside-month"
      )}
    ></div>
  );
};

export default CalendarMobileBodyWeekGridCell;
