import { useMemo } from "react";
import { useCalendarSharedContext } from "../CalendarSharedContext";
import { useCalendarTimelineConfig } from "../CalendarTimeline/CalendarTimelineConfigContext";

const useTimelineCSSProperties = () => {
  const { days } = useCalendarSharedContext();
  const { minCellWidth, defaultGroupsWidth } = useCalendarTimelineConfig();

  const cssProperties = useMemo<Record<string, any>>(() => {
    return {
      "--rivile-calendar-days-count": days.length,
      "--rivile-calendar-cell-min-width": minCellWidth + "px",
      "--rivile-calendar-weekdays-count": days.length,
      '--rivile-calendar-groups-default-width': defaultGroupsWidth ? defaultGroupsWidth + 'px' : undefined
    };
  }, [days, minCellWidth]);

  return cssProperties;
};

export default useTimelineCSSProperties;
