import clsx from "clsx";
import { isToday, isWeekend } from "date-fns";
import React, { useCallback } from "react";
import { useCalendarMobileContext } from "../../../CalendarMobile/CalendarMobileContext";
import { useCalendarSharedContext } from "../../../CalendarSharedContext";

export interface CalendarMobileBodyWeekHeaderDayProps {
  day: Date;
}

const CalendarMobileBodyWeekHeaderDay: React.FC<
  CalendarMobileBodyWeekHeaderDayProps
> = (props) => {
  const monthDay = props.day.getDate();
  const month = props.day.getMonth();

  const { onDayClick } = useCalendarSharedContext();

  const { currentMonth } = useCalendarMobileContext();

  const handleDayClick = useCallback(
    (e) => {
      if (typeof onDayClick === "function") {
        onDayClick(props.day);
      }
    },
    [onDayClick, props.day]
  );

  const isDayWeekend = isWeekend(props.day);
  const isDayToday = isToday(props.day);
  const isOutsideMonth = month !== currentMonth;

  return (
    <div
      className={clsx(
        "rivile-calendar-body-week-header-day",
        onDayClick && "clickable",
        isDayWeekend && "is-weekend",
        isDayToday && "is-today",
        isOutsideMonth && "is-outside-month"
      )}
      onClick={handleDayClick}
    >
      {monthDay}
    </div>
  );
};

export default CalendarMobileBodyWeekHeaderDay;
