import { Icon } from "@adv-libs/icons";
import React from "react";
import cc from "./cc";

export interface AdvSelectArrowIndicatorProps {
  isOpen: boolean;
}

const AdvSelectArrowIndicator: React.FC<AdvSelectArrowIndicatorProps> = (
  props
) => {
  return (
    <div className={cc(`indicator-dropdown-icon`)}>
      <Icon
        icon={props.isOpen ? "caret-up" : "caret-down"}
        style={{ fontSize: 10 }}
      />
    </div>
  );
};

export default AdvSelectArrowIndicator;
