import { makeid, useCombinedRef } from "@adv-libs/utils";
import React, { useCallback, useMemo, useRef } from "react";
import cc from "./cc";
import { StateActions } from "./hooks/create/useCreateSelectState";
import useItemManipulation from "./hooks/useItemManipulation";
import useQuery from "./hooks/useQuery";
import useRefs from "./hooks/useRefs";
import useSelectDispatch from "./hooks/useSelectDispatch";
import useSelectedItemRender from "./hooks/useSelectedItemRender";
import useTabIndex from "./hooks/useTabIndex";
import { AdvSelectOption } from "./types";

export interface AdvSelectValueProps {
  value: AdvSelectOption | null;
  disabled?: boolean;
}

const AdvSelectValue: React.FC<AdvSelectValueProps> = (props) => {
  const { getSelectedItemLabel } = useItemManipulation();
  const tabIndex = useTabIndex();
  const inputRef = useRef<HTMLInputElement>();
  const selectedItemRender = useSelectedItemRender();

  const refs = useRefs();

  const query = useQuery();
  const dispatch = useSelectDispatch();

  const handleQueryChange = useCallback((e) => {
    dispatch(StateActions.changeQuery(e.target.value));
  }, []);

  const searchInputFieldName = useMemo(() => {
    return "$" + makeid(5);
  }, []);

  const label = getSelectedItemLabel(props.value);

  return (
    <div className={cc("single-value")}>
      <span
        className={cc("value")}
        style={{ display: query?.length ? "none" : "block" }}
      >
        {selectedItemRender
          ? props.value
            ? selectedItemRender({
                item: props.value,
                multiple: false,
                label: label,
              })
            : null
          : label}
      </span>
      <input
        ref={useCombinedRef(inputRef, refs.singleQueryInputRef)}
        name={searchInputFieldName}
        value={query || ""}
        onChange={handleQueryChange}
        type="text"
        className={cc(`single-value-input`)}
        tabIndex={tabIndex}
        disabled={props.disabled}
      ></input>
    </div>
  );
};

export default AdvSelectValue;
