import React, { useContext, useMemo } from "react";

export type AdvDatePickerContextType = {
  value: Date;
};

const AdvDatePickerContext = React.createContext<AdvDatePickerContextType>({
  value: null,
});

export interface AdvDatePickerContextProviderProps {
  value: Date;
}

const AdvDatePickerContextProvider: React.FC<
  AdvDatePickerContextProviderProps
> = (props) => {
  const value = useMemo(() => {
    return {
      value: props.value,
    };
  }, [props.value]);

  return (
    <AdvDatePickerContext.Provider value={value}>
      {props.children}
    </AdvDatePickerContext.Provider>
  );
};

const useAdvDatePickerContext = () => {
  return useContext(AdvDatePickerContext);
};

export { useAdvDatePickerContext, AdvDatePickerContextProvider };
