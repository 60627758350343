import { Icon } from "@adv-libs/icons";
import { useCombinedRef } from "@adv-libs/utils";
import React, { useCallback, useMemo } from "react";
import AdvControlLabel from "../AdvControlLabel";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";

export interface AdvTextareaProps extends AdvCommonControlProps {
  fullHeight?: boolean;
  rows?: number;
  fieldName?: string;
  maxLength?: number;
  showLengthCount?: boolean;
  autoHeight?: boolean;
  resize?: boolean;
}

const name = "textarea";

const AdvTextarea = React.forwardRef<
  HTMLTextAreaElement,
  React.PropsWithChildren<AdvTextareaProps>
>((props, ref) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback(
    (e) => {
      props.onCommit(e.target.value);
    },
    [props]
  );

  const value = useMemo(() => {
    return props.value;
  }, [props.value]);

  const { onBlur, onFocus, className } = useControl({
    name,
    value,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    disabled: props.disabled,
    className: props.className,
    autocomplete: props.autocomplete,
    notify: props.notify,
  });

  const handleTextareaInput = useCallback(() => {
    if (props.autoHeight && textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, []);

  return (
    <span
      data-field={props.fieldName}
      data-testid={name + "|" + props.fieldName}
      className={className}
      style={{ height: props.fullHeight ? "100%" : undefined }}
    >
      <label>
        {props.startIcon ? (
          <span className={cp("control__start-icon")}>
            <Icon icon={props.startIcon} spin={props.startIconSpin} />
          </span>
        ) : null}
        {props.endIcon ? (
          <span className={cp("control__end-icon")}>
            <Icon icon={props.endIcon} spin={props.endIconSpin} />
          </span>
        ) : null}
        <textarea
          ref={useCombinedRef(textareaRef, ref)}
          maxLength={props.maxLength}
          value={value ? value : ""}
          rows={props.rows}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={props.disabled}
          readOnly={props.readOnly}
          onInput={handleTextareaInput}
          style={{
            resize: props.resize ? "vertical" : "none",
          }}
        />
        <span className={cp("control-textarea__count")}>
          {props.showLengthCount && props.maxLength
            ? typeof value === "string"
              ? props.maxLength - value.length
              : props.maxLength
            : null}
        </span>
        <AdvControlLabel label={props.label} />
      </label>
    </span>
  );
});

AdvTextarea.defaultProps = {
  autocomplete: false,
  resize: true,
};

export default AdvTextarea;
