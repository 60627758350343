import clsx from "clsx";
import React from "react";
import { cm, cp } from "../utils";

export interface AdvImagePlaceholderProps {
  title: string;
  hideImage?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const AdvImagePlaceholder: React.FC<AdvImagePlaceholderProps> = (props) => {
  return (
    <div className={cp("image-placeholder")}>
      <div className={cp("image-placeholder__content")}>
        {!props.hideImage ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56"
              height="46"
              fill="none"
              viewBox="0 0 56 46"
            >
              <path
                fill="#D3D3D3"
                d="M50.374 0H5.552C2.524 0 0 2.524 0 5.552v34.02c0 3.03 2.524 5.553 5.552 5.553h44.822c3.028 0 5.552-2.524 5.552-5.552V5.452C55.926 2.423 53.402 0 50.374 0V0zM5.552 2.019h44.822c1.918 0 3.533 1.615 3.533 3.533v23.219a13.59 13.59 0 00-14.335-.303l-6.663 4.038c-.1 0-.1.1-.1.1l-4.846-4.24c-4.745-4.24-11.609-4.542-16.758-.908L2.02 34.02V5.452c0-1.918 1.615-3.432 3.533-3.432v-.001zM2.02 39.572v-3.028l10.297-7.37c4.34-3.129 10.297-2.826 14.335.808L41.49 43.105H5.552c-1.918-.1-3.534-1.615-3.534-3.533h.001zm48.354 3.432h-5.855L34.221 33.92l6.36-3.836c3.735-2.22 8.58-2.12 12.316.303l1.01.706v8.38c0 2.018-1.616 3.532-3.534 3.532z"
              ></path>
              <path
                fill="#D3D3D3"
                d="M17.162 18.676c2.322 0 4.24-1.918 4.24-4.24s-1.918-4.24-4.24-4.24-4.24 1.918-4.24 4.24 1.918 4.24 4.24 4.24zm0-6.461a2.24 2.24 0 012.22 2.22 2.24 2.24 0 01-2.22 2.222 2.24 2.24 0 01-2.22-2.221 2.24 2.24 0 012.22-2.221z"
              ></path>
            </svg>
            {!props.disabled ? (
              <svg
                className={cp("image-placeholder__plus")}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
              >
                <circle cx="7" cy="7" r="7" fill="#00B373"></circle>
                <path
                  fill="#fff"
                  d="M6.32 10.122h1.61V7.616h2.52V6.02H7.93V3.5H6.32v2.52H3.8v1.596h2.52v2.506z"
                ></path>
              </svg>
            ) : null}
          </>
        ) : null}
      </div>
      <div
        className={clsx(
          cp("image-placeholder__title"),
          cm(cp("image-placeholder__title"), {
            error: props.error,
          })
        )}
      >
        {props.title}
      </div>
    </div>
  );
};

export default AdvImagePlaceholder;
