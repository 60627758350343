import React from "react";
import { toast as originalToast } from "react-toastify";
import ToastComponent from "./ToastComponent";
import { Toast, ToastContent, ToastOptions } from "./types";

const dismiss = () => {
  originalToast.clearWaitingQueue();
  originalToast.dismiss();
};

const error = (
  content: ToastContent,
  options: ToastOptions = { autoClose: false },
  dismissPrevious: boolean = true
) => {
  const autoClose = options.autoClose ?? false;
  if (dismissPrevious) {
    dismiss();
  }
  return originalToast.error(
    <ToastComponent
      content={content}
      icon="warning"
      intent="error"
      progressBar={autoClose}
    />,
    {
      autoClose: autoClose,
      onClose: options.onClose,
      onOpen: options.onOpen,
    }
  );
};

const info = (
  content: ToastContent,
  options: ToastOptions = { autoClose: 5000 },
  dismissPrevious: boolean = true
) => {
  const autoClose = options.autoClose ?? 5000;
  if (dismissPrevious) {
    dismiss();
  }
  return originalToast.info(
    <ToastComponent
      content={content}
      icon={["information", 2136]}
      intent="info"
      progressBar={autoClose}
    />,
    {
      autoClose: autoClose,
      onClose: options.onClose,
      onOpen: options.onOpen,
    }
  );
};

const warning = (
  content: ToastContent,
  options: ToastOptions = { autoClose: false },
  dismissPrevious: boolean = true
) => {
  const autoClose = options.autoClose ?? false;
  if (dismissPrevious) {
    dismiss();
  }
  return originalToast.warning(
    <ToastComponent
      content={content}
      icon={"warning"}
      intent="warning"
      progressBar={autoClose}
    />,
    {
      autoClose: autoClose,
      onClose: options.onClose,
      onOpen: options.onOpen,
    }
  );
};

const success = (
  content: ToastContent,
  options: ToastOptions = { autoClose: 5000 },
  dismissPrevious: boolean = true
) => {
  const autoClose = options.autoClose ?? 5000;
  if (dismissPrevious) {
    dismiss();
  }
  return originalToast.success(
    <ToastComponent
      content={content}
      icon={"success"}
      intent="success"
      progressBar={autoClose}
    />,
    {
      autoClose: autoClose,
      onClose: options.onClose,
      onOpen: options.onOpen,
    }
  );
};

const toast: Toast = (
  content: ToastContent,
  options?: ToastOptions,
  dismissPrevious?: boolean
) => info(content, options, dismissPrevious);

toast.error = error;
toast.info = info;
toast.warn = warning;
toast.warning = warning;
toast.success = success;
toast.dismiss = dismiss;
toast.is = () => {
  return !!document.querySelector(".r365-toast");
};

export default toast;
