import React, { useCallback, useContext, useMemo, useState } from "react";
import { CalendarGroupType } from "../types.js";

export type CalendarTimelineContextType = {
  startDate: Date;
  endDate: Date;
  showMonthsHeader: boolean;
  groupStyle: (group: CalendarGroupType) => React.CSSProperties;
  update: () => any;
  iteration: number;
};

const CalendarTimelineContext =
  React.createContext<CalendarTimelineContextType>(null);

export interface CalendarTimelineContextProviderProps {
  startDate: Date;
  endDate: Date;
  showMonthsHeader: boolean;
  groupStyle: (group: CalendarGroupType) => React.CSSProperties;
}

const CalendarTimelineContextProvider: React.FC<
  CalendarTimelineContextProviderProps
> = (props) => {
  const [iteration, setIteration] = useState(0);

  const update = useCallback(() => {
    setIteration((prev) => prev + 1);
  }, []);

  const value = useMemo<CalendarTimelineContextType>(() => {
    return {
      startDate: props.startDate,
      endDate: props.endDate,
      showMonthsHeader: props.showMonthsHeader,
      groupStyle: props.groupStyle,
      update: update,
      iteration: iteration,
    };
  }, [
    props.startDate,
    props.endDate,
    props.showMonthsHeader,
    props.groupStyle,
    update,
    iteration,
  ]);

  return (
    <CalendarTimelineContext.Provider value={value}>
      {props.children}
    </CalendarTimelineContext.Provider>
  );
};

const useCalendarTimelineContext = () => {
  return useContext(CalendarTimelineContext);
};

export { CalendarTimelineContextProvider, useCalendarTimelineContext };
