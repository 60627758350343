import { useCombinedRef, useIsMobile } from "@adv-libs/utils";
import React from "react";
import useCursor from "../hooks/useCursor";
import useIsMultiple from "../hooks/useIsMultiple";
import useIsMultipleCheckboxes from "../hooks/useIsMultipleCheckboxes";
import useIsSingleSelect from "../hooks/useIsSingleSelect";
import useValue from "../hooks/useValue";
import ccMenu from "./../ccMenu";
import useFilteredItems from "./../hooks/useFilteredItems";
import useItemManipulation from "./../hooks/useItemManipulation";
import AdvSelectMenuFooter from "./AdvSelectMenuFooter";
import AdvSelectMenuItem from "./AdvSelectMenuItems";
import AdvSelectMenuToolbar from "./AdvSelectMenuToolbar";
import useMenuScrollHandler from "./useMenuScrollHandler";

export interface AdvSelectMenuProps {}

const AdvSelectMenu = React.forwardRef<HTMLDivElement, AdvSelectMenuProps>(
  (props, ref: React.MutableRefObject<HTMLDivElement>) => {
    const { getItemValue, getItemLabel, getSelectedItemValue } =
      useItemManipulation();

    const isCheckboxes = useIsMultipleCheckboxes();

    const cursor = useCursor();

    const { onScroll, scrollMenuRef, menuToolbarRef } = useMenuScrollHandler();

    const filteredItems = useFilteredItems();
    const value = useValue();
    const isMultiple = useIsMultiple();
    const isSingleSelect = useIsSingleSelect();
    const isMobile = useIsMobile();

    const singleResult = filteredItems.length === 1;

    return (
      <>
        <AdvSelectMenuToolbar ref={menuToolbarRef} />
        <div
          tabIndex={-1}
          ref={useCombinedRef(ref, scrollMenuRef)}
          className={ccMenu()}
          onScroll={onScroll}
        >
          {/* Show fetched and filtered items */}
          {filteredItems.map((item, filteredIndex) => {
            const itemValue = getItemValue(item);
            const itemLabel = getItemLabel(item);

            let finalIndex = filteredIndex;
            let selected = false;

            if (isCheckboxes) {
              const valueIndex = value
                ? value.findIndex((valueItem) => {
                    return getSelectedItemValue(valueItem) === itemValue;
                  })
                : -1;

              if (valueIndex > -1) {
                selected = true;
                finalIndex = valueIndex;
              } else {
                finalIndex = undefined;
              }
            }

            return (
              <AdvSelectMenuItem
                key={itemValue}
                index={finalIndex}
                item={item}
                label={itemLabel}
                highlighted={filteredIndex === cursor}
                multiple={isMultiple}
                selected={selected}
                isCheckboxes={isCheckboxes}
                isSingleSelect={isSingleSelect}
                isMobile={isMobile}
                singleResult={singleResult}
              />
            );
          })}
        </div>
        <AdvSelectMenuFooter />
      </>
    );
  }
);

export default AdvSelectMenu;
