import clsx from "clsx";
import React, { useMemo } from "react";
import { useCalendarSharedContext } from "../CalendarSharedContext";

export interface CalendarMobileHeaderProps {}

const CalendarMobileHeader: React.FC<CalendarMobileHeaderProps> = (props) => {
  const { weekDayNames, weekStartsOn } = useCalendarSharedContext();

  const weekDays = useMemo(() => {
    return new Array(7).fill(0).map((_, index) => {
      return index;
    });
  }, []);

  return (
    <div className="rivile-calendar-header rivile-calendar-header--mobile">
      <div className="rivile-calendar-header-content">
        <div className="rivile-calendar-header-weekdays">
          {weekDays.map((_, day) => {
            const weekDayName = weekDayNames[day];
            let dayForWeekend = day + weekStartsOn;
            dayForWeekend =
              dayForWeekend > 6 ? dayForWeekend - 7 : dayForWeekend;

            const isDayWeekend = dayForWeekend === 0 || dayForWeekend === 6;

            return (
              <div
                key={day}
                className={clsx(
                  "rivile-calendar-header-weekday",
                  isDayWeekend && "is-weekend"
                )}
              >
                {weekDayName}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarMobileHeader;
