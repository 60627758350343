import React from "react";
import { useCalendarTimelineScrollableContext } from "../../CalendarTimeline/CalendarTimelineScrollableContext";
import CalendarTimelineBodyGridCells from "./CalendarTimelineBodyGridCells";

export interface CalendarTimelineBodyGridScrollableProps {}

const CalendarTimelineBodyGridScrollable: React.FC<CalendarTimelineBodyGridScrollableProps> =
  (props) => {
    const { bodyGridRef } = useCalendarTimelineScrollableContext();

    return (
      <div ref={bodyGridRef} className="rivile-calendar-body-grid-scrollable">
        <CalendarTimelineBodyGridCells />
      </div>
    );
  };

export default CalendarTimelineBodyGridScrollable;
