import React from "react";
import { CalendarEventInWeekType } from "../../../types";
import CalendarMobileBodyWeekGrid from "./CalendarMobileBodyWeekGrid";
import CalendarMobileBodyWeekHeader from "./CalendarMobileBodyWeekHeader";

export interface CalendarMobileBodyWeekProps {
  week: Date[];
  isExpanded: boolean;
  weekGroups: {
    weekStart: Date;
    weekEnd: Date;
    events: CalendarEventInWeekType[][];
  };
  weekIndex: number;
  onWeekClose: () => any;
  onWeekExpand: (weekIndex: number) => any;
}

const CalendarMobileBodyWeek: React.FC<CalendarMobileBodyWeekProps> = (
  props
) => {
  const { week, weekGroups, weekIndex, isExpanded, onWeekClose, onWeekExpand } =
    props;

  return (
    <div key={weekIndex} className="rivile-calendar-body-week">
      <CalendarMobileBodyWeekHeader week={week} />
      <CalendarMobileBodyWeekGrid
        week={week}
        weekGroups={weekGroups}
        weekIndex={weekIndex}
        onWeekClose={onWeekClose}
        onWeekExpand={onWeekExpand}
        isExpanded={isExpanded}
      />
    </div>
  );
};

export default CalendarMobileBodyWeek;
