import React, { useContext, useMemo } from "react";
import type { CalendarMobileConfigType } from "../types";

export type CalendarMobileConfigContextType = CalendarMobileConfigType;

const CalendarMobileConfigContext =
  React.createContext<CalendarMobileConfigContextType>(null);

export interface CalendarMobileConfigContextProviderProps
  extends CalendarMobileConfigType {}

const CalendarMobileConfigContextProvider: React.FC<
  CalendarMobileConfigContextProviderProps
> = (props) => {
  const value = useMemo<CalendarMobileConfigType>(() => {
    return {};
  }, []);

  return (
    <CalendarMobileConfigContext.Provider value={value}>
      {props.children}
    </CalendarMobileConfigContext.Provider>
  );
};

const useCalendarMobileConfig = () => {
  return useContext(CalendarMobileConfigContext);
};

export { CalendarMobileConfigContextProvider, useCalendarMobileConfig };
