import React, { useContext, useMemo } from "react";

export type CalendarMobileContextType = {
  currentYear: number;
  currentMonth: number;
  onSwipedLeft?: () => any;
  onSwipedRight?: () => any;
};

const CalendarMobileContext =
  React.createContext<CalendarMobileContextType>(null);

export interface CalendarMobileContextProviderProps {
  onSwipedLeft: () => any;
  onSwipedRight: () => any;
  currentYear: number;
  currentMonth: number;
}

const CalendarMobileContextProvider: React.FC<
  CalendarMobileContextProviderProps
> = (props) => {
  const value = useMemo<CalendarMobileContextProviderProps>(() => {
    return {
      onSwipedLeft: props.onSwipedLeft,
      onSwipedRight: props.onSwipedRight,
      currentYear: props.currentYear,
      currentMonth: props.currentMonth,
    };
  }, [
    props.onSwipedLeft,
    props.onSwipedRight,
    props.currentYear,
    props.currentMonth,
  ]);

  return (
    <CalendarMobileContext.Provider value={value}>
      {props.children}
    </CalendarMobileContext.Provider>
  );
};

const useCalendarMobileContext = () => {
  return useContext(CalendarMobileContext);
};

export { CalendarMobileContextProvider, useCalendarMobileContext };
