import React, { memo, useMemo } from "react";
import { Tooltip as TooltipContainer } from "react-tooltip";
import CalendarTimelineBody from "../CalendarBody/CalendarTimelineBody/CalendarTimelineBody";
import CalendarContainer from "../CalendarContainer";
import CalendarContent from "../CalendarContent";
import CalendarTimelineHeader from "../CalendarHeader/CalendarTimelineHeader";
import CalendarLoadingOverlay from "../CalendarLoadingOverlay";
import { useCalendarSharedContext } from "../CalendarSharedContext";
import useCalendarTimelineMeasure from "../hooks/useCalendarMeasure";
import useSharedCSSProperties from "../hooks/useSharedCSSProperties";
import useTimelineCSSProperties from "../hooks/useTimelineCSSProperties";
import { useCalendarTimelineDOMReferencesContext } from "./CalendarTimelineDOMReferencesContext";
import { CalendarTimelineGroupsResizeContextProvider } from "./CalendarTimelineGroupsResizeContext";
import { CalendarTimelineScrollableContextProvider } from "./CalendarTimelineScrollableContext";

interface CalendarTimelineCoreProps {}

const CalendarTimelineCore: React.FC<CalendarTimelineCoreProps> = memo(
  (props) => {
    const { days } = useCalendarSharedContext();

    const sharedCSSProperties = useSharedCSSProperties();
    const timelineCSSProperties = useTimelineCSSProperties();

    const domRefs = useCalendarTimelineDOMReferencesContext();

    const { onGroupsResize } = useCalendarTimelineMeasure({
      daysCount: days.length,
    });

    const containerStyle = useMemo(() => {
      return {
        ...sharedCSSProperties,
        ...timelineCSSProperties,
      };
    }, [sharedCSSProperties, timelineCSSProperties]);

    return (
      <CalendarContainer
        ref={domRefs.calendarRef}
        style={containerStyle}
        className={"rivile-calendar--timeline"}
      >
        <TooltipContainer
          id="rivile-calendar-tooltip"
          className="rivile-calendar-tooltip"
        />
        <CalendarContent>
          <CalendarTimelineGroupsResizeContextProvider
            onGroupsResize={onGroupsResize}
          >
            <CalendarTimelineScrollableContextProvider>
              <CalendarTimelineHeader />
              <CalendarTimelineBody />
            </CalendarTimelineScrollableContextProvider>
          </CalendarTimelineGroupsResizeContextProvider>
          <CalendarLoadingOverlay />
        </CalendarContent>
      </CalendarContainer>
    );
  }
);

export default CalendarTimelineCore;
