import { Icon } from "@adv-libs/icons";
import { useTranslation } from "@adv-libs/l10n";
import { makeid, useDebounced, useIsMobile } from "@adv-libs/utils";
import React, { useCallback, useMemo } from "react";
import { cp } from "../../utils";
import { getHandledError } from "../errorHandler";
import useClearValue from "../hooks/useClearValue";
import usIsFirstTimeLoading from "../hooks/useIsFirstTimeLoading";
import useIsMultiple from "../hooks/useIsMultiple";
import useIsMultipleCheckboxes from "../hooks/useIsMultipleCheckboxes";
import useIsSingleSelect from "../hooks/useIsSingleSelect";
import useMultipleHideSearch from "../hooks/useMultipleHideSearch";
import useOnCommit from "../hooks/useOnCommit";
import useRefs from "../hooks/useRefs";
import useSelectError from "../hooks/useSelectError";
import useTabIndex from "../hooks/useTabIndex";
import useValue from "../hooks/useValue";
import { StateActions } from "./../hooks/create/useCreateSelectState";
import useFilteredItemsCount from "./../hooks/useFilteredItemsCount";
import useIsLoading from "./../hooks/useIsLoading";
import useItemManipulation from "./../hooks/useItemManipulation";
import useQuery from "./../hooks/useQuery";
import useSelectDispatch from "./../hooks/useSelectDispatch";
import AdvSelectMenuItem from "./AdvSelectMenuItems";

export interface AdvSelectItemsControlProps {}

const name = "select2__items-control";

const cc = (className?: string) => {
  return cp(`control-${name}${className ? `-${className}` : ""}`);
};

const AdvSelectMenuToolbar = React.forwardRef<
  HTMLDivElement,
  AdvSelectItemsControlProps
>((props, ref) => {
  const itemManipulation = useItemManipulation();
  const dispatch = useSelectDispatch();
  const filteredItemsCount = useFilteredItemsCount();
  const query = useQuery();
  const debouncedQuery = useDebounced(query, 300);
  const isLoading = useIsLoading();
  const isFirstTimeLoading = usIsFirstTimeLoading();
  const isMultiple = useIsMultiple();
  const isCheckboxes = useIsMultipleCheckboxes();
  const isSingleSelect = useIsSingleSelect();
  const onCommit = useOnCommit();
  const value = useValue();
  const tabIndex = useTabIndex();
  const hideSearch = useMultipleHideSearch();
  const clearValue = useClearValue();
  const error = useSelectError();

  const handledError = getHandledError(error);

  const hasValue = Array.isArray(value) ? value.length > 0 : !!value;

  const refs = useRefs();

  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const handleQueryChange = useCallback(
    (e) => {
      if (isMultiple) {
        dispatch(StateActions.changeQuery(e.target.value));
      }
    },
    [isMultiple]
  );

  const searchInputFieldName = useMemo(() => {
    return "$" + makeid(5);
  }, []);

  const handleClearClick = useCallback(() => {
    onCommit(clearValue ?? undefined);
  }, [clearValue, onCommit]);

  const handleMultipleQueryInputMouseDown = useCallback(
    (e) => {
      if (isMobile) {
        e.stopPropagation();
      }
    },
    [isMobile]
  );

  const handleMultipleQueryInputBlur = useCallback(
    (e) => {
      if (isMobile) {
        e.stopPropagation();
      }
    },
    [isMobile]
  );

  return (
    <div ref={ref} className={cc()}>
      {isMultiple ? (
        hideSearch ? (
          <input
            ref={refs.multipleQueryInputRef}
            style={{
              position: "absolute",
              opacity: 0,
              width: 1,
              height: 1,
            }}
          />
        ) : (
          <div className={cc("search")}>
            <Icon icon="search-finder" />
            <input
              placeholder={t("Search")}
              ref={refs.multipleQueryInputRef}
              name={searchInputFieldName}
              type="text"
              tabIndex={tabIndex}
              onChange={handleQueryChange}
              onMouseDown={handleMultipleQueryInputMouseDown}
              onBlur={handleMultipleQueryInputBlur}
              value={query || ""}
            />
          </div>
        )
      ) : null}
      {isMultiple && !isSingleSelect && !isCheckboxes && hasValue ? (
        <div className={cc("selected-multiple-items")}>
          <div className={cc("selected-multiple-items-header")}>
            <div className={cc("selected-multiple-items-header-title")}>
              {t("Selected items")}
            </div>
            <div className={cc("selected-multiple-items-header-clear-button")}>
              <div role="button" onClick={handleClearClick}>
                {t("Clear")}
              </div>
            </div>
          </div>
          <div className={cc("selected-multiple-items-list")}>
            {value.map((value, index) => {
              const selectedItemValue =
                itemManipulation.getSelectedItemValue(value);
              const selectedItemLabel =
                itemManipulation.getSelectedItemLabel(value);
              return (
                <AdvSelectMenuItem
                  key={selectedItemValue}
                  index={index}
                  item={value}
                  label={selectedItemLabel}
                  selected
                  multiple={isMultiple}
                />
              );
            })}
          </div>
        </div>
      ) : null}
      {error ? <div className={cc("error")}>{handledError}</div> : null}
      {isLoading && isFirstTimeLoading ? (
        <div className={cc("loading")}> {t("Loading...")}</div>
      ) : null}
      {debouncedQuery &&
      !error &&
      !isLoading &&
      !filteredItemsCount &&
      hasValue ? (
        <div className={cc("no-options")}>{t("No more options found")}</div>
      ) : null}
      {debouncedQuery &&
      !error &&
      !isLoading &&
      !filteredItemsCount &&
      !hasValue ? (
        <div className={cc("no-options")}>{t("No options found")}</div>
      ) : null}
      {!debouncedQuery &&
      !error &&
      !isLoading &&
      !filteredItemsCount &&
      !isMultiple ? (
        <div className={cc("no-options")}>{t("No options")}</div>
      ) : null}
    </div>
  );
});

export default AdvSelectMenuToolbar;
