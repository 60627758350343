const isDateRangesOverlaping = (
  startDate0: Date,
  endDate0: Date,
  startDate1: Date,
  endDate1: Date
) => {
  return (
    (startDate0 >= startDate1 && startDate0 <= endDate1) ||
    (endDate0 >= startDate1 && endDate0 <= endDate1) ||
    (startDate0 <= startDate1 && endDate0 >= endDate1)
  );
};

export default isDateRangesOverlaping;
