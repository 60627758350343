import { createJexl } from "@adv-libs/utils";
import { dot } from "@adv-libs/utils";
import { useCallback, useMemo } from "react";
import interpolateJexl from "../../interpolateJexl";
import {
  AdvSelectCommonProps,
  AdvSelectOption,
  ItemManipulation,
} from "../../types";

export interface UseCreateItemManipulationOptions {
  valueParam: AdvSelectCommonProps["valueParam"];
  labelParam: AdvSelectCommonProps["labelParam"];
  selectedValueParam: AdvSelectCommonProps["selectedValueParam"];
  selectedLabelParam: AdvSelectCommonProps["selectedLabelParam"];
}

const useCreateItemManipulation = (
  options: UseCreateItemManipulationOptions
): ItemManipulation => {
  const getItemValue = useCallback(
    (item: AdvSelectOption) => {
      if (
        typeof options.valueParam === "string" &&
        options.valueParam.startsWith("jexl:")
      ) {
        return interpolateJexl(item, options.valueParam);
      } else if (typeof options.valueParam === "function") {
        return options.valueParam(item);
      } else {
        return dot.get(item, options.valueParam);
      }
    },
    [options.valueParam]
  );

  const getItemLabel = useCallback(
    (item: AdvSelectOption) => {
      if (
        typeof options.labelParam === "string" &&
        options.labelParam.startsWith("jexl:")
      ) {
        return interpolateJexl(item, options.labelParam);
      } else if (typeof options.labelParam === "function") {
        return options.labelParam(item);
      } else {
        return dot.get(item, options.labelParam);
      }
    },
    [options.labelParam]
  );

  const jexlSelectedValueTemplate = useMemo(() => {
    if (
      typeof options.selectedValueParam === "string" &&
      options.selectedValueParam.startsWith("jexl:")
    ) {
      const jexl = createJexl();
      return jexl.compile(
        options.selectedValueParam.replace("jexl:", "").trim()
      );
    } else {
      return null;
    }
  }, [options.selectedValueParam]);

  const getSelectedItemValue = useCallback(
    (item: AdvSelectOption) => {
      if (jexlSelectedValueTemplate) {
        return jexlSelectedValueTemplate.evalSync(item);
      } else if (typeof options.selectedValueParam === "function") {
        return options.selectedValueParam(item);
      } else if (options.selectedValueParam) {
        return dot.get(item, options.selectedValueParam);
      } else {
        return getItemValue(item);
      }
    },
    [options.selectedValueParam, jexlSelectedValueTemplate, getItemValue]
  );

  const jexlSelectedLabelTemplate = useMemo(() => {
    if (
      typeof options.selectedLabelParam === "string" &&
      options.selectedLabelParam.startsWith("jexl:")
    ) {
      const jexl = createJexl();
      return jexl.compile(
        options.selectedLabelParam.replace("jexl:", "").trim()
      );
    } else {
      return null;
    }
  }, [options.selectedLabelParam]);

  const getSelectedItemLabel = useCallback(
    (item: AdvSelectOption) => {
      if (jexlSelectedLabelTemplate) {
        return jexlSelectedLabelTemplate.evalSync(item);
      } else if (typeof options.selectedLabelParam === "function") {
        return options.selectedLabelParam(item);
      } else if (options.selectedLabelParam) {
        return dot.get(item, options.selectedLabelParam);
      } else {
        return getItemLabel(item);
      }
    },
    [options.selectedLabelParam, jexlSelectedLabelTemplate, getItemLabel]
  );

  const itemManipulation = useMemo<ItemManipulation>(() => {
    return {
      getItemLabel,
      getItemValue,
      getSelectedItemLabel,
      getSelectedItemValue,
    };
  }, [getItemLabel, getItemValue, getSelectedItemLabel, getSelectedItemValue]);

  return itemManipulation;
};

export default useCreateItemManipulation;
