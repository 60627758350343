import { useAutoNameFallback } from "@adv-libs/utils";
import clsx from "clsx";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { AdvCommonControlProps } from "../types";
import cm from "../utils/cm";
import cp from "../utils/cp";

export interface AdvRadioGroupProps extends AdvCommonControlProps {
  align?: "left" | "center" | "right";
  noHeight?: boolean;
  style?: CSSProperties;
  groupName?: string;
  value: { id: any; name: string } | any;
  items?: { id: any; name: string; disabled?: boolean }[];
  fieldName?: string;
  noLabel?: boolean;
}

const name = "radio";

const AdvRadioGroup = React.forwardRef<HTMLInputElement, AdvRadioGroupProps>(
  (props, ref) => {
    const handleChange = useCallback(
      (e) => {
        if (props.readOnly || props.disabled) return;
        const index = parseInt(e.target.dataset.index);
        const item = props.items[index];
        props.onCommit(item);
      },
      [props]
    );

    const groupName = useAutoNameFallback(props.groupName, "r365-radio");

    const controlClassName = cp("control");

    return (
      <span
        data-field={props.fieldName}
        data-testid={name + "|" + props.fieldName}
        className={clsx(
          cp("control"),
          cp("control-" + name),
          cp("control-" + name + "--" + props.align),
          cm(cp("control-" + name), {
            required: props.required,
            "no-label": !props.label,
            readonly: props.readOnly,
            "no-height": props.noHeight,
          }),
          cm(controlClassName, {
            disabled: props.disabled,
            danger: props.danger,
          })
        )}
        style={props.style}
      >
        {props.label && !props.noLabel ? (
          <span className={cp("control-" + name + "__label")}>
            {props.label}
          </span>
        ) : null}
        {props.items.map((item, index) => {
          return (
            <label key={item.id} className={clsx({ disabled: item.disabled })}>
              {item.name}
              <input
                ref={ref}
                type="radio"
                name={groupName}
                data-index={index}
                onChange={handleChange}
                checked={
                  props.value?.id === item?.id || props.value === item?.id
                }
                disabled={props.disabled || item.disabled}
              />
              <div className={cp("control-" + name + "__indicator")}></div>
            </label>
          );
        })}
      </span>
    );
  }
);

AdvRadioGroup.defaultProps = {
  align: "left",
  items: [],
};

export default AdvRadioGroup;
