import React, { useState } from "react";
import { usePopper } from "react-popper-2";
import { AdvCommonControlProps } from "../types";

export interface AdvInputBigTooltipProps {
  tooltip: AdvCommonControlProps["tooltip"];
  referenceElement: HTMLElement;
}

const AdvInputBigTooltip: React.FC<AdvInputBigTooltipProps> = (props) => {
  const tooltip = props.tooltip;
  const referenceElement = props.referenceElement;

  const tooltipPosition =
    typeof tooltip === "string" ? "auto" : tooltip?.position ?? "auto";
  const tooltipText = typeof tooltip == "string" ? tooltip : tooltip?.text;
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: tooltipPosition,
      strategy: "fixed",
      modifiers: [
        { name: "offset", options: { offset: [0, 15] } },
        { name: "arrow", options: { element: arrowElement } },
      ],
    }
  );

  return (
    <div
      ref={setPopperElement}
      style={{
        backgroundColor: "#68aad1",
        borderRadius: "6px",
        color: "white",
        padding: "20px",
        width: "min-content",
        height: "auto",
        zIndex: 999,
        ...popperStyles.popper,
      }}
      {...popperAttributes.popper}
    >
      <div id="popper__element">{tooltipText}</div>
      <div
        id="popper__arrow"
        ref={setArrowElement}
        style={popperStyles.arrow}
      />
    </div>
  );
};

export default AdvInputBigTooltip;
