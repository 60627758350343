import clsx from "clsx";
import React from "react";
import { SwipeableHandlers } from "react-swipeable";

export interface CalendarContentProps extends Partial<SwipeableHandlers> {}

const CalendarContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CalendarContentProps>
>((props, ref) => {
  return (
    <div ref={ref} className={clsx("rivile-calendar-content")} {...props}>
      {props.children}
    </div>
  );
});

export default CalendarContent;
