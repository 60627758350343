import { isPlainObject } from "@adv-libs/utils";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { usePopper } from "react-popper-2";

export interface AdvTooltipProps {
  tooltip:
    | {
        position?: "auto" | "top" | "right" | "bottom" | "left";
        text: string;
      }
    | string;
  small?: boolean;
  onClose?: () => any;
  onMouseLeave?: () => any;
}

export interface AdvTooltipInstance {
  setReferenceElement: (referenceElement: any) => any;
}

const AdvTooltip = React.forwardRef<any, AdvTooltipProps>((props, ref) => {
  if (!(isPlainObject(props.tooltip) || typeof props.tooltip === "string")) {
    throw new Error("Only object of {text, position} and string is supported");
  }

  const tooltipPosition =
    typeof props.tooltip === "string"
      ? "bottom"
      : props.tooltip.position ?? "bottom";
  const tooltipText =
    typeof props.tooltip == "string" ? props.tooltip : props.tooltip.text;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: tooltipPosition,
      strategy: "fixed",
      modifiers: [
        { name: "offset", options: { offset: [0, 8] } },
        { name: "arrow", options: { element: arrowElement } },
      ],
    }
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        setReferenceElement: setReferenceElement,
      };
    },
    [setReferenceElement]
  );

  const handleDocumentMouseDown = useCallback(
    (e) => {
      if (!popperElement.contains(e.target)) {
        props.onClose();
      }
    },
    [popperElement, props.onClose]
  );

  const arrowStyle = useMemo(() => {
    const style = { ...popperStyles.arrow };
    if (props.small) {
      style["display"] = "none";
    }
    return style;
  }, [popperStyles.arrow]);

  useEffect(() => {
    if (props.onClose) {
      document.addEventListener("mousedown", handleDocumentMouseDown);
      return () => {
        document.removeEventListener("mousedown", handleDocumentMouseDown);
      };
    }
  }, [handleDocumentMouseDown, props.onClose]);

  return (
    <div
      id="r365-tooltip"
      ref={setPopperElement}
      className={clsx(
        props.small ? "r365-tooltip--small" : null,
        "r365-tooltip--" + tooltipPosition
      )}
      style={popperStyles.popper}
      onMouseLeave={props.onMouseLeave}
      {...popperAttributes.popper}
    >
      <div dangerouslySetInnerHTML={{ __html: tooltipText }}></div>
      <div id="r365-tooltip__arrow" ref={setArrowElement} style={arrowStyle} />
    </div>
  );
});

export default AdvTooltip;
