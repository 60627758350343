import { KeyCode } from "@adv-libs/utils";
import React, { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { Card, CardBody, CardHeader, CardTitle } from "../Card";
import { Dialog } from "../Dialog";
import { cp } from "../utils";

export interface SimpleModalProps {
  maxWidth?: number;
  height?: number;
  title?: string;
  onClose?: () => any;
}

const SimpleModal: React.FC<SimpleModalProps> = (props) => {
  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === KeyCode.KEY_ESCAPE) {
      props.onClose();
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return ReactDOM.createPortal(
    <>
      <div className={cp("simple-modal-backdrop")}></div>
      <div className={cp("simple-modal")}>
        <Dialog maxWidth={props.maxWidth} height={props.height}>
          <Card fullHeight>
            <CardHeader>
              <CardTitle>{props.title}</CardTitle>
              {props.onClose ? (
                <span
                  onClick={props.onClose}
                  style={{ cursor: "pointer", fontSize: 24 }}
                >
                  &times;
                </span>
              ) : null}
            </CardHeader>
            <CardBody flex>{props.children}</CardBody>
          </Card>
        </Dialog>
      </div>
    </>,
    document.body
  );
};

export default SimpleModal;
