import React, { memo } from "react";
import { CalendarTimelineGroupsHeightSyncContextProvider } from "../../CalendarTimeline/CalendarTimelineGroupsHeightSyncContext";
import CalendarTimelineBodyContent from "./CalendarTimelineBodyContent";

export interface CalendarTimelineBodyProps {}

const CalendarTimelineBody: React.FC<CalendarTimelineBodyProps> = memo(
  (props) => {
    return (
      <CalendarTimelineGroupsHeightSyncContextProvider>
        <div className="rivile-calendar-body rivile-calendar-body--timeline">
          <CalendarTimelineBodyContent />
        </div>
      </CalendarTimelineGroupsHeightSyncContextProvider>
    );
  }
);

export default CalendarTimelineBody;
