import { Icon } from "@adv-libs/icons";
import { useTranslation } from "@adv-libs/l10n";
import clsx from "clsx";
import { dot } from "@adv-libs/utils";
import React, { useCallback, useReducer, useRef } from "react";
import { cm, cp } from "../utils";
import AdvImagePlaceholder from "./AdvImagePlaceholder";

export interface AdvImageProps {
  maxSize?: number;
  readOnly?: boolean;
  nameProperty?: string;
  onCommit?: (value: any) => any;
  label?: string;
  value?: string;
  width?: any;
  height?: any;
  disabled?: boolean;
  fieldName?: string;
  aspect?: number;
  border?: boolean;
}

export interface AdvImageUploadHandler {
  setProgress: (progress: number) => void;
  maxSize?: number;
}

export interface AdvImageStaticMethods {
  onUpload: (file: File, handler: AdvImageUploadHandler) => Promise<any>;
  onPreview: (value: any) => string;
}

type ReducerAction =
  | {
      type: "FILE_CLEAR";
    }
  | {
      type: "FILE_INIT";
    }
  | {
      type: "FILE_SUCCESS";
      payload: {
        fileName: string;
      };
    }
  | {
      type: "FILE_PROGRESS";
      payload: {
        progress: number;
      };
    };

type ReducerState = {
  fileName: string | null;
  isLoading: boolean;
  progress: number;
};

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case "FILE_CLEAR": {
      return {
        fileName: null,
        isLoading: false,
        progress: 0,
      };
    }
    case "FILE_INIT": {
      return {
        ...state,
        progress: 0,
        isLoading: true,
      };
    }
    case "FILE_SUCCESS": {
      return {
        fileName: action.payload.fileName,
        isLoading: false,
        progress: 0,
      };
    }
    case "FILE_PROGRESS": {
      return {
        ...state,
        progress: action.payload.progress,
      };
    }
    default:
      return state;
  }
};

const initialState = {
  fileName: null,
  fileError: null,
  isLoading: false,
  progress: 0,
};

const AdvImage: React.FC<AdvImageProps> & AdvImageStaticMethods = (props) => {
  const {
    maxSize,
    onCommit,
    readOnly,
    disabled,
    value,
    label,
    width,
    height,
    aspect,
    nameProperty,
  } = props;

  const { t, pt } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);
  const $inputRef = useRef(null);

  const handleInputChange = useCallback(
    async (event: React.FormEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files;
      const file = files[0];
      if (!file) return;
      dispatch({ type: "FILE_CLEAR" });
      dispatch({ type: "FILE_INIT" });

      const handler: AdvImageUploadHandler = {
        setProgress: (progress: number) => {
          dispatch({ type: "FILE_PROGRESS", payload: { progress } });
        },
        maxSize: maxSize,
      };

      try {
        const result = await AdvImage.onUpload(file, handler);
        if (result) {
          if (typeof onCommit === "function") {
            onCommit(result);
          }
          const fileName = nameProperty
            ? dot.get<string>(result, nameProperty)
            : file.name;

          dispatch({ type: "FILE_SUCCESS", payload: { fileName: fileName } });
        } else {
          // Reset state to initial
          dispatch({ type: "FILE_CLEAR" });
        }
      } catch (err) {
        console.error(err);
        throw new Error("Failed to upload image");
      }

      if ($inputRef.current) {
        $inputRef.current.remove();
        $inputRef.current = null;
      }
    },
    [onCommit, maxSize, nameProperty, pt, t]
  );

  const handleClear = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch({ type: "FILE_CLEAR" });
      onCommit(null);
    },
    [onCommit]
  );

  const handleClick = useCallback(() => {
    if (readOnly || state.isLoading || disabled) return;
    const $fileInput = document.createElement("input");
    $inputRef.current = $fileInput;
    $fileInput.setAttribute("type", "file");
    $fileInput.setAttribute(
      "accept",
      ".jpg, .jpeg, .png, .gif, .tiff, .jfif, .bmp, .tif, .pjpeg, .apng"
    );
    $fileInput.style["position"] = "fixed";
    $fileInput.style["left"] = "-9999999px";
    $fileInput.style["opacity"] = "0";
    $fileInput.addEventListener("change", handleInputChange as any);
    document.body.appendChild($fileInput);
    $fileInput.click();
  }, [handleInputChange, readOnly, state.isLoading]);

  const title = state.isLoading
    ? state.progress === 100
      ? t("Please wait...")
      : state.progress + " %"
    : label;

  const finalHeight = props.aspect ? undefined : props.height;

  return (
    <div
      data-field={props.fieldName}
      data-testid={"image|" + props.fieldName}
      className={clsx(
        cp("control"),
        cp("control-image"),
        cm(cp("control-image"), {
          readonly: props.readOnly,
          border: props.border,
        }),
        cm(cp("control"), {
          disabled: props.disabled,
        })
      )}
      onClick={handleClick}
      style={{ width, height: finalHeight }}
    >
      {props.aspect ? (
        <div
          className="image-aspect"
          style={{ paddingTop: (1 / aspect) * 100 + "%" }}
        ></div>
      ) : null}
      <div className="image-content">
        {!value || state.isLoading ? (
          <label>
            {state.isLoading ? (
              <Icon icon={["spin", 2200]} spin />
            ) : (
              <AdvImagePlaceholder title={title} disabled={props.disabled} />
            )}
          </label>
        ) : null}
        {value && !state.isLoading ? (
          <div
            className="image"
            style={{ backgroundImage: `url(${AdvImage.onPreview(value)})` }}
          ></div>
        ) : null}
        {!(props.readOnly || props.disabled) && value && !state.isLoading ? (
          <div className="close-overlay" onClick={handleClear}>
            <div className="close-button">&times;</div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

AdvImage.defaultProps = {
  width: 120,
  height: 120,
  nameProperty: "name",
};

AdvImage.onUpload = (file: File, handler: AdvImageUploadHandler) => {
  throw new Error(
    `[AdvImage] No upload handler set. Set upload handler by assign it to the AdvImage.onUpload method`
  );
};

AdvImage.onPreview = (value: any) => {
  throw new Error(
    `[AdvImage] No preview handler set. Set preview handler by assign it to the AdvImage.onPreview method`
  );
};

export default AdvImage;
