import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useIsMultiple = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.multiple, [])
  );
};

export default useIsMultiple;
