import {
  KeyCode,
  defined,
  useClickedOutside,
  useCombinedRef,
  useSecondEffect,
} from "@adv-libs/utils";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import Popover from "../Popover/Popover";
import { cp } from "../utils";

export interface DropdownProps {
  /**
   * Controls the popover is open or not.
   * If prop is defined, dropdown goes to controlled way
   * and open state should be controlled outside dropdown.
   * Use *onClose* and *onOpen* callbacks together.
   *
   * If prop is not defined, open and close logic is done inside.
   */
  isOpen?: boolean;
  align?: "start" | "end";
  position?: "top" | "bottom" | "right" | "left";
  offsetX?: number;
  offsetY?: number;
  onClose?: () => any;
  onOpen?: () => any;
  onOpened?: () => any;
  onClosed?: () => any;
  usePortal?: boolean;
  portalTarget?: HTMLElement;
  className?: string;
  sameWidth?: boolean;
  sameTop?: boolean;
  disabled?: boolean;
  onHover?: boolean;
}

export interface DropdownInstance {
  close: () => any;
}

const Dropdown = React.forwardRef<
  DropdownInstance,
  React.PropsWithChildren<DropdownProps>
>((props, ref) => {
  const [reference, setReference] = useState<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const childrenArray = React.Children.toArray(props.children);

  const triggerChildren = childrenArray[0] as React.ReactElement;
  const contentChildren = childrenArray[1] || null;

  useImperativeHandle(
    ref,
    () => {
      return {
        close: () => {
          setIsOpen(false);
        },
      };
    },
    []
  );

  const handleTriggerMouseDown = useCallback(
    (e) => {
      if (props.onHover) return;

      if (typeof props.onOpen === "function") {
        props.onOpen();
      }

      if (!defined(props.isOpen) && !props.disabled) {
        const preventOpen = e.target
          ? e.target.closest("." + cp("prevent-dropdown"))
          : false;

        setIsOpen((state) => {
          if (!state && preventOpen) return state;
          return !state;
        });
      }
    },
    [props.onOpen, props.isOpen, props.onHover, props.disabled]
  );

  const handleClickedOutside = useCallback(
    (e) => {
      if (props.onHover) return;

      if (
        dropdownRef.current.contains(e.target) ||
        dropdownRef.current === e.target
      ) {
        return;
      }

      if ((props.isOpen ?? isOpen) && typeof props.onClose === "function") {
        props.onClose();
      }
      if (!defined(props.isOpen)) {
        setIsOpen(false);
      }
    },
    [isOpen, props.onClose, props.isOpen, props.onHover]
  );

  const [clickOutsideReference] = useClickedOutside(handleClickedOutside);

  const checkIfCloseIsTriggered = useCallback(
    (clickedElement: HTMLElement) => {
      const closestCloseTrigger = clickedElement.closest(
        "." + cp("close-dropdown")
      );
      if (closestCloseTrigger) {
        if (typeof props.onClose === "function") {
          props.onClose();
        }
        if (!defined(props.isOpen)) {
          setIsOpen(false);
        }
      }
    },
    [props.onClose, props.isOpen]
  );

  const handleDropdownClick = useCallback(
    (e) => {
      checkIfCloseIsTriggered(e.target);
    },
    [checkIfCloseIsTriggered]
  );

  const extendedTriggerChildren = useMemo(() => {
    return React.cloneElement(triggerChildren, {
      ref: setReference,
    });
  }, [triggerChildren, handleTriggerMouseDown]);

  const handleMouseEnter = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === KeyCode.KEY_ESCAPE) {
      setIsOpen(false);
    }
  }, []);

  useSecondEffect(() => {
    if (isOpen) {
      if (typeof props.onOpened === "function") {
        props.onOpened();
      }
    } else {
      if (typeof props.onClosed === "function") {
        props.onClosed();
      }
    }
  }, [isOpen]);

  useSecondEffect(() => {
    if (defined(props.isOpen)) {
      if (props.isOpen) {
        if (typeof props.onOpened === "function") {
          props.onOpened();
        }
      } else {
        if (typeof props.onClosed === "function") {
          props.onClosed();
        }
      }
    }
  }, [props.isOpen]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (reference) {
      reference.addEventListener("mousedown", handleTriggerMouseDown);

      return () => {
        reference.removeEventListener("mousedown", handleTriggerMouseDown);
      };
    }
  }, [reference, handleTriggerMouseDown]);

  useEffect(() => {
    if (reference) {
      if (isOpen || props.isOpen) {
        reference.classList.add("r365-dropdown-trigger--open");
      } else {
        reference.classList.remove("r365-dropdown-trigger--open");
      }
    }
  }, [isOpen, props.isOpen]);

  return (
    <div
      ref={useCombinedRef(ref, dropdownRef)}
      className={clsx(cp("dropdown"), props.className)}
      onClick={!props.onHover ? handleDropdownClick : undefined}
      onMouseEnter={props.onHover ? handleMouseEnter : undefined}
      onMouseLeave={props.onHover ? handleMouseLeave : undefined}
    >
      {extendedTriggerChildren}
      {props.isOpen ?? isOpen ? (
        props.usePortal ? (
          ReactDOM.createPortal(
            <Popover
              ref={clickOutsideReference}
              sameWidth={props.sameWidth}
              align={props.align}
              position={props.position}
              reference={reference}
              offsetX={props.offsetX}
              offsetY={props.offsetY}
            >
              {contentChildren}
            </Popover>,
            props.portalTarget ?? document.body
          )
        ) : (
          <Popover
            ref={clickOutsideReference}
            sameWidth={props.sameWidth}
            sameTop={props.sameTop}
            align={props.align}
            position={props.position}
            reference={reference}
            offsetX={props.offsetX}
            offsetY={props.offsetY}
          >
            {contentChildren}
          </Popover>
        )
      ) : null}
    </div>
  );
});

export default Dropdown;
