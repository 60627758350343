import React from "react";
import { CalendarEventInWeekType } from "../../../types";
import CalendarMobileBodyWeekEventsGroup from "./CalendarMobileBodyWeekEventsGroup";

export interface CalendarMobileBodyWeekEventsGroupsProps {
  isExpanded: boolean;
  visibleWeekEvents: CalendarEventInWeekType[][];
  weekGroups: {
    weekStart: Date;
    weekEnd: Date;
    events: CalendarEventInWeekType[][];
  };
}

const CalendarMobileBodyWeekEventsGroups: React.FC<CalendarMobileBodyWeekEventsGroupsProps> =
  (props) => {
    const { weekGroups, visibleWeekEvents: firstEventsByWeek } = props;

    const weekStart = weekGroups.weekStart;
    const weekEnd = weekGroups.weekEnd;

    return (
      <div className="rivile-calendar-body-week-events-groups">
        {firstEventsByWeek.map((eventGroup, index) => {
          return (
            <CalendarMobileBodyWeekEventsGroup
              key={index}
              weekEvents={eventGroup}
              weekStart={weekStart}
              weekEnd={weekEnd}
            />
          );
        })}
      </div>
    );
  };

export default CalendarMobileBodyWeekEventsGroups;
