import React from "react";
import CalendarMobileBodyWeeks from "./CalendarMobileBodyWeeks";

export interface CalendarMobileBodyContentProps {}

const CalendarMobileBodyContent: React.FC<CalendarMobileBodyContentProps> = (
  props
) => {
  return (
    <div className="rivile-calendar-body-content">
      <CalendarMobileBodyWeeks />
    </div>
  );
};

export default CalendarMobileBodyContent;
