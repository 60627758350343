import { defined } from "@adv-libs/utils";
import clsx from "clsx";
import React, { CSSProperties, useMemo } from "react";
import { AdvFormFieldMessages } from "../AdvFormFieldMessages";
import cgrid, { CGridColSize, COrder, corder } from "../utils/cgrid";
import cm from "../utils/cm";
import cp from "../utils/cp";

export interface AdvFormFieldWrapperProps {
  fill?: boolean;
  messages?: ({ intent?: string; description: string } | string)[];
  messagesPosition?: "top" | "bottom" | string;
  description?: string;
  size?: CGridColSize;
  order?: COrder;
  width?: number;
  style?: CSSProperties;
  onClick?: (e?: any) => any;
  padding?: boolean;
}

const AdvFormFieldWrapper = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AdvFormFieldWrapperProps>
>(
  (
    {
      children,
      messages,
      fill,
      size,
      messagesPosition,
      width,
      style,
      description,
      onClick,
      padding,
      order,
    },
    ref
  ) => {
    const hasError = Array.isArray(messages) ? messages.length > 0 : false;

    const fieldData = useMemo(() => {
      const data = [];
      if (hasError) {
        data["data-error"] = true;
      }
      return data;
    }, [hasError]);

    return (
      <div
        ref={ref}
        onClick={onClick}
        className={clsx(
          cgrid(size),
          corder(order),
          cp("form-field-wrapper"),
          cm(cp("form-field-wrapper"), {
            shrinked: fill === false || defined(width),
            padding: padding,
          })
        )}
        style={{ ...style, width }}
        {...fieldData}
      >
        {messagesPosition === "top" ? (
          <AdvFormFieldMessages messages={messages} />
        ) : null}
        {children}
        {description ? (
          <div
            className={cp("form-field-wrapper__description")}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        ) : null}
        {messagesPosition === "bottom" ? (
          <AdvFormFieldMessages messages={messages} />
        ) : null}
      </div>
    );
  }
);

AdvFormFieldWrapper.defaultProps = {
  fill: true,
  messagesPosition: "bottom",
  padding: true,
};

export default AdvFormFieldWrapper;
