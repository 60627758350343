import React from "react";
import { cp } from "../utils";

export interface MenuListSplitProps {
  title?: string;
  border?: boolean;
}

const MenuListSplit: React.FC<MenuListSplitProps> = (props) => {
  return (
    <>
      {props.border ? <div className={cp("menu-list-split")}></div> : null}
      {props.title ? (
        <div className={cp("menu-list-split-title")}>{props.title}</div>
      ) : null}
    </>
  );
};

MenuListSplit.defaultProps = {
  border: true,
};

export default MenuListSplit;
