import {
  KeyCode,
  useCombinedRef,
  useIsMobile,
  useSecondEffect,
} from "@adv-libs/utils";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import AdvControlLabel from "../AdvControlLabel";
import StartIcon from "../AdvInput/StartIcon";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import useControl from "../useControl";
import { cm } from "../utils";
import AdvSelectArrowIndicator from "./AdvSelectArrowIndicator";
import AdvSelectClearIndicator from "./AdvSelectClearIndicator";
import AdvSelectLoadingIndicator from "./AdvSelectLoadingIndicator";
import AdvSelectMenu from "./AdvSelectMenu/AdvSelectMenu";
import AdvSelectPopover from "./AdvSelectPopover";
import AdvSelectValueContainer from "./AdvSelectValueContainer";
import cc from "./cc";
import { StateActions } from "./hooks/create/useCreateSelectState";
import useAddItem from "./hooks/useAddItem";
import useFilteredItems from "./hooks/useFilteredItems";
import useHasValue from "./hooks/useHasValue";
import useRefs from "./hooks/useRefs";
import useSelectDispatch from "./hooks/useSelectDispatch";
import useSelectState from "./hooks/useSelectState";
import { AdvSelect2Instance, AdvSelectProps2 } from "./types";

export type AdvSelect2CoreProps = AdvSelectProps2;

const name = "select2";

const AdvSelect2Core = React.forwardRef<
  AdvSelect2Instance,
  AdvSelect2CoreProps
>((props, ref) => {
  const refs = useRefs();

  const mouseIsLeavedDocumentRef = useRef<boolean>(false);
  const documentFocusTimeRef = useRef<number>(null);

  const [referenceElement, setReferenceElement] = useState(null);

  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const addItem = useAddItem();
  const dispatch = useSelectDispatch();
  const state = useSelectState();
  const isMobile = useIsMobile();

  const filteredItems = useFilteredItems();

  const hasValue = useHasValue();

  const { onFocus, onBlur, className, style } = useControl({
    label: props.label,
    name,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger || !!state.error,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    // assume empty array value as undefined
    value: hasValue || props.overlay,
    nullIsValue: false, //props.nullIsValue
    className: props.className,
    onFocus: props.onFocus,
    height: props.height,
    notify: props.notify,
  });

  const handleMouseEnter = useCallback(
    (e) => {
      if (state.menuIsOpen) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    },
    [state.menuIsOpen]
  );

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || state.focused ? showTooltip : false;
  }

  useEffect(() => {
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(refs.controlRef.current);
    }
  }, [showTooltip, props.tooltip, hasValue, state.focused, state.menuIsOpen]);

  /**
   * If fake multiple input focused and the root component is already focused,
   * prevent bubble event to root component. It prevents, to reopen menu after
   * fake multiple input is forced to focus, after menu close.
   */
  const handleFakeMultipleInputFocus = useCallback(
    (e) => {
      if (state.focused) {
        e.stopPropagation();
      }
    },
    [onFocus, state.focused]
  );

  /**
   * If fake multiple input is blurred and menu is open,
   * stop event bubling to the root component.
   *
   * After menu is open, the multiple query input focus occurs, and it triggers
   * fake multiple input blur. If it's bubbles event to the root component
   * it will close menu
   */
  const handleFakeMultipleInputBlur = useCallback(
    (e) => {
      if (state.menuIsOpen) {
        e.stopPropagation();
      }
    },
    [state.menuIsOpen]
  );

  /**
   * The root component focus is inherited from single or multiple query focus.
   * It opens menu and marks focus programatically.
   *
   * If mouse is out of the document, do nothing.
   *
   * If focus occurs immediately (in 10ms) after visiblity change, skip focus event.
   */
  const handleRootFocus = useCallback(
    (e?) => {
      if (mouseIsLeavedDocumentRef.current) {
        return;
      }

      if (documentFocusTimeRef.current !== null) {
        const currentTime = Date.now();
        const delta = currentTime - documentFocusTimeRef.current;

        if (delta < 10) {
          return;
        }
      }

      if (!state.menuIsOpen) {
        dispatch(StateActions.openMenuWithFocus());
      } else {
        dispatch(StateActions.setFocused(true));
      }
      // dispatch(StateActions.openMenuWithFocus());
      onFocus(e);
    },
    [onFocus, state.menuIsOpen, state.focused]
  );

  /**
   * Blur event closes menu (even it's already closed)
   * and marks blur programatically.
   *
   * If mouse is out of the document, just close menu, but prevent blur
   */
  const handleRootBlur = useCallback(() => {
    if (mouseIsLeavedDocumentRef.current) {
      dispatch(StateActions.closeMenu());
      return;
    }

    if (state.menuIsOpen) {
      dispatch(StateActions.closeMenuWithBlur());
    } else {
      dispatch(StateActions.setFocused(false));
    }
    onBlur();
    // dispatch(StateActions.setFocused(false));
  }, [onBlur, state.menuIsOpen, state.focused]);

  const handleRootMouseDown = useCallback(
    (e) => {
      if (props.disabled || props.readOnly) return false;

      if (state.focused && !state.menuIsOpen) {
        /**
         * If select is already focused and menu is closed,
         * open menu, and prevent default to loose focus
         */
        e.preventDefault();
        dispatch(StateActions.openMenu());

        return;
      }

      if (props.multiple) {
        /**
         * Multiple select doesn't have clickable input, so this event is called
         * wherever user clicks on root component.
         *
         * On this event, open menu, and prevent default loose focus.
         *
         * After menu is open, hook below forces the multiple query input to focus,
         * so prevent default also helps to not loose the focus from that input
         */

        e.preventDefault();
        dispatch(StateActions.openMenu());
      } else {
        /**
         * This event is not called when query input is on mouse down event.
         * It means that root component was clicked not directly on query input,
         * so in this case, force query input focus.
         *
         * Prevent default, because we don't want someone to take over the focus
         */
        e.preventDefault();
        refs.singleQueryInputRef.current.focus();
      }
    },
    [
      props.disabled,
      props.multiple,
      props.readOnly,
      state.menuIsOpen,
      state.focused,
    ]
  );

  /**
   * If menu is opened and select is multiple,
   * force multiple query input focus
   *
   * If menu is opened and select is not multiple
   * force refocus query input
   *
   * If menu is closed, and select is multiple and already focused,
   * force fake multiple input to focus
   */
  useEffect(() => {
    if (props.multiple && state.menuIsOpen && !isMobile) {
      refs.multipleQueryInputRef.current?.focus?.();
    } else if (props.multiple && !state.menuIsOpen && state.focused) {
      refs.fakeMultipleSelectInputRef.current.focus();
    } else if (!props.multiple && state.menuIsOpen) {
      refs.singleQueryInputRef.current.focus();
    }
  }, [state.menuIsOpen]);

  useSecondEffect(() => {
    if (state.menuIsOpen) {
      if (typeof props.onMenuOpened === "function") {
        props.onMenuOpened();
      }
    } else {
      if (typeof props.onMenuClosed === "function") {
        props.onMenuClosed();
      }
    }
  }, [state.menuIsOpen]);

  useImperativeHandle(
    ref,
    () => {
      return {
        focus: handleRootFocus,
        blur: handleRootBlur,
        closeMenu: () => {
          dispatch(StateActions.closeMenu());
        },
      };
    },
    [handleRootFocus, handleRootBlur]
  );

  // @EVENT
  const handleDocumentMouseDown = useCallback(
    (e) => {
      // if (!keepFocusedRef.current) {
      //   if (state.menuIsOpen) {
      //     /**
      //      * GLEAP support
      //      * Do not close menu if mouse down event is related to the gleap functionallity
      //      */
      //     if (isGleapRecording(e)) {
      //       return;
      //     }
      //     onBlur();
      //     dispatch(StateActions.closeMenu());
      //   }
      // }
      // keepFocusedRef.current = false;
    },
    [state.menuIsOpen, onBlur]
  );

  const handleDocumentMouseEnter = useCallback(() => {
    mouseIsLeavedDocumentRef.current = false;
  }, []);

  const handleDocumentMouseLeave = useCallback(() => {
    mouseIsLeavedDocumentRef.current = true;
  }, []);

  const handleVisibilityChange = useCallback(() => {
    documentFocusTimeRef.current = Date.now();
  }, []);

  /**
   * Clear select value, and simulate root component blur event,
   * which closes menu if it's open
   */
  const handleClearButtonMouseDown = useCallback(
    (e) => {
      e.stopPropagation();
      props.onCommit(props.clearValue ?? undefined);
      handleRootBlur();

      if (typeof props.onValueClear === "function") {
        setTimeout(() => {
          props.onValueClear();
        }, 100);
      }
    },
    [props.onCommit, props.clearValue, props.onValueClear, handleRootBlur]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KeyCode.KEY_DOWN) {
        e.preventDefault();
        if (state.menuIsOpen) {
          /**
           * If menu is open, prevent event bubbling to parent elements
           */
          e.stopPropagation();

          if (state.menuItemCursor === null) {
            /** If cursor is not set, set cursor to begin */
            dispatch(StateActions.moveCursorToBegin());
          } else if (state.menuItemCursor + 1 < filteredItems.length) {
            /** If next cursor position is under items count, move cursor down */
            dispatch(StateActions.moveCursorDown());
          }
        } else {
          e.stopPropagation();
          /** If menu is closed, open it ant move cursor to begin */
          dispatch(StateActions.moveCursorToBegin(true));
        }
      } else if (e.keyCode === KeyCode.KEY_UP) {
        e.preventDefault();
        if (state.menuIsOpen) {
          /**
           * If menu is open, prevent event bubbling to parent elements
           */
          e.stopPropagation();
        }

        if (state.menuItemCursor === null) {
          /** If cursor is not set, set cursor to begin */
          dispatch(StateActions.moveCursorToBegin());
        } else if (state.menuItemCursor > 0) {
          /** If cursor is below start position, move cursor up */
          dispatch(StateActions.moveCursorUp());
        }
      } else if (e.keyCode === KeyCode.KEY_ESCAPE) {
        if (state.menuIsOpen) {
          /** If menu is open */
          e.preventDefault();

          if (!props.forwardESCEvent) {
            /**
             * Prevent event bubbling to parent elements if 'forwardESCEvent' is falsy
             */
            e.stopPropagation();
          }

          /** Close menu */
          dispatch(StateActions.closeMenu());
        } else {
          /** If menu is closed, force blur */
          if (props.multiple) {
            refs.fakeMultipleSelectInputRef.current.blur();
          } else {
            refs.singleQueryInputRef.current.blur();
          }
        }
      } else if (e.keyCode === KeyCode.KEY_RETURN && isMobile) {
        e.preventDefault();
      } else if (e.keyCode === KeyCode.KEY_RETURN && !isMobile) {
        e.preventDefault();

        if (state.menuIsOpen && state.menuItemCursor === null) {
          /**
           * If menu is open and cursor is not touched,
           * prevent event bubbling to parent elements.
           */
          e.stopPropagation();

          /** If there are items, select the first of them  */
          if (filteredItems.length > 0) {
            addItem(filteredItems[0]);

            if ((props.multiple && props.singleSelect) || !props.multiple) {
              dispatch(StateActions.closeMenu());
            }

            // if (isMobile) {
            //   /** Blur single input if on mobile (to hide keyboard) */
            //   refs.singleQueryInputRef.current.blur();
            // }
          }
        } else if (state.menuIsOpen && state.menuItemCursor !== null) {
          /**
           * If menu is open and cursor is touched,
           * prevent event bubbling to parent elements.
           */
          e.stopPropagation();

          /** If there are items, select the item by cursor index  */
          if (filteredItems.length > 0) {
            const item = filteredItems[state.menuItemCursor];
            addItem(item);

            if ((props.multiple && props.singleSelect) || !props.multiple) {
              dispatch(StateActions.closeMenu());
            }

            if (isMobile) {
              /** Blur single input if on mobile (to hide keyboard) */
              refs.singleQueryInputRef.current.blur();
            }
          }
        }
      }
    },
    [
      addItem,
      props.forwardESCEvent,
      state.query,
      state.menuItemCursor,
      state.menuIsOpen,
      props.multiple,
      filteredItems,
      isMobile,
      dispatch,
      handleRootBlur,
    ]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentMouseDown);
    document.addEventListener("mouseenter", handleDocumentMouseEnter);
    document.addEventListener("mouseleave", handleDocumentMouseLeave);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("mousedown", handleDocumentMouseDown);
      document.removeEventListener("mouseenter", handleDocumentMouseEnter);
      document.removeEventListener("mouseleave", handleDocumentMouseLeave);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    handleDocumentMouseDown,
    handleDocumentMouseLeave,
    handleDocumentMouseEnter,
    handleVisibilityChange,
  ]);

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (state.menuIsOpen && state.focused) {
        dispatch(StateActions.closeMenu());
      }

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value, state.menuIsOpen, state.focused]
  );

  /**
   * Add onCommit callback to DOM element
   */
  useLayoutEffect(() => {
    (refs.controlRef.current as any).commit = (value) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  /**
   * If component comes disabled or readonly, blur it
   */
  useSecondEffect(() => {
    if (props.disabled || props.readOnly) {
      handleRootBlur();
    }
  }, [props.disabled, props.readOnly]);

  return (
    <>
      {props.tooltip && finalShowTooltip && !state.menuIsOpen ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        ref={useCombinedRef(refs.controlRef, setReferenceElement)}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        className={clsx(
          className,
          cm(cc(), {
            multiple: props.multiple,
            "menu-is-open": state.menuIsOpen,
          })
        )}
        onMouseDown={handleRootMouseDown}
        onKeyDown={handleKeyDown}
        onFocus={handleRootFocus}
        onBlur={handleRootBlur}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        style={style}
      >
        {props.startIcon ? (
          <StartIcon
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIcon={props.startIcon}
            startIconActive={props.startIconActive}
            startIconCount={props.startIconCount}
            startIconSize={props.startIconSize}
            startIconSpin={props.startIconSpin}
            value={props.value}
          />
        ) : null}
        {/* Fake input for multiple select focus */}
        {props.multiple ? (
          <input
            type="text"
            ref={refs.fakeMultipleSelectInputRef}
            className={cc("multiple-focus-input")}
            onFocus={handleFakeMultipleInputFocus}
            onBlur={handleFakeMultipleInputBlur}
            tabIndex={state.menuIsOpen || state.focused ? -1 : props.tabIndex}
            style={{
              position: "absolute",
              opacity: 0,
              width: 1,
              height: 1,
            }}
          />
        ) : null}
        <label>
          <AdvSelectValueContainer
            disabled={props.disabled}
            overlay={props.overlay}
            readOnly={props.readOnly}
          />
          {!props.overlay ? (
            <div className={cc(`indicator-container`)}>
              {props.disabled || props.readOnly ? null : state.isLoading ? (
                <AdvSelectLoadingIndicator />
              ) : props.isClearable && hasValue ? (
                <AdvSelectClearIndicator
                  onMouseDown={handleClearButtonMouseDown}
                />
              ) : null}
              {props.disabled || props.readOnly ? null : (
                <AdvSelectArrowIndicator isOpen={state.menuIsOpen} />
              )}
            </div>
          ) : null}
          <AdvControlLabel ref={refs.labelRef} label={props.label} />
          {state.menuIsOpen ? (
            <AdvSelectPopover reference={referenceElement}>
              <AdvSelectMenu />
            </AdvSelectPopover>
          ) : null}
        </label>
      </span>
    </>
  );
});

export default AdvSelect2Core;
