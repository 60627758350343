const groupEventsByPropertyIfNotOverlaping = <T>(
  items: T[],
  groupsIds: any[],
  groupByProperty: (item: T) => any,
  startDate: (item: T) => Date,
  endDate: (item: T) => Date
): Record<string, T[][]> => {
  const groupedItemsObject = items.reduce((acc, item) => {
    const groupProperty = groupByProperty(item);
    acc[groupProperty] = acc[groupProperty] || [];

    let placed = false;
    for (const group of acc[groupProperty]) {
      let overlap = false;
      for (const existingItem of group) {
        const currentItemStart = startDate(item);
        const currentItemEnd = endDate(item);
        const existingItemStart = startDate(existingItem);
        const existingItemEnd = endDate(existingItem);
        if (
          !(
            currentItemEnd < existingItemStart ||
            currentItemStart > existingItemEnd
          )
        ) {
          overlap = true;
          break;
        }
      }
      if (!overlap) {
        group.push(item);
        placed = true;
        break;
      }
    }

    if (!placed) {
      acc[groupProperty].push([item]); // Add a new group for the overlapping event
    }

    return acc;
  }, {} as Record<string, T[][]>);

  for (const groupId of groupsIds) {
    if (!groupedItemsObject[groupId]) {
      groupedItemsObject[groupId] = [];
    }
  }

  return groupedItemsObject;
};

export default groupEventsByPropertyIfNotOverlaping;
