const datePickerRegister = {};

export const registerDatePicker = (id: string, datePicker: any) => {
  datePickerRegister[id] = datePicker;
};

export const getFirstDatePicker: any = () => {
  return Object.values(datePickerRegister)[0];
};

export const unregisterDatePicker = (id: string) => {
  delete datePickerRegister[id];
};

export const closeDatePickers = (exceptId: string) => {
  for (const id in datePickerRegister) {
    if (id !== exceptId) {
      const datePicker = datePickerRegister[id];
      if (datePicker) {
        datePicker.close();
      }
    }
  }
};
