import { useTranslation } from "@adv-libs/l10n";
import { copyToClipboard } from "@adv-libs/utils";
import Editor, { EditorProps } from "@monaco-editor/react";
import React, { useCallback, useMemo, useState } from "react";
import { AdvFormFieldWrapper } from "../AdvFormFieldWrapper";
import { Button } from "../Button";
import { SimpleModal } from "../SimpleModal";
import { toast } from "../Toast";

export interface AdvJSONEditorButtonProps {
  label?: string;
  modalTitle?: string;
  value?: string;
  onCommit?: (json: string) => any;
  onCloseConfirm?: () => Promise<boolean>;
  disabled?: boolean;
  copy?: boolean;
  fill?: boolean;
  dialogWidth?: any;
  dialogHeight?: any;
}

const AdvJSONEditorButton: React.FC<AdvJSONEditorButtonProps> = (props) => {
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const [changed, setChanged] = useState(false);

  const handleClick = useCallback(() => {
    setEditorIsOpen(true);
  }, []);

  const handleClose = useCallback(async () => {
    if (changed && typeof props.onCloseConfirm === "function") {
      const confirmed = await props.onCloseConfirm();
      if (!confirmed) return;
    }
    setEditorIsOpen(false);
  }, [changed, props.onCloseConfirm]);

  const handleSave = useCallback(
    (json) => {
      props.onCommit(json);
      setEditorIsOpen(false);
    },
    [props.onCommit]
  );

  const handleChange = useCallback(() => {
    setChanged(true);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Button
        icon={props.disabled ? ["eye", 2202] : ["edit", 1835]}
        onClick={handleClick}
        fill={props.fill}
      >
        {props.label ?? t("Edit JSON")}
      </Button>
      {editorIsOpen ? (
        <AdvJSONEditorDialog
          modalTitle={props.modalTitle}
          value={props.value}
          disabled={props.disabled}
          copy={props.copy}
          onSave={handleSave}
          onClose={handleClose}
          onChange={handleChange}
          dialogWidth={props.dialogWidth}
          dialogHeight={props.dialogHeight}
        />
      ) : null}
    </>
  );
};

interface AdvJSONEditorDialogProps {
  modalTitle?: string;
  value?: string;
  onSave?: (json: string) => any;
  copy?: boolean;
  onClose?: () => any;
  onChange?: () => any;
  disabled?: boolean;
  dialogWidth?: any;
  dialogHeight?: any;
}

const AdvJSONEditorDialog: React.FC<AdvJSONEditorDialogProps> = (props) => {
  const formattedValue = useMemo(() => {
    try {
      return JSON.stringify(JSON.parse(props.value), null, 2);
    } catch (err) {
      console.error(err);
      return props.value;
    }
  }, []);

  const [jsonString, setJsonString] = useState(formattedValue ?? "{}");

  const { t } = useTranslation();

  let validJSON: any = false;
  try {
    validJSON = JSON.parse(jsonString);
  } catch (err) {
    validJSON = false;
  }

  const handleChanged = useCallback(
    (jsonString: string) => {
      setJsonString(jsonString);
      props.onChange();
    },
    [props.onChange]
  );

  const editorOptions = useMemo<EditorProps["options"]>(() => {
    return {
      readOnly: props.disabled,
      scrollBeyondLastLine: false,
      minimap: {
        enabled: false,
      },
    };
  }, [props.disabled]);

  const handleSave = useCallback(() => {
    if (typeof props.onSave === "function" && validJSON) {
      props.onSave(jsonString);
    }
  }, [props, validJSON, jsonString]);

  const handleCopy = useCallback(() => {
    let stringifiedJSON = "";
    if (validJSON) {
      stringifiedJSON = JSON.stringify(validJSON, null, 4);
    } else {
      stringifiedJSON = jsonString;
    }
    copyToClipboard(stringifiedJSON);
    toast(t("Copied"));
  }, [validJSON, jsonString]);

  return (
    <SimpleModal
      title={props.modalTitle ?? t("JSON Editor")}
      onClose={props.onClose}
    >
      <div>
        {!props.disabled ? (
          <div
            style={{
              padding: "5px 0",
              marginBottom: 5,
              fontSize: "12px",
              color: "gray",
            }}
          >
            {t("CTRL+SHIFT+I to format")}
          </div>
        ) : null}
        <Editor
          options={editorOptions}
          width={props.dialogWidth}
          height={props.dialogHeight}
          language="json"
          value={jsonString}
          onChange={handleChanged}
        />
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {props.copy ? (
              <AdvFormFieldWrapper size={0}>
                <Button newStyle onClick={handleCopy}>
                  {t("Copy")}
                </Button>
              </AdvFormFieldWrapper>
            ) : null}
          </div>
          <div style={{ display: "flex" }}>
            <AdvFormFieldWrapper size={0}>
              <Button minimal newStyle onClick={props.onClose}>
                {t("Cancel")}
              </Button>
            </AdvFormFieldWrapper>
            <AdvFormFieldWrapper size={0}>
              <Button
                primary
                newStyle
                disabled={!validJSON}
                onClick={props.disabled ? props.onClose : handleSave}
              >
                {t("Close")}
              </Button>
            </AdvFormFieldWrapper>
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

AdvJSONEditorDialog.defaultProps = {
  dialogHeight: 600,
};

export default AdvJSONEditorButton;
