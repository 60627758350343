import { AdvCommonControlProps } from "../../../types";
import { AdvSelectProps2 } from "../../types";

export interface UseCreateHasValueOptions {
  value: AdvCommonControlProps["value"];
  multiple: AdvSelectProps2["multiple"];
  query: string;
}

/**
 * Checks if value is presented.
 * Undefined - no value, because 'false' also can be assumed as value
 * True - has value
 */
const useCreateHasValue = (options: UseCreateHasValueOptions) => {
  if (options.multiple && !Array.isArray(options.value)) {
    // If select is multiple, and the value is not an array, assume as there is no value
    return undefined;
  }

  if (
    options.multiple &&
    Array.isArray(options.value) &&
    options.value.length === 0
  ) {
    // If select is multiple, and the value array is empty, assume as there is no value
    return undefined;
  }

  if (
    options.multiple &&
    Array.isArray(options.value) &&
    options.value.length > 0
  ) {
    // If select is multiple, and the value array has items, assume as there is a value
    return options.value;
  }

  if (!options.multiple && options.query) {
    // If select is not multiple, and query is ented, assume that query is a value
    return options.query;
  }

  // In other cases check value by default
  return options.value;
};

export default useCreateHasValue;
