import clsx from "clsx";
import React from "react";
import { cp } from "../utils";

const getMessageDescription = (
  message: string | { intent?: string; description: string }
) => {
  if (typeof message === "string") return message;
  else return message.description;
};

const getMessageIntent = (
  message: string | { intent?: string; description: string }
) => {
  if (typeof message === "string") return "danger";
  else return message.intent || "danger";
};

export interface AdvFormFieldMessagesProps {
  messages?: ({ intent?: string; description: string } | string)[];
}

const AdvFormFieldMessages: React.FC<AdvFormFieldMessagesProps> = (props) => {
  const { messages } = props;

  return (
    <div className={cp("form-field-messages")}>
      {Array.isArray(messages) && messages.length === 1 ? (
        <div
          className={clsx(
            cp("form-field-messages__message"),
            cp("form-field-messages__message--" + getMessageIntent(messages[0]))
          )}
        >
          {getMessageDescription(messages[0])}
        </div>
      ) : null}
      {Array.isArray(messages) && messages.length > 1 ? (
        <ul className={clsx(cp("form-field-messages__list"))}>
          {messages.map((message, key) => {
            return (
              <li
                key={key}
                className={clsx(
                  cp("form-field-messages__list__item"),
                  cp(
                    "form-field-messages__list__item--" +
                      getMessageIntent(message)
                  )
                )}
              >
                {getMessageDescription(message)}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default AdvFormFieldMessages;
