import React, { memo } from "react";
import { CalendarGroupedEventsType } from "../../types";
import CalendarTimelineBodyEventsScrollable from "./CalendarTimelineBodyEventsScrollable";
import CalendarTimelineBodyGridScrollable from "./CalendarTimelineBodyGridScrollable";

export interface CalendarTimelineBodyGridProps {
  groupedEventsObject: CalendarGroupedEventsType;
}

const CalendarTimelineBodyGrid: React.FC<CalendarTimelineBodyGridProps> = memo(
  (props) => {
    return (
      <div className="rivile-calendar-body-grid">
        <CalendarTimelineBodyGridScrollable />
        <CalendarTimelineBodyEventsScrollable
          groupedEventsObject={props.groupedEventsObject}
        />
      </div>
    );
  }
);

export default CalendarTimelineBodyGrid;
