import { useTranslation } from "@adv-libs/l10n";
import {
  KeyCode,
  dateToString,
  makeid,
  stringToDate,
  useCombinedRef,
  useIsMobile,
  useSecondEffect,
} from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  default as DatePicker,
  default as ReactDatePicker,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { findDOMNode } from "react-dom";
import { Portal } from "react-overlays";
import AdvControlLabel from "../AdvControlLabel";
import StartIcon from "../AdvInput/StartIcon";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { Button } from "../Button";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import { registerDatePicker, unregisterDatePicker } from "./datePickerRegister";
import dateRangeParser from "./dateRangeParser";
import { __getGlobalLocale, getDateTimeDisplayFormat } from "./globalState";
import useAutoWidthMeasure from "./useAutoWidthMeasure";

export interface AdvDatePickerRangeProps extends AdvCommonControlProps {
  format?: string;
  displayFormat?: string;
  isClearable?: boolean;
  fieldName?: string;
  defaultStartTime?: string;
  defaultEndTime?: string;
  showIcon?: boolean;
  height?: number;
  minWidth?: number;
  autoWidth?: boolean;
}

const name = "date";

const AdvDateRangePicker = React.forwardRef<
  ReactDatePicker,
  React.PropsWithChildren<AdvDatePickerRangeProps>
>((props, ref) => {
  const datePickerRef = useRef<any>();
  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const blurByTabRef = useRef(false);
  const clickOutsideRef = useRef(false);
  const refocusRef = useRef(false);
  const manuallyEnterRef = useRef(false);
  const clearButtonRef = useRef(false);
  const startIconClickRef = useRef(false);

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const { pt } = useTranslation();

  const isMobile = useIsMobile();

  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const format = props.format ?? "iso-short";

  const displayFormat = useMemo(() => {
    return getDateTimeDisplayFormat(false, props.displayFormat);
  }, [props.displayFormat]);

  const initialStartDateValue = useMemo<Date>(() => {
    return stringToDate(props.value?.[0], format);
  }, []);

  const initialEndDateValue = useMemo<Date>(() => {
    return stringToDate(props.value?.[1], format);
  }, []);

  const [startDateValue, setStartDateValue] = useState(initialStartDateValue);
  const [endDateValue, setEndDateValue] = useState(initialEndDateValue);

  const startDateValueRef = useRef<string>(
    (props.value && props.value[0]) || null
  );
  const endDateValueRef = useRef<string>(
    (props.value && props.value[1]) || null
  );

  const id = useMemo(() => {
    return makeid(5);
  }, []);

  const datesValue = useMemo(() => {
    const startDate = calendarIsOpen
      ? startDateValue
      : stringToDate(props.value?.[0], format, false);

    const endDate = calendarIsOpen
      ? endDateValue
      : stringToDate(props.value?.[1], format, false);

    return [startDate, endDate];
  }, [props.value, calendarIsOpen, startDateValue, endDateValue]);

  const formattedValue = useMemo(() => {
    const startDate = datesValue[0];
    const endDate = datesValue[1];

    const formattedStartDate = startDate
      ? dateToString(startDate, displayFormat)
      : null;
    const formattedEndDate = endDate
      ? dateToString(endDate, displayFormat)
      : null;

    return `${formattedStartDate ? formattedStartDate + " - " : ""}${
      formattedEndDate || ""
    }`.trim();
  }, [props.value, calendarIsOpen, datesValue, displayFormat]);

  const [inputValue, setInputValue] = useState(formattedValue);

  const deferredCalendarClose = useCallback(() => {
    setTimeout(() => {
      setCalendarIsOpen(false);
    }, 0);
  }, []);

  const handleCommit = useCallback(() => {
    props.onCommit([startDateValueRef.current, endDateValueRef.current]);
  }, [props.onCommit, calendarIsOpen, format]);

  const getInputComponent = useCallback(() => {
    const $datePicker = findDOMNode(datePickerRef.current) as HTMLDivElement;
    const $input = $datePicker.querySelector("input");
    return $input;
  }, []);

  const inputIsFocused = useCallback(() => {
    const $input = getInputComponent();
    return document.activeElement === $input;
  }, [getInputComponent]);

  const handleChange = useCallback(
    (dates, e?, manually?: boolean) => {
      if (manuallyEnterRef.current) {
        manuallyEnterRef.current = false;
        return;
      }

      if (manually) {
        manuallyEnterRef.current = true;
      }

      const [startDate, endDate] = dates;

      const formattedStartDate = startDate
        ? dateToString(startDate, format)
        : null;
      const formattedEndDate = endDate ? dateToString(endDate, format) : null;

      setStartDateValue(startDate);
      setEndDateValue(endDate);
      startDateValueRef.current = formattedStartDate;
      endDateValueRef.current = formattedEndDate;

      if (
        formattedStartDate &&
        formattedEndDate &&
        calendarIsOpen &&
        !inputIsFocused() &&
        !isMobile
      ) {
        handleCommit();
        deferredCalendarClose();
      }
    },
    [
      props.onCommit,
      handleCommit,
      inputIsFocused,
      calendarIsOpen,
      deferredCalendarClose,
      isMobile,
    ]
  );

  const handleMobileCommit = useCallback(() => {
    if (startDateValueRef.current && endDateValueRef.current) {
      handleCommit();
      deferredCalendarClose();
    } else if (startDateValueRef.current && !endDateValueRef.current) {
      endDateValueRef.current = startDateValueRef.current;
      handleCommit();
      deferredCalendarClose();
    } else if (!startDateValueRef.current && !endDateValueRef.current) {
      deferredCalendarClose();
    }
  }, [handleCommit, deferredCalendarClose]);

  const handleCancelCalendar = useCallback(() => {
    setStartDateValue(null);
    setEndDateValue(null);
    startDateValueRef.current = null;
    endDateValueRef.current = null;
    deferredCalendarClose();
  }, []);

  const refocus = useCallback(() => {
    refocusRef.current = true;
    const $input = getInputComponent();
    $input.focus();
    onFocus();
  }, [getInputComponent]);

  const handleCalendarClose = useCallback(() => {
    if (
      !clickOutsideRef.current &&
      !blurByTabRef.current &&
      !clearButtonRef.current &&
      !isMobile
    ) {
      refocus();
    }
    clearButtonRef.current = false;
    clickOutsideRef.current = false;
    blurByTabRef.current = false;
  }, [datesValue, isMobile, props.onCommit, refocus]);

  const hasValue = inputValue;

  const { onBlur, onFocus, style, className } = useControl({
    name,
    value: inputValue,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    disabled: props.disabled,
    readOnly: props.readOnly,
    className: props.className,
    notify: props.notify,
    autocomplete: props.autocomplete,
    height: props.height,
  });

  const handleMouseEnter = useCallback((e) => {
    if (e.target.closest(".react-datepicker")) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, props.tooltip, hasValue, focused, calendarIsOpen]);

  const handleCalendarClickOutside = useCallback(
    (e) => {
      if (isMobile) return;
      // Skip if clicked on input
      const $parent = findDOMNode(datePickerRef.current);
      if (e && $parent.contains(e.target)) {
        return;
      }

      clickOutsideRef.current = true;
      manuallyEnterRef.current = false;
      clearButtonRef.current = false;

      deferredCalendarClose();
    },
    [
      isMobile,
      startDateValue,
      endDateValue,
      handleCommit,
      handleChange,
      inputIsFocused,
      deferredCalendarClose,
    ]
  );

  const handleChangeRaw = useCallback(
    (e) => {
      e.preventDefault();

      if (e.keyCode === KeyCode.KEY_RETURN) {
        return;
      }

      setCalendarIsOpen(true);

      setInputValue(e.target.value);

      if (typeof e.target.value === "string") {
        try {
          const [startDate, endDate] = dateRangeParser(e.target.value);

          datePickerRef.current.setState({
            inputValue: e.target.value,
            lastPreSelectChange: "input",
          });

          manuallyEnterRef.current = false;

          if (endDate && startDate) {
            if (endDate < startDate) {
              handleChange([endDate, startDate], e);
            } else {
              handleChange([startDate, endDate], e);
            }
          } else {
            handleChange([null, null], e);
          }
        } catch (err) {
          /* istanbul ignore next */
          console.error(err);
        }
      }
    },
    [handleChange]
  );

  const handleFakeInputKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KeyCode.KEY_RETURN) {
        e.preventDefault();

        if (inputIsFocused()) {
          if (calendarIsOpen) {
            if (startDateValue && endDateValue) {
              handleChange([startDateValue, endDateValue], null, true);
            } else {
              handleChange([null, null], null, true);
            }

            handleCommit();

            deferredCalendarClose();
          } else {
            manuallyEnterRef.current = true;
            setTimeout(() => {
              refocus();
            }, 0);
          }
        }
      } else if (e.keyCode === KeyCode.KEY_TAB) {
        blurByTabRef.current = true;

        if (inputIsFocused()) {
          if (calendarIsOpen) {
            if (startDateValue && endDateValue) {
              handleChange([startDateValue, endDateValue], null, true);
            } else {
              handleChange([null, null], null, true);
            }

            handleCommit();
          }
        }

        deferredCalendarClose();
      } else if (e.keyCode === KeyCode.KEY_ESCAPE) {
        e.preventDefault();
        e.stopPropagation();
        deferredCalendarClose();
      } else if (e.keyCode === KeyCode.KEY_DOWN) {
        if (!calendarIsOpen) {
          e.preventDefault();
          e.stopPropagation();
          setCalendarIsOpen(true);
        }
      }
    },
    [
      deferredCalendarClose,
      handleCommit,
      handleChange,
      refocus,
      inputIsFocused,
      calendarIsOpen,
      startDateValue,
      endDateValue,
      formattedValue,
    ]
  );

  const handleCalendarInputClick = useCallback(() => {
    if (props.disabled || props.readOnly) return;
    if (!startIconClickRef.current) {
      setCalendarIsOpen(true);
    }
    startIconClickRef.current = false;
  }, [props.disabled, props.readOnly]);

  const handleFocus = useCallback(
    (e) => {
      if (
        !refocusRef.current &&
        !startIconClickRef.current &&
        !calendarIsOpen
      ) {
        setCalendarIsOpen(true);
      }
      startIconClickRef.current = false;
      refocusRef.current = false;
      clearButtonRef.current = false;
      blurByTabRef.current = false;
      return onFocus(e);
    },
    [onFocus, calendarIsOpen, isMobile]
  );

  const handleBlur = useCallback(
    (e) => {
      refocusRef.current = false;
      return onBlur(e);
    },
    [onBlur]
  );

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();
      startIconClickRef.current = true;

      // closeMenu();
      //
      // handleFakeBlur();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value, isMobile]
  );

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);
      clearButtonRef.current = true;
      setStartDateValue(null);
      setEndDateValue(null);
      startDateValueRef.current = null;
      endDateValueRef.current = null;
      setCalendarIsOpen(false);
      setShowTooltip(false);
      datePickerRef.current.setBlur(false);
    },
    [props.onCommit, onBlur]
  );

  useSecondEffect(() => {
    if (!calendarIsOpen) {
      if (props.value && props.value[0]) {
        const epoch = Date.parse(props.value[0]);
        setStartDateValue(new Date(epoch));
      } else {
        setStartDateValue(null);
      }
      if (props.value && props.value[1]) {
        const epoch = Date.parse(props.value[1]);
        setEndDateValue(new Date(epoch));
      } else {
        setEndDateValue(null);
      }
      setInputValue(formattedValue);
      handleCalendarClose();
      setShowTooltip(false);
    } else {
      manuallyEnterRef.current = false;
    }
  }, [calendarIsOpen]);

  useEffect(() => {
    registerDatePicker(id, {
      close: handleCalendarClickOutside,
    });
    return () => {
      unregisterDatePicker(id);
    };
  }, [id, handleCalendarClickOutside]);

  useSecondEffect(() => {
    if (!inputIsFocused()) {
      setInputValue(formattedValue);
    }
  }, [formattedValue]);

  useSecondEffect(() => {
    if (startDateValue) {
      datePickerRef.current.setPreSelection(startDateValue);
    }
  }, [startDateValue]);

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: !!inputValue,
    labelRef: labelRef,
    value: inputValue,
    label: props.label,
  });

  const handleFocused = useCallback(() => {
    if (isMobile) return;
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip, isMobile]);

  const handleBlurred = useCallback(() => {
    if (isMobile) return;
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip, isMobile]);

  useEffect(() => {
    // Workaround for https://github.com/Hacker0x01/react-datepicker/issues/2832
    if (datePickerRef.current) {
      if (!datePickerRef.current.calendar) {
        datePickerRef.current.calendar = {
          componentNode: document.createElement("div"),
        };
      }
    }
  });

  return (
    <>
      {props.tooltip && finalShowTooltip && !calendarIsOpen ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        onClick={handleCalendarInputClick}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        style={style}
        ref={controlRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.showIcon && !props.startIcon ? (
          <StartIcon
            startIcon={"calendar"}
            onStartIconClick
            startIconActive={calendarIsOpen}
          />
        ) : null}
        <label onBlur={handleBlur}>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              inputValue ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          <DatePicker
            locale={__getGlobalLocale()}
            ref={useCombinedRef(datePickerRef, ref)}
            // Disable tab focus on DatePicker
            tabIndex={props.tabIndex}
            onChangeRaw={handleChangeRaw}
            onClickOutside={handleCalendarClickOutside}
            open={calendarIsOpen}
            popperPlacement="bottom-start"
            popperContainer={
              isMobile
                ? calendarIsOpen
                  ? AdvDatePickerMobileContainer
                  : undefined
                : AdvDatePickerContainer
            }
            disabledKeyboardNavigation={isMobile}
            onKeyDown={handleFakeInputKeyDown}
            selected={datesValue[0]}
            openToDate={datesValue[0] || currentDate}
            startDate={datesValue[0]}
            endDate={datesValue[1]}
            dateFormat={displayFormat}
            value={inputValue}
            selectsRange
            disabled={props.disabled || props.readOnly}
            readOnly={isMobile}
            onChange={handleChange}
            onFocus={handleFocus}
          >
            {isMobile ? (
              <div className="react-datepicker__actions">
                <Button minimal onClick={handleCancelCalendar}>
                  {pt("datepicker", "Cancel")}
                </Button>
                <Button primary onClick={handleMobileCommit}>
                  {pt("datepicker", "Ok")}
                </Button>
              </div>
            ) : null}
          </DatePicker>
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
      </span>
    </>
  );
});

const AdvDatePickerContainer = ({ children }) => {
  const el = document.body;

  return <Portal container={el}>{children}</Portal>;
};

const AdvDatePickerMobileContainer = (props) => {
  const el = document.body;

  return (
    <Portal container={el}>
      <div className="react-datepicker__portal">{props.children}</div>
    </Portal>
  );
};

// const CLEAVE_OPTIONS = {
//   time: true,
//   timePattern: ["h", "m"],
// };

// const CustomTimeInput: React.FC<any> = ({
//   startTimeValueRef,
//   endTimeValueRef,
//   onTimeChange,
//   startTimeValue,
//   endTimeValue,
// }) => {
//   const handleStartTimeChange = useCallback(
//     (timeValue) => {
//       startTimeValueRef.current = timeValue;
//     },
//     [endTimeValue, onTimeChange]
//   );

//   const handleEndTimeChange = useCallback(
//     (timeValue) => {
//       endTimeValueRef.current = timeValue;
//     },
//     [startTimeValue, onTimeChange]
//   );

//   const handleBlur = useCallback(() => {
//     onTimeChange(startTimeValueRef.current, endTimeValueRef.current);
//   }, [onTimeChange]);

//   return (
//     <div>
//       <AdvInput
//         value={startTimeValue}
//         onCommit={handleStartTimeChange}
//         cleave={CLEAVE_OPTIONS}
//         onBlur={handleBlur}
//       />
//       <AdvInput
//         value={endTimeValue}
//         onCommit={handleEndTimeChange}
//         cleave={CLEAVE_OPTIONS}
//         onBlur={handleBlur}
//       />
//     </div>
//   );
// };

AdvDateRangePicker.defaultProps = {
  autocomplete: false,
  // defaultStartTime: "00:00",
  // defaultEndTime: "23:59",
  showIcon: true,
};

export default AdvDateRangePicker;
