import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { CellMeasurerCache } from "react-virtualized/dist/commonjs";

export type CalendarTimelineGroupsHeightSyncContextType = {
  sidebarGroupsMeasureCache: CellMeasurerCache;
  eventsMeasureCache: CellMeasurerCache;
  clearMeasureCache: () => void;
  getMaxRowHeight: (index: number) => number;
  iteration: number;
};

const CalendarTimelineGroupsHeightSyncContext =
  React.createContext<CalendarTimelineGroupsHeightSyncContextType>(null);

export interface CalendarGroupsHeightSyncContextProviderProps {}

const CalendarTimelineGroupsHeightSyncContextProvider: React.FC<CalendarGroupsHeightSyncContextProviderProps> =
  memo((props) => {
    const [iteration, setIteration] = useState(0);
    const sidebarGroupsMeasureCache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
      });
    }, []);

    const eventsMeasureCache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
      });
    }, []);

    const clearMeasureCache = useCallback(() => {
      sidebarGroupsMeasureCache.clearAll();
      eventsMeasureCache.clearAll();
      setIteration((iteration) => iteration + 1);
    }, [eventsMeasureCache, sidebarGroupsMeasureCache]);

    const getMaxRowHeight = useCallback(
      (index: number) => {
        const height = Math.max(
          sidebarGroupsMeasureCache.rowHeight({ index }),
          eventsMeasureCache.rowHeight({ index })
        );

        return height;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [eventsMeasureCache, sidebarGroupsMeasureCache, iteration]
    );

    const value = useMemo(() => {
      return {
        sidebarGroupsMeasureCache,
        eventsMeasureCache,
        clearMeasureCache,
        getMaxRowHeight,
        iteration,
      };
    }, [
      clearMeasureCache,
      eventsMeasureCache,
      getMaxRowHeight,
      iteration,
      sidebarGroupsMeasureCache,
    ]);

    return (
      <CalendarTimelineGroupsHeightSyncContext.Provider value={value}>
        {props.children}
      </CalendarTimelineGroupsHeightSyncContext.Provider>
    );
  });

const useCalendarTimelineGroupsHeightSyncContext = () => {
  return useContext(CalendarTimelineGroupsHeightSyncContext);
};

export {
  CalendarTimelineGroupsHeightSyncContextProvider,
  useCalendarTimelineGroupsHeightSyncContext,
};
