const groupDaysToWeeks = (days: Date[]) => {
  return days.reduce((weeks, day, index) => {
    const weekIndex = Math.floor(index / 7);
    if (!weeks[weekIndex]) {
      weeks[weekIndex] = [];
    }
    weeks[weekIndex].push(day);
    return weeks;
  }, [] as Date[][]);
};

export default groupDaysToWeeks;
