const cm = (baseClass: string, modifierObject: Record<string, any>) => {
  return Object.keys(modifierObject).reduce((result, modifierName) => {
    return {
      ...result,
      [baseClass + "--" + modifierName]: !!modifierObject[modifierName],
    };
  }, {});
};

export default cm;
