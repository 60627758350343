import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import React, { CSSProperties, useMemo } from "react";

export interface AdvWorkflowProps {
  color?: string;
  name?: string;
  onClick?: (e?: any) => any;
  endIcon?: any;
  endIconSize?: any;
  endIconSpin?: boolean;
}

const DEFAULT_NAME = "None";
const DEFAULT_COLOR = "#BCBCC9";

const AdvWorkflow = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AdvWorkflowProps>
>((props, ref) => {
  const style = useMemo(() => {
    const style: CSSProperties = {};
    style["--r365-workflow-color"] = props.color || DEFAULT_COLOR;
    return style;
  }, [props.color]);

  return (
    <div
      ref={ref}
      className={clsx("r365-workflow")}
      style={style}
      onClick={props.onClick}
    >
      <div className="r365-workflow__start-icon">
        <div className="r365-workflow__start-icon__rect"></div>
      </div>
      <div className="r365-workflow__label">
        {props.name ? props.name : DEFAULT_NAME}
      </div>
      {props.endIcon ? (
        <div
          className="r365-workflow__end-icon"
          style={{ fontSize: props.endIconSize }}
        >
          <Icon icon={props.endIcon} spin={props.endIconSpin} />
        </div>
      ) : null}
    </div>
  );
});

export default AdvWorkflow;
