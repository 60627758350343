import React from "react";
import AdvSelectMultipleValue from "./AdvSelectMultipleValue";
import AdvSelectValue from "./AdvSelectValue";
import cc from "./cc";
import useHasValue from "./hooks/useHasValue";
import useIsMultiple from "./hooks/useIsMultiple";
import useValue from "./hooks/useValue";

export interface AdvSelectValueContainerProps {
  overlay?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

const AdvSelectValueContainer: React.FC<AdvSelectValueContainerProps> = (
  props
) => {
  const isMultiple = useIsMultiple();
  const hasValue = useHasValue();
  const value = useValue();

  return (
    <div className={cc(`value-container`)}>
      {props.overlay ? (
        <div className={cc("overlay")}>{props.overlay}</div>
      ) : isMultiple ? (
        <AdvSelectMultipleValue value={hasValue ? value : null} />
      ) : (
        <AdvSelectValue
          disabled={props.disabled || props.readOnly}
          value={hasValue ? value : null}
        />
      )}
    </div>
  );
};

export default AdvSelectValueContainer;
