import { defined, useCombinedRef, useSecondEffect } from "@adv-libs/utils";
import MonacoEditor, { EditorProps, Monaco } from "@monaco-editor/react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdvControlLabel from "../AdvControlLabel";
import AdvInputBigTooltip from "../AdvInput/AdvInputBigTooltip";
import EndIcon from "../AdvInput/EndIcon";
import StartIcon from "../AdvInput/StartIcon";
import useAutoWidthMeasure from "../AdvInput/useAutoWidthMeasure";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";

export interface AdvRegexpInputProps extends AdvCommonControlProps {
  type?: string;
  height?: number;
  fieldName?: string;
  onBlur?: (e?) => any;
  onFocus?: (e?) => any;
  isClearable?: boolean;
  autoWidth?: boolean;
  minWidth?: number;
}

const name = "regexp-input";

const AdvRegexpInput = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvRegexpInputProps>
>((props, ref) => {
  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipIsBig =
    typeof props.tooltip === "string" ? false : !!props.tooltip?.isBig;

  const value = useMemo(() => {
    return defined(props.value) ? props.value : "";
  }, [props.value]);

  const hasValue = value;

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: hasValue,
    labelRef: labelRef,
    value: value,
  });

  const {
    onBlur,
    onFocus,
    className,
    style,
    // onAnimationStart,
    // onAnimationEnd,
  } = useControl({
    name,
    value,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    height: props.height,
    notify: props.notify,
  });

  const handleChange = useCallback(
    (value) => {
      props.onCommit(value);
    },
    [props.onCommit]
  );

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  const handleStopClearClickPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);

      const $input = controlRef.current.querySelector("input");
      if ($input) {
        $input.blur();
      }
    },
    [props.onCommit, onBlur]
  );

  const handleMouseEnter = useCallback((e) => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (tooltipIsBig) return;
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, tooltipIsBig, props.tooltip, hasValue, focused]);

  /**
   * If component comes disabled or readonly, blur it
   */
  useSecondEffect(() => {
    if (props.disabled || props.readOnly) {
      onBlur();
    }
  }, [props.disabled, props.readOnly]);

  useLayoutEffect(() => {
    (controlRef.current as any).commit = (value: any) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  const handleFocused = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip]);

  const handleBlurred = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip]);

  useEffect(() => {
    const $scroll = controlRef.current.querySelector(".CodeMirror-scroll");
    if ($scroll) {
      $scroll.scrollTop = 4;
    }
  }, []);

  const handleEditorWillMount = useCallback((monaco: Monaco) => {
    monaco.languages.register({ id: "regex" });
    monaco.languages.setMonarchTokensProvider("regex", {
      tokenizer: {
        root: [
          [/\[.*?\]/, "constant"], // square brackets
          [/\\./, "escape"], // escape sequences
          [/\(\?:|\(\?=|\(\?!/, "metatag"], // non-capturing groups
          [/\d+/, "number"], // numbers
          [/[()]/, "delimiter"], // parentheses
          [/[\^$*+?.]/, "operator"], // operators
        ],
      },
    });

    monaco.editor.defineTheme("regexTheme", {
      base: "vs",
      inherit: true,
      colors: {},
      rules: [
        { token: "constant", foreground: "4EC9B0" },
        { token: "escape", foreground: "C586C0" },
        { token: "metatag", foreground: "569CD6" },
        { token: "number", foreground: "3F2FC9" },
        { token: "delimiter", foreground: "D4D4D4" },
        { token: "operator", foreground: "F14C4C" },
      ],
    });
  }, []);

  const handleEditorMounted = useCallback(
    (editor: any) => {
      editor.onDidChangeCursorPosition((e) => {
        // Monaco tells us the line number after cursor position changed
        if (e.position.lineNumber > 1) {
          // Trim editor value
          editor.setValue(
            editor
              .getValue()
              .replace(/[\n\r]/g, "")
              .trim()
          );
          // Bring back the cursor to the end of the first line
          editor.setPosition({
            ...e.position,
            // Setting column to Infinity would mean the end of the line
            column: Infinity,
            lineNumber: 1,
          });
        }
      });

      editor.onDidFocusEditorText((e) => {
        onFocus();
      });

      editor.onDidBlurEditorText((e) => {
        onBlur();
      });
    },
    [onFocus, onBlur]
  );

  const options = useMemo<EditorProps["options"]>(() => {
    return {
      readOnly: props.disabled || props.readOnly,
      lineNumbers: "off",
      contextmenu: false,
      renderLineHighlight: "none",
      quickSuggestions: false,
      autoClosingBrackets: "never",
      autoClosingOvertype: "never",
      autoIndent: "none",
      autoSurround: "never",
      autoClosingQuotes: "never",
      autoClosingDelete: "never",
      glyphMargin: false,
      lineDecorationsWidth: 0,
      folding: false,
      fixedOverflowWidgets: true,
      acceptSuggestionOnEnter: "off",
      roundedSelection: false,
      cursorStyle: "line-thin",
      occurrencesHighlight: false,
      links: false,
      wordBasedSuggestions: false,
      find: {
        addExtraSpaceOnTop: false,
        autoFindInSelection: "never",
        seedSearchStringFromSelection: "never",
      },
      fontSize: 14,
      fontWeight: "normal",
      wordWrap: "off",
      lineNumbersMinChars: 0,
      overviewRulerLanes: 0,
      overviewRulerBorder: false,
      hideCursorInOverviewRuler: true,
      scrollBeyondLastColumn: 0,
      scrollbar: {
        horizontal: "hidden",
        vertical: "hidden",
        alwaysConsumeMouseWheel: false,
      },
      minimap: {
        enabled: false,
      },
    };
  }, [props.disabled || props.readOnly]);

  return (
    <>
      {tooltipIsBig && focused ? (
        <AdvInputBigTooltip
          tooltip={props.tooltip}
          referenceElement={referenceElement}
        />
      ) : props.tooltip && !tooltipIsBig && finalShowTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        style={style}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={useCombinedRef(controlRef, setReferenceElement)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            startIconSize={props.startIconSize}
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.endIcon ? (
          <EndIcon
            endIcon={props.endIcon}
            endIconSize={props.endIconSize}
            endIconSpin={props.endIconSpin}
            endIconTooltip={props.endIconTooltip}
            onEndIconClick={props.onEndIconClick}
          />
        ) : null}
        <label>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              props.value ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
                onClick={handleStopClearClickPropagation}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          <MonacoEditor
            onMount={handleEditorMounted}
            options={options}
            language="regex"
            theme="regexTheme"
            height={23}
            beforeMount={handleEditorWillMount}
            value={value}
            loading={null}
            onChange={handleChange}
          />
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
      </span>
    </>
  );
});

AdvRegexpInput.defaultProps = {};

export default AdvRegexpInput;
