import React, { useContext, useMemo } from "react";
import type { CalendarSharedConfigType } from "./types";

export type CalendarSharedConfigContextType = CalendarSharedConfigType;

const CalendarSharedConfigContext =
  React.createContext<CalendarSharedConfigContextType>(null);

export interface CalendarSharedConfigContextProviderProps
  extends CalendarSharedConfigType {}

const CalendarSharedConfigContextProvider: React.FC<
  CalendarSharedConfigContextProviderProps
> = (props) => {
  const defaultEventStyle = useMemo<
    CalendarSharedConfigType["defaultEventStyle"]
  >(() => {
    return {
      eventStyle: {
        borderColor:
          props.defaultEventStyle?.eventStyle?.borderColor || "#BC8D33",
      },
      eventBackgroundStyle: {
        backgroundColor:
          props.defaultEventStyle?.eventBackgroundStyle?.backgroundColor ||
          "#FFECA9",
      },
      eventContentStyle: {
        color: props.defaultEventStyle?.eventContentStyle?.color || "#6D4A15",
      },
    };
  }, [props.defaultEventStyle]);

  const value = useMemo<CalendarSharedConfigType>(() => {
    return {
      defaultEventStyle: defaultEventStyle,
      scrollBarWidth: props.scrollBarWidth || 8,
    };
  }, [defaultEventStyle, props.scrollBarWidth]);

  return (
    <CalendarSharedConfigContext.Provider value={value}>
      {props.children}
    </CalendarSharedConfigContext.Provider>
  );
};

const useCalendarSharedConfig = () => {
  return useContext(CalendarSharedConfigContext);
};

export { CalendarSharedConfigContextProvider, useCalendarSharedConfig };
