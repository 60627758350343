import { KeyCode, defined, keyEvents, useAutoName } from "@adv-libs/utils";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { cp } from "../utils";

export interface DialogProps {
  maxWidth?: any;
  height?: any;
  minWidth?: any;
  maxHeight?: any;
  zIndex?: number;
  onClose?: () => any;
}

const Dialog: React.FC<DialogProps> = (props) => {
  const { maxWidth, maxHeight, minWidth, height } = props;

  const lastFocusedElement = useRef<HTMLElement>();
  const dialogRef = useRef<HTMLDivElement>();

  const dialogName = useAutoName("dialog");

  const dialogInlineStyle = useMemo(() => {
    const style = {};
    if (defined(maxWidth)) {
      style["maxWidth"] = maxWidth;
    }
    if (defined(minWidth)) {
      style["minWidth"] = minWidth;
    }
    if (defined(height)) {
      style["height"] = `min(${
        typeof height === "number" ? height + "px" : height
      }, 100vh)`;
    }
    return style;
  }, [maxWidth, minWidth, height]);

  const handleESCKeyDown = useCallback(
    (e) => {
      if (props.onClose) {
        const activeDialog = dialogBuffer[dialogBuffer.length - 1];
        if (activeDialog === dialogName) {
          props.onClose();
          if (lastFocusedElement.current?.focus) {
            lastFocusedElement.current.focus();
          }
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }
    },
    [props.onClose]
  );

  useEffect(() => {
    keyEvents.addEventListener(KeyCode.KEY_ESCAPE, handleESCKeyDown);
    return () => {
      keyEvents.removeEventListener(KeyCode.KEY_ESCAPE, handleESCKeyDown);
    };
  }, [handleESCKeyDown]);

  useEffect(() => {
    lastFocusedElement.current = document.activeElement as HTMLElement;
    dialogRef.current.focus();
  }, []);

  useEffect(() => {
    dialogBuffer.push(dialogName);
    return () => {
      const index = dialogBuffer.findIndex((name) => name === dialogName);
      if (index > -1) {
        dialogBuffer.splice(index, 1);
      }
    };
  }, []);

  return (
    <div
      className={cp("dialog-backdrop")}
      style={{ zIndex: props.zIndex ?? 1000 }}
    >
      <div className={cp("dialog-container")} style={dialogInlineStyle}>
        <div
          ref={dialogRef}
          tabIndex={0}
          className={clsx(cp("dialog"))}
          style={{ maxHeight: props.maxHeight }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

Dialog.defaultProps = {
  maxWidth: 700,
};

const dialogBuffer: string[] = [];

export default Dialog;
