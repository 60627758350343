import { stringToDate } from "@adv-libs/utils";
import {
  dateAllowedInputFormats,
  dateAllowedInputFormatsWithTime,
} from "./constants";

const dateParser = (dateString: string, time?: boolean) => {
  if (!dateString) return null;

  dateString = dateString.trim();

  /** Try to parse only day */
  if (dateString.length < 3) {
    const day = parseInt(dateString);

    if (!isNaN(day) && day > 0 && day <= 31) {
      const date = new Date();
      date.setDate(day);

      return date;
    }

    return null;
  }

  /** Try to parse the full date */

  if (time) {
    try {
      for (const format of dateAllowedInputFormatsWithTime) {
        const date = stringToDate(dateString, format, false);
        if (date) {
          return date;
        }
      }
    } catch (err) {}
  }

  for (const format of dateAllowedInputFormats) {
    const date = stringToDate(dateString, format, false);
    if (date) {
      return date;
    }
  }

  return null;
};

export default dateParser;
