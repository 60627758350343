import React, { memo, useCallback, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import CalendarMobileBody from "../CalendarBody/CalendarMobileBody/CalendarMobileBody";
import CalendarContainer from "../CalendarContainer";
import CalendarContent from "../CalendarContent";
import CalendarMobileHeader from "../CalendarHeader/CalendarMobileHeader";
import CalendarLoadingOverlay from "../CalendarLoadingOverlay";
import { useCalendarMobileContext } from "./CalendarMobileContext";
import { useCalendarSharedContext } from "../CalendarSharedContext";
import useMobileCSSProperties from "../hooks/useMobileCSSProperties";
import useSharedCSSProperties from "../hooks/useSharedCSSProperties";

interface CalendarMobileCoreProps {}

const CalendarMobileCore: React.FC<CalendarMobileCoreProps> = memo((props) => {
  const { isLoading } = useCalendarSharedContext();
  const { onSwipedLeft, onSwipedRight } = useCalendarMobileContext();

  const sharedCSSProperties = useSharedCSSProperties();
  const mobileCSSProperties = useMobileCSSProperties();

  const containerStyle = useMemo(() => {
    return {
      ...sharedCSSProperties,
      ...mobileCSSProperties,
    };
  }, [sharedCSSProperties, mobileCSSProperties]);

  const handleSwipedLeft = useCallback(() => {
    if (isLoading) return;
    if (typeof onSwipedLeft === "function") {
      onSwipedLeft();
    }
  }, [isLoading, onSwipedLeft]);

  const handleSwipedRight = useCallback(() => {
    if (isLoading) return;
    if (typeof onSwipedRight === "function") {
      onSwipedRight();
    }
  }, [isLoading, onSwipedRight]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    touchEventOptions: { passive: true },
  });

  return (
    <CalendarContainer
      style={containerStyle}
      className={"rivile-calendar--mobile"}
    >
      <CalendarContent {...swipeHandlers}>
        <CalendarMobileHeader />
        <CalendarMobileBody />
        <CalendarLoadingOverlay />
      </CalendarContent>
    </CalendarContainer>
  );
});

export default CalendarMobileCore;
