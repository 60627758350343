let _globalDateFormat;
let _globalLocale;

export const __setGlobalDateFormat = (
  getFormat: (time?: boolean) => string | null
) => {
  _globalDateFormat = getFormat;
};

export const __setGlobalLocale = (getLocale: () => string) => {
  _globalLocale = getLocale;
};

export const __getGlobalLocale = () => {
  if (typeof _globalLocale === "function") {
    return _globalLocale();
  } else {
    return "en";
  }
};

const DEFAULT_DATETIME_FORMAT = "yyyy-MM-dd HH:mm";
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

export const getDateTimeDisplayFormat = (
  time?: boolean,
  displayFormat?: string
) => {
  const defaultDisplayFormat = time
    ? DEFAULT_DATETIME_FORMAT
    : DEFAULT_DATE_FORMAT;

  if (displayFormat || typeof _globalDateFormat !== "function") {
    return displayFormat ?? defaultDisplayFormat;
  } else {
    const displayFormat = _globalDateFormat(time);
    if (displayFormat) return displayFormat;
    return defaultDisplayFormat;
  }
};
