import { createContext, createProvider, useDebounced } from "@adv-libs/utils";
import React, { useMemo, useRef } from "react";
import useCreateAddItem from "./hooks/create/useCreateAddItem";
import useCreateFetchItems from "./hooks/create/useCreateFetchItems";
import useCreateFilteredItems from "./hooks/create/useCreateFilteredItems";
import useCreateHasValue from "./hooks/create/useCreateHasValue";
import useCreateItemManipulation from "./hooks/create/useCreateItemManipulation";
import useCreateReloadItem from "./hooks/create/useCreateReloadItem";
import useCreateRemoveItem from "./hooks/create/useCreateRemoveItem";
import useCreateSelectState from "./hooks/create/useCreateSelectState";
import useAutoWidthMeasure from "./hooks/global/useAutoWidthMeasure";
import useDetectPrimitive from "./hooks/global/useDetectPrimitive";
import useItemsFetching from "./hooks/global/useItemsFetching";
import useSelectByDefault from "./hooks/global/useSelectByDefault";
import { AdvSelectContextProviderProps, AdvSelectContextType } from "./types";

export const AdvSelectContext = createContext<AdvSelectContextType>(null);

const Provider = createProvider(AdvSelectContext);

const AdvSelectContextProvider: React.FC<AdvSelectContextProviderProps> = (
  props
) => {
  const { state, dispatch } = useCreateSelectState({
    page: props.paginated ? 0 : null,
  });

  const debouncedQuery = useDebounced(state.query, 300);

  const getItemsIsFunction = typeof props.getItems === "function";

  /** Refs */
  const singleQueryInputRef = useRef<HTMLInputElement>();
  const multipleQueryInputRef = useRef<HTMLInputElement>();
  const fakeMultipleSelectInputRef = useRef<HTMLInputElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const controlRef = useRef<HTMLSpanElement>();
  const popoverRef = useRef<HTMLDivElement>();

  const refs = useMemo(() => {
    return {
      singleQueryInputRef,
      multipleQueryInputRef,
      fakeMultipleSelectInputRef,
      popoverRef,
      labelRef,
      controlRef,
    };
  }, []);

  /** Re-usable hooks */
  const itemManipulation = useCreateItemManipulation({
    labelParam: props.labelParam,
    valueParam: props.valueParam,
    selectedLabelParam: props.selectedLabelParam,
    selectedValueParam: props.selectedValueParam,
  });

  const fetchItems = useCreateFetchItems({
    getItems: props.getItems,
    filter: props.filter,
    params: props.params,
    paginated: props.paginated,
    detectResponse: props.detectResponse,
    debouncedQuery: debouncedQuery,
    dispatch: dispatch,
    state: state,
  });

  const reloadItem = useCreateReloadItem({
    getItems: props.getItems,
    multiple: props.multiple,
    onCommit: props.onCommit,
    paginated: props.paginated,
  });

  const addItem = useCreateAddItem({
    multiple: props.multiple,
    onBeforeSelect: props.onBeforeSelect,
    onCommit: props.onCommit,
    singleSelect: props.singleSelect,
    value: props.value,
  });

  const removeItem = useCreateRemoveItem({
    multiple: props.multiple,
    onCommit: props.onCommit,
    value: props.value,
    clearValue: props.clearValue,
  });

  /** Calculated values */
  const filteredItems = useCreateFilteredItems({
    filter: props.filter,
    multiple: props.multiple,
    multipleCheckboxes: props.multipleCheckboxes,
    prependItems: props.prependItems,
    singleSelect: props.singleSelect,
    itemIsDisabled: props.itemIsDisabled,
    value: props.value,
    itemManipulation: itemManipulation,
    detectResponse: props.detectResponse,
    isPaginated: state.isPaginated,
    items: state.items,
    debouncedQuery,
  });

  const hasValue = useCreateHasValue({
    multiple: props.multiple,
    value: props.value,
    query: state.query,
  });

  /** Global hooks */
  useAutoWidthMeasure({
    minWidth: props.minWidth,
    autoWidth: props.autoWidth,
    hasValue: hasValue,
    refs: refs,
  });

  useDetectPrimitive({
    itemManipulation: itemManipulation,
    reloadItem: reloadItem,
    detectPrimitive: props.detectPrimitive,
    getItems: props.getItems,
    multiple: props.multiple,
    items: state.items,
    onCommit: props.onCommit,
    paginated: props.paginated,
    prependItems: props.prependItems,
    value: props.value,
  });

  useSelectByDefault({
    selectByDefault: props.selectByDefault,
    items: state.items,
    onCommit: props.onCommit,
    prependItems: props.prependItems,
    value: props.value,
  });

  useItemsFetching({
    cacheTime: props.cacheTime,
    dispatch: dispatch,
    state: state,
    fetchItems: fetchItems,
    getItemsIsFunction: getItemsIsFunction,
    debouncedQuery: debouncedQuery,
    detectPrimitive: props.detectPrimitive,
    filter: props.filter,
    selectByDefault: props.selectByDefault,
    value: props.value,
    getItems: props.getItems,
    params: props.params,
  });

  const value = useMemo<AdvSelectContextType>(() => {
    return {
      itemManipulation,
      addItem,
      removeItem,
      fetchItems,
      reloadItem,
      state,
      dispatch,
      debouncedQuery,
      filteredItems,
      hasValue,
      paginated: props.paginated,
      multiple: props.multiple,
      value: props.value,
      singleSelect: props.singleSelect,
      onCommit: props.onCommit,
      tabIndex: props.tabIndex,
      actions: props.actions,
      clearValue: props.clearValue,
      multipleCheckboxes: props.multipleCheckboxes,
      multipleHideSearch: props.multipleHideSearch,
      popoverWrapper: props.popoverWrapper,
      itemRender: props.itemRender,
      showMenuConfirm: props.showMenuConfirm,
      itemIsDisabled: props.itemIsDisabled,
      itemIcon: props.itemIcon,
      selectedItemRender: props.selectedItemRender,
      refs,
    };
  }, [
    itemManipulation,
    addItem,
    removeItem,
    fetchItems,
    reloadItem,
    debouncedQuery,
    state,
    dispatch,
    filteredItems,
    hasValue,
    props.paginated,
    props.multiple,
    props.onCommit,
    props.singleSelect,
    props.value,
    props.tabIndex,
    props.actions,
    props.multipleCheckboxes,
    props.multipleHideSearch,
    props.itemRender,
    props.showMenuConfirm,
    props.itemIsDisabled,
    props.itemIcon,
    props.selectedItemRender,
    props.popoverWrapper,
    refs,
  ]);

  return <Provider value={value}>{props.children}</Provider>;
};

export { AdvSelectContextProvider };
