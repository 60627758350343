import React from "react";
import { Icon } from "@adv-libs/icons";

export interface CalendarMobileBodyWeekCloseButtonProps {
  onClose: () => any;
}

const CalendarMobileBodyWeekCloseButton: React.FC<
  CalendarMobileBodyWeekCloseButtonProps
> = (props) => {
  return (
    <div className="rivile-calendar-body-week-close">
      <div
        className="rivile-calendar-body-week-close-button"
        onClick={props.onClose}
      >
        <Icon icon="caret-up" />
      </div>
    </div>
  );
};

export default CalendarMobileBodyWeekCloseButton;
