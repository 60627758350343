import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdvControlLabel from "../AdvControlLabel";
import StartIcon from "../AdvInput/StartIcon";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import AdvSimpleSelectClearIndicator from "./AdvSimpleSelectClearIndicator";
import AdvSimpleSelectLoadingIndicator from "./AdvSimpleSelectLoadingIndicator";
import cc from "./cc";

export type AdvSimpleSelectItem = {
  id: any;
  name: string;
};

export interface AdvSimpleSelectProps extends AdvCommonControlProps {
  fieldName?: string;
  isClearable?: boolean;
  getItems?:
    | AdvSimpleSelectItem[]
    | (() => Promise<AdvSimpleSelectItem[]> | AdvSimpleSelectItem[]);
}

type AdvSimpleSelectState = {
  error: any;
  isLoading: boolean;
  items: AdvSimpleSelectItem[];
};

const initialState: AdvSimpleSelectState = {
  error: null,
  isLoading: false,
  items: [],
};

const name = "simple-select";

const AdvSimpleSelect: React.FC<AdvSimpleSelectProps> = (props) => {
  const [state, setState] = useState<AdvSimpleSelectState>(initialState);

  const hasValue = props.value !== undefined && props.value !== null;

  const selectContainerRef = useRef<HTMLDivElement>(null);

  const { className, style } = useControl({
    label: props.label,
    name,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger || !!state.error,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    // assume empty array value as undefined
    value: hasValue,
    nullIsValue: false, //props.nullIsValue
    className: props.className,
    notify: props.notify,
  });

  const fetchItems = useCallback(() => {
    setState((state) => ({ ...state, isLoading: true }));

    const items = props.getItems;

    if (Array.isArray(items)) {
      setState((state) => ({
        ...state,
        isLoading: false,
        items: items,
      }));
    } else if (typeof items === "function") {
      const promise = items();

      if (promise instanceof Promise) {
        promise
          .then((items) => {
            setState((state) => ({
              ...state,
              isLoading: false,
              items: items,
            }));
          })
          .catch((error) => {
            console.error(error);
            setState((state) => ({
              ...state,
              isLoading: false,
              error: error,
            }));
          });
      } else {
        setState((state) => ({
          ...state,
          isLoading: false,
          items: promise,
        }));
      }
    }
  }, [props.getItems]);

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  const handleClearButtonMouseDown = useCallback(
    (e) => {
      e.stopPropagation();
      props.onCommit(undefined);
    },
    [props.onCommit]
  );

  const items = state.items;

  const handleChange = useCallback(
    (e) => {
      const index = parseInt(e.target.value);

      if (!isNaN(index)) {
        const item = items[index];
        props.onCommit(item);
      }
    },
    [items, props.onCommit]
  );

  const selectedItemIndex = useMemo(() => {
    if (props.value && Array.isArray(items)) {
      return items.findIndex((item) => item.id === props.value.id);
    } else {
      return "";
    }
  }, [props.value, items]);

  useEffect(() => {
    fetchItems();
  }, [props.getItems]);

  return (
    <span
      data-field={props.fieldName}
      data-testid={"field|" + props.fieldName}
      data-type={name}
      className={clsx(className)}
      style={style}
    >
      {props.startIcon ? (
        <StartIcon
          onStartIconClick={
            props.onStartIconClick ? handleStartIconClick : undefined
          }
          startIcon={props.startIcon}
          startIconActive={props.startIconActive}
          startIconCount={props.startIconCount}
          startIconSize={props.startIconSize}
          startIconSpin={props.startIconSpin}
          value={props.value}
        />
      ) : null}
      <div className={cc(`indicator-container`)}>
        {props.disabled || props.readOnly ? null : state.isLoading ? (
          <AdvSimpleSelectLoadingIndicator />
        ) : props.isClearable && hasValue ? (
          <AdvSimpleSelectClearIndicator
            onMouseDown={handleClearButtonMouseDown}
          />
        ) : null}
      </div>
      <label>
        <AdvControlLabel
          label={state.error ? state.error.toString() : props.label}
        />
        <div className={cc("container")} ref={selectContainerRef}>
          <select
            value={selectedItemIndex}
            onChange={handleChange}
            disabled={props.disabled || props.readOnly || state.isLoading}
          >
            <option style={{ display: "none" }}></option>
            {items.map((item, index) => {
              return (
                <option key={index} value={index}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </label>
    </span>
  );
};

export default AdvSimpleSelect;
