import { useTranslation } from "@adv-libs/l10n";
import clsx from "clsx";
import { isToday, isWeekend } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { useCalendarSharedContext } from "../CalendarSharedContext";
import { useCalendarTimelineContext } from "../CalendarTimeline/CalendarTimelineContext";
import { useCalendarTimelineGroupsResizeContext } from "../CalendarTimeline/CalendarTimelineGroupsResizeContext";
import { useCalendarTimelineScrollableContext } from "../CalendarTimeline/CalendarTimelineScrollableContext";
import useCalendarGroupsDrag from "../hooks/useCalendarGroupsDrag";
import getDayKey from "../utils/getDayKey";
import getMonthsFromDays from "../utils/getMonthsFromDays";
import getWeekDayName from "../utils/getWeekDayName";

export interface CalendarTimelineHeaderProps {}

const CalendarTimelineHeader: React.FC<CalendarTimelineHeaderProps> = (
  props
) => {
  const { days, weekDayNames, weekStartsOn } = useCalendarSharedContext();
  const { showMonthsHeader } = useCalendarTimelineContext();
  const { headerRef } = useCalendarTimelineScrollableContext();
  const { dragRef } = useCalendarGroupsDrag();
  const { isOver } = useCalendarTimelineGroupsResizeContext();
  const { i18n } = useTranslation();

  const locale = i18n.language;

  const months = useMemo(() => {
    return getMonthsFromDays(days, locale);
  }, [days, locale]);

  return (
    <div className="rivile-calendar-header rivile-calendar-header--timeline">
      <div className="rivile-calendar-header-groups-header">
        <div
          className={clsx(
            "rivile-calendar-header-groups-resize-handler",
            isOver && "is-over"
          )}
          ref={dragRef}
        ></div>
      </div>
      <div ref={headerRef} className="rivile-calendar-header-container">
        <div className="rivile-calendar-header-content">
          {showMonthsHeader && (
            <div className="rivile-calendar-header-months">
              {months.map((month) => {
                return (
                  <div
                    key={`${month.year}-${month.month}`}
                    title={`${month.title} ${month.year}`}
                    className="rivile-calendar-header-month"
                    style={
                      {
                        "--rivile-calendar-month-days-count": month.days,
                      } as any
                    }
                  >
                    <div className="rivile-calendar-header-month-title">
                      {month.title}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="rivile-calendar-header-weekdays">
            {days.map((dayDate) => {
              const weekDayName = getWeekDayName(
                dayDate,
                weekStartsOn,
                weekDayNames
              );

              const isDayWeekend = isWeekend(dayDate);

              return (
                <div
                  key={getDayKey(dayDate)}
                  className={clsx(
                    "rivile-calendar-header-weekday",
                    isDayWeekend && "is-weekend"
                  )}
                >
                  {weekDayName}
                </div>
              );
            })}
          </div>
          <div className="rivile-calendar-header-days">
            {days.map((day) => {
              return <CalendarHeaderDay key={getDayKey(day)} day={day} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

interface CalendarHeaderDayProps {
  day: Date;
}

const CalendarHeaderDay: React.FC<CalendarHeaderDayProps> = (props) => {
  const monthDay = props.day.getDate();

  const { onDayClick } = useCalendarSharedContext();

  const handleDayClick = useCallback(
    (e) => {
      if (typeof onDayClick === "function") {
        onDayClick(props.day);
      }
    },
    [onDayClick, props.day]
  );

  const isDayWeekend = isWeekend(props.day);
  const isDayToday = isToday(props.day);

  return (
    <div
      className={clsx(
        "rivile-calendar-header-day",
        onDayClick && "clickable",
        isDayWeekend && "is-weekend",
        isDayToday && "is-today"
      )}
      onClick={handleDayClick}
    >
      {monthDay}
    </div>
  );
};

export default CalendarTimelineHeader;
