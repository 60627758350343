import { useCallback } from "react";
import { AdvSelectCommonProps } from "../../types";

export interface UseCreateRemoveItemOptions {
  multiple: AdvSelectCommonProps["multiple"];
  onCommit: AdvSelectCommonProps["onCommit"];
  value: AdvSelectCommonProps["value"];
  clearValue: AdvSelectCommonProps["clearValue"];
}

const useCreateRemoveItem = (options: UseCreateRemoveItemOptions) => {
  const removeItem = useCallback(
    (itemIndex?: number) => {
      if (options.multiple) {
        if (options.value) {
          const values = [...options.value];
          values.splice(itemIndex, 1);
          if (values.length > 0) {
            options.onCommit(values);
          } else {
            options.onCommit(options.clearValue ?? undefined);
          }
        }
      }
    },
    [options.value, options.onCommit, options.multiple]
  );

  return removeItem;
};

export default useCreateRemoveItem;
