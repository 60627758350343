import clsx from "clsx";
import React, { memo } from "react";
import { useCalendarSharedContext } from "../../../CalendarSharedContext";
import { useCalendarTimelineDOMReferencesContext } from "../../../CalendarTimeline/CalendarTimelineDOMReferencesContext";
import { useCalendarTimelineGroupsResizeContext } from "../../../CalendarTimeline/CalendarTimelineGroupsResizeContext";
import useCalendarGroupsDrag from "../../../hooks/useCalendarGroupsDrag";
import CalendarTimelineBodyGroups from "./CalendarTimelineBodyGroups";

export interface CalendarTimelineBodyGroupsSidebarProps {}

const CalendarTimelineBodyGroupsSidebar: React.FC<CalendarTimelineBodyGroupsSidebarProps> =
  memo((props) => {
    const { data } = useCalendarSharedContext();
    const { dragRef } = useCalendarGroupsDrag();
    const { isOver } = useCalendarTimelineGroupsResizeContext();
    const domRefs = useCalendarTimelineDOMReferencesContext();

    return (
      <div
        ref={domRefs.groupsSidebarRef}
        className="rivile-calendar-body-groups-sidebar"
      >
        <div
          ref={dragRef}
          className={clsx(
            "rivile-calendar-body-groups-resize-handle",
            isOver && "is-over"
          )}
        ></div>
        <CalendarTimelineBodyGroups groups={data.groups} />
      </div>
    );
  });

export default CalendarTimelineBodyGroupsSidebar;
