import { useTranslation } from "@adv-libs/l10n";
import clsx from "clsx";
import React, { useMemo } from "react";
import cc from "./cc";
import useItemManipulation from "./hooks/useItemManipulation";
import useSelectedItemRender from "./hooks/useSelectedItemRender";
import { AdvSelectOption } from "./types";

export interface AdvSelectMultipleValueProps {
  value: AdvSelectOption[] | null;
}

const AdvSelectMultipleValue: React.FC<AdvSelectMultipleValueProps> = (
  props
) => {
  const { getSelectedItemLabel } = useItemManipulation();
  const selectedItemRender = useSelectedItemRender();
  const { t } = useTranslation();

  const valueToDisplay = useMemo(() => {
    if (!props.value || !props.value?.length) return null;

    const label = getSelectedItemLabel(props.value[0]);

    if (props.value.length > 1) {
      return (
        <div className="selected-items">
          {props.value.length} {t("selected")}
        </div>
      );
    } else if (selectedItemRender) {
      return selectedItemRender({
        item: props.value[0],
        multiple: true,
        label: label,
      });
    } else {
      return label;
    }
  }, [props.value, selectedItemRender, getSelectedItemLabel]);

  return (
    <div
      className={clsx(cc(`multiple-value`))}
      title={typeof valueToDisplay === "string" ? valueToDisplay : undefined}
    >
      {valueToDisplay}
    </div>
  );
};

export default AdvSelectMultipleValue;
