import clsx from "clsx";
import React, { CSSProperties } from "react";
import { cm } from "../utils";
import cp from "../utils/cp";

export interface AdvSectionProps {
  className?: string;
  style?: CSSProperties;
  fullHeight?: boolean;
  shadow?: boolean;
  onClick?: (e?: any) => any;
  primitive?: boolean;
  transparent?: boolean;
}

const AdvSection = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AdvSectionProps>
>((props, ref) => {
  return (
    <div
      ref={ref}
      onClick={props.onClick}
      className={clsx(
        cp("section"),
        cm(cp("section"), {
          "full-height": props.fullHeight,
          shadow: props.shadow,
          primitive: props.primitive,
          transparent: props.transparent,
        }),
        props.className
      )}
      style={props.style}
    >
      {props.children}
    </div>
  );
});

AdvSection.defaultProps = {};

export default AdvSection;
