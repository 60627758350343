import { Icon } from "@adv-libs/icons";
import { useCombinedRef } from "@adv-libs/utils";
import clsx from "clsx";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { AdvCommonControlProps } from "../types";
import cm from "../utils/cm";
import cp from "../utils/cp";

export interface ButtonProps {
  fill?: boolean;
  padded?: boolean;
  minimal?: boolean;
  big?: boolean;
  primary?: boolean;
  title?: string;
  icon?: any;
  iconSrc?: string;
  rightIcon?: any;
  rightIconSize?: any;
  external?: boolean;
  tooltip?: AdvCommonControlProps["tooltip"];
  link?: string | (() => string);
  replace?: boolean;
  state?: any;
  iconSize?: any;
  iconStyle?: CSSProperties;
  rightIconStyle?: CSSProperties;
  onClick?: (e) => any;
  spin?: boolean;
  rightSpin?: boolean;
  disabled?: boolean;
  activeOnDisabled?: boolean;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  align?: "center" | "left" | "right";
  type?: "button" | "submit" | "reset";
  className?: string;
  /** New props */
  newStyle?: boolean;
  // @TODO change color variations to single prop
  dark?: boolean;
  black?: boolean;
  gray?: boolean;
  danger?: boolean;
  transparent?: boolean;
  light?: boolean;
  narrow?: boolean;
  color?: string;
  hover?: string;
  onMouseDown?: (e) => any;
}

const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps>
>((props, ref) => {
  const {
    fill,
    big,
    minimal,
    primary,
    link,
    replace,
    onClick,
    icon,
    iconSrc,
    rightIcon,
    rightIconSize,
    title,
    className,
    spin,
    contentStyle,
    rightSpin,
    disabled,
    type,
    children,
    external,
    padded,
    align,
    newStyle,
    dark,
    black,
    narrow,
    activeOnDisabled,
    iconSize,
    gray,
    tooltip,
    light,
    transparent,
    danger,
    hover,
    color,
    iconStyle,
    rightIconStyle,
    ...restProps
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>();

  const tooltipRef = useRef<AdvTooltipInstance>(null);

  const handleClick = useCallback(
    (e) => {
      if (disabled && !activeOnDisabled) return e.preventDefault();
      if (typeof onClick === "function") {
        onClick(e);
      }
    },
    [disabled, activeOnDisabled, onClick]
  );

  const onlyTwoIcons =
    props.icon && props.rightIcon && !props.title && !props.children;

  const finalIconStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (onlyTwoIcons) {
      style["paddingRight"] = 5;
      style["paddingLeft"] = 14;
    }
    if (iconStyle) {
      return { ...style, ...iconStyle };
    }
    return style;
  }, [onlyTwoIcons, iconStyle]);

  const finalRightIconStyle = useMemo(() => {
    const style: CSSProperties = {};
    if (onlyTwoIcons) {
      style["paddingLeft"] = 5;
      style["paddingRight"] = 14;
    }
    if (iconStyle) {
      return { ...style, ...rightIconStyle };
    }
    return style;
  }, [onlyTwoIcons, rightIconStyle]);

  const handleMouseEnter = useCallback(() => {
    if (tooltip) {
      setShowTooltip(true);
    }
  }, [tooltip]);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const handleMouseUp = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const finalContentStyle = useMemo(() => {
    const style = { ...contentStyle };
    if (color) {
      style["--color"] = color;
    }
    if (hover) {
      style["--hover"] = hover;
    }
    return style;
  }, [color, hover, contentStyle]);

  useEffect(() => {
    if (showTooltip && tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(buttonRef.current);
    }
  }, [showTooltip, tooltip]);

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return React.createElement(
    typeof link !== "undefined"
      ? external && typeof link !== "function"
        ? "a"
        : Link
      : "div",
    {
      className: clsx(
        cp("button"),
        cm(cp("button"), {
          disabled,
          clickable: disabled && activeOnDisabled,
          big,
          primary,
          fill,
          minimal,
          external,
          padded,
          new: newStyle,
          dark: dark,
          black: black,
          light: light,
          gray: gray,
          narrow: narrow,
          transparent: transparent,
          danger: danger,
          "align-left": align === "left",
          "align-right": align === "right",
          "align-center": align === "center",
        }),
        className
      ),
      to: link,
      href: typeof link === "string" ? link : undefined,
      replace: replace,
      style: props.style,
      onClick: handleClick,
      onMouseDown: props.onMouseDown,
      ...(external
        ? {
            target: "_blank",
            rel: "noopener noreferrer",
          }
        : {}),
      ...restProps,
    },
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onPointerEnter={handleMouseEnter}
      onPointerLeave={handleMouseLeave}
    >
      {tooltip && showTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <button
        ref={useCombinedRef(ref, buttonRef)}
        className={cp("button__content")}
        style={finalContentStyle}
        type={type ?? "button"}
        disabled={disabled}
      >
        {icon || iconSrc ? (
          <span
            className={clsx(cp("button__icon"), cp("button__icon-left"))}
            style={finalIconStyle}
          >
            <Icon
              icon={icon as any}
              src={iconSrc}
              spin={spin}
              style={{ fontSize: iconSize }}
            />
          </span>
        ) : null}
        {title || children ? (
          <span className={cp("button__title")}>
            <span
              className={cp("button__title-text")}
              {...(title
                ? { dangerouslySetInnerHTML: { __html: title } }
                : null)}
            >
              {children ? children : null}
            </span>
          </span>
        ) : null}
        {rightIcon ? (
          <span
            className={clsx(cp("button__icon"), cp("button__icon-right"))}
            style={finalRightIconStyle}
          >
            <Icon
              icon={rightIcon as any}
              spin={rightSpin}
              style={{ fontSize: rightIconSize }}
            />
          </span>
        ) : null}
        {external ? (
          <span className={cp("button__external-icon")}>
            <Icon icon={["ext-link"]} />
          </span>
        ) : null}
      </button>
    </div>
  );
});

Button.defaultProps = {
  newStyle: true,
};

export default Button;
