import { useTranslation } from "@adv-libs/l10n";
import {
  KeyCode,
  makeid,
  stringToDate,
  useCombinedRef,
  useIsMobile,
} from "@adv-libs/utils";
import { addMonths, format as dnsDateFormat } from "date-fns";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  default as DatePicker,
  default as ReactDatePicker,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { findDOMNode } from "react-dom";
import { Portal } from "react-overlays";
import AdvControlLabel from "../AdvControlLabel";
import StartIcon from "../AdvInput/StartIcon";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { Button } from "../Button";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import { registerDatePicker, unregisterDatePicker } from "./datePickerRegister";
import { __getGlobalLocale } from "./globalState";
import useAutoWidthMeasure from "./useAutoWidthMeasure";

export interface AdvMonthPickerProps extends AdvCommonControlProps {
  format?: string;
  displayFormat?: string;
  isClearable?: boolean;
  fieldName?: string;
  defaultStartTime?: string;
  defaultEndTime?: string;
  showIcon?: boolean;
  height?: number;
  minWidth?: number;
  autoWidth?: boolean;
}

const name = "month";

const AdvMonthPicker = React.forwardRef<
  ReactDatePicker,
  React.PropsWithChildren<AdvMonthPickerProps>
>((props, ref) => {
  const datePickerRef = useRef<any>();
  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const blurByTabRef = useRef(false);
  const refocusRef = useRef(false);
  const startIconClickRef = useRef(false);

  const { pt } = useTranslation();

  const isMobile = useIsMobile();
  const isMobile400 = useIsMobile(500);

  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  const format = props.format ?? "yyyy-MM-01";
  const displayFormat = props.displayFormat ?? "yyyy LLLL";

  const date = useMemo(() => {
    if (props.value) {
      return stringToDate(props.value, format, false);
    } else {
      return null;
    }
  }, [props.value]);

  const id = useMemo(() => {
    return makeid(5);
  }, []);

  const deferredCalendarClose = useCallback(() => {
    setTimeout(() => {
      setCalendarIsOpen(false);
    }, 0);
  }, []);

  const getInputComponent = useCallback(() => {
    const $datePicker = findDOMNode(datePickerRef.current) as HTMLDivElement;
    const $input = $datePicker.querySelector("input");
    return $input;
  }, []);

  const refocus = useCallback(() => {
    refocusRef.current = true;
    const $input = getInputComponent();
    $input.focus();
    onFocus();
  }, [getInputComponent]);

  const inputIsFocused = useCallback(() => {
    const $input = getInputComponent();
    return document.activeElement === $input;
  }, [getInputComponent]);

  const handleChange = useCallback(
    (date: Date) => {
      props.onCommit(dnsDateFormat(date, format));

      deferredCalendarClose();

      setTimeout(() => {
        refocus();
      }, 0);
    },
    [
      props.onCommit,
      inputIsFocused,
      calendarIsOpen,
      deferredCalendarClose,
      refocus,
      isMobile,
    ]
  );

  // const handleMobileCommit = useCallback(() => {
  //   if (startDateValueRef.current && endDateValueRef.current) {
  //     handleCommit();
  //     deferredCalendarClose();
  //   } else if (startDateValueRef.current && !endDateValueRef.current) {
  //     endDateValueRef.current = startDateValueRef.current;
  //     handleCommit();
  //     deferredCalendarClose();
  //   } else if (!startDateValueRef.current && !endDateValueRef.current) {
  //     deferredCalendarClose();
  //   }
  // }, [handleCommit, deferredCalendarClose]);

  const handleCancelCalendar = useCallback(() => {
    deferredCalendarClose();
  }, []);

  // const handleCalendarClose = useCallback(() => {
  //   if (
  //     !clickOutsideRef.current &&
  //     !blurByTabRef.current &&
  //     !clearButtonRef.current &&
  //     !isMobile
  //   ) {
  //     refocus();
  //   }
  //   clearButtonRef.current = false;
  //   clickOutsideRef.current = false;
  //   blurByTabRef.current = false;
  // }, [datesValue, isMobile, props.onCommit, refocus]);

  const hasValue = !!props.value;

  const { onBlur, onFocus, style, className } = useControl({
    name,
    value: props.value,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    disabled: props.disabled,
    readOnly: props.readOnly,
    className: props.className,
    notify: props.notify,
    autocomplete: props.autocomplete,
    height: props.height,
  });

  const handleMouseEnter = useCallback((e) => {
    if (e.target.closest(".react-datepicker")) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, props.tooltip, hasValue, focused, calendarIsOpen]);

  const handleCalendarClickOutside = useCallback(
    (e) => {
      if (isMobile) return;
      // Skip if clicked on input
      const $parent = findDOMNode(datePickerRef.current);
      if (e && $parent.contains(e.target)) {
        return;
      }

      deferredCalendarClose();
      onBlur(e);
    },
    [isMobile, onBlur, handleChange, inputIsFocused, deferredCalendarClose]
  );

  const handleKeyDown = useCallback(() => {
    const preSelectionDate = datePickerRef.current.state.preSelection;
    datePickerRef.current.setPreSelection(addMonths(preSelectionDate, 3));
  }, []);

  const handleKeyUp = useCallback(() => {
    const preSelectionDate = datePickerRef.current.state.preSelection;
    datePickerRef.current.setPreSelection(addMonths(preSelectionDate, -3));
  }, []);

  const handleKeyRight = useCallback(() => {
    const preSelectionDate = datePickerRef.current.state.preSelection;
    datePickerRef.current.setPreSelection(addMonths(preSelectionDate, 1));
  }, []);

  const handleKeyLeft = useCallback(() => {
    const preSelectionDate = datePickerRef.current.state.preSelection;
    datePickerRef.current.setPreSelection(addMonths(preSelectionDate, -1));
  }, []);

  const handleFakeInputKeyDown = useCallback(
    (e) => {
      if (e.keyCode === KeyCode.KEY_RETURN) {
        e.preventDefault();
        if (inputIsFocused()) {
          if (calendarIsOpen) {
            const preSelectionDate = datePickerRef.current.state.preSelection;
            handleChange(preSelectionDate);
          } else {
            e.preventDefault();
            e.stopPropagation();
            setCalendarIsOpen(true);
          }
        }
      } else if (e.keyCode === KeyCode.KEY_TAB) {
        blurByTabRef.current = true;
        deferredCalendarClose();
      } else if (e.keyCode === KeyCode.KEY_ESCAPE) {
        e.preventDefault();
        e.stopPropagation();
        deferredCalendarClose();
      } else if (
        e.keyCode === KeyCode.KEY_DOWN ||
        e.keyCode === KeyCode.KEY_UP ||
        e.keyCode === KeyCode.KEY_RIGHT ||
        e.keyCode === KeyCode.KEY_LEFT
      ) {
        if (!calendarIsOpen) {
          e.preventDefault();
          e.stopPropagation();
          setCalendarIsOpen(true);
        } else if (e.keyCode === KeyCode.KEY_DOWN) {
          handleKeyDown();
        } else if (e.keyCode === KeyCode.KEY_UP) {
          handleKeyUp();
        } else if (e.keyCode === KeyCode.KEY_RIGHT) {
          handleKeyRight();
        } else if (e.keyCode === KeyCode.KEY_LEFT) {
          handleKeyLeft();
        }
      }
    },
    [
      deferredCalendarClose,
      handleChange,
      refocus,
      inputIsFocused,
      calendarIsOpen,
      handleChange,
      onBlur,
      handleKeyDown,
      handleKeyUp,
      handleKeyRight,
      handleKeyLeft,
    ]
  );

  const handleCalendarInputClick = useCallback(() => {
    if (props.disabled || props.readOnly) return;
    if (!startIconClickRef.current) {
      setCalendarIsOpen(true);
    }
    startIconClickRef.current = false;
  }, [props.disabled, props.readOnly]);

  const handleFocus = useCallback(
    (e) => {
      if (
        !refocusRef.current &&
        !startIconClickRef.current &&
        !calendarIsOpen
      ) {
        setCalendarIsOpen(true);
      }
      startIconClickRef.current = false;
      refocusRef.current = false;
      blurByTabRef.current = false;
      return onFocus(e);
    },
    [onFocus, calendarIsOpen, isMobile]
  );

  const handleBlur = useCallback(
    (e) => {
      if (!calendarIsOpen || blurByTabRef.current) {
        refocusRef.current = false;
        blurByTabRef.current = false;
        return onBlur(e);
      }
    },
    [onBlur, calendarIsOpen]
  );

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();
      startIconClickRef.current = true;

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value, isMobile]
  );

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);
      setCalendarIsOpen(false);
      setShowTooltip(false);
      datePickerRef.current.setBlur(false);
      onBlur(e);
    },
    [props.onCommit, onBlur, refocus]
  );

  useEffect(() => {
    registerDatePicker(id, {
      close: handleCalendarClickOutside,
    });
    return () => {
      unregisterDatePicker(id);
    };
  }, [id, handleCalendarClickOutside]);

  useAutoWidthMeasure({
    autoWidth: props.autoWidth,
    minWidth: props.minWidth,
    controlRef: controlRef,
    hasValue: !!props.value,
    labelRef: labelRef,
    value: props.value,
    label: props.label,
  });

  const handleFocused = useCallback(() => {
    if (isMobile) return;
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip, isMobile]);

  const handleBlurred = useCallback(() => {
    if (isMobile) return;
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip, isMobile]);

  useEffect(() => {
    // Workaround for https://github.com/Hacker0x01/react-datepicker/issues/2832
    if (datePickerRef.current) {
      if (!datePickerRef.current.calendar) {
        datePickerRef.current.calendar = {
          componentNode: document.createElement("div"),
        };
      }
    }
  });

  return (
    <>
      {props.tooltip && finalShowTooltip && !calendarIsOpen ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        onClick={handleCalendarInputClick}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        style={style}
        ref={controlRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.showIcon && !props.startIcon ? (
          <StartIcon
            startIcon={"calendar"}
            onStartIconClick
            startIconActive={calendarIsOpen}
          />
        ) : null}
        <label onBlur={handleBlur}>
          <div className={cp(`control__indicator-container`)}>
            {props.readOnly || props.disabled ? null : props.isClearable &&
              hasValue ? (
              <div
                className={cp(`control__indicator-clear`)}
                onMouseDown={handleClearButtonClick}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  focusable="false"
                >
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                </svg>
              </div>
            ) : null}
          </div>
          <DatePicker
            locale={__getGlobalLocale()}
            ref={useCombinedRef(datePickerRef, ref)}
            // Disable tab focus on DatePicker
            tabIndex={props.tabIndex}
            // onChangeRaw={handleChangeRaw}
            onClickOutside={handleCalendarClickOutside}
            open={calendarIsOpen}
            popperPlacement="bottom-start"
            popperContainer={
              isMobile
                ? calendarIsOpen
                  ? AdvDatePickerMobileContainer
                  : undefined
                : AdvDatePickerContainer
            }
            // disabledKeyboardNavigation={isMobile}
            selected={date}
            openToDate={date}
            dateFormat={displayFormat}
            // value={inputValue}
            disabled={props.disabled || props.readOnly}
            readOnly
            // onSelect={handleChange}
            onChange={handleChange}
            onKeyDown={handleFakeInputKeyDown}
            onFocus={handleFocus}
            customInput={<CustomInputComponent />}
            showMonthYearPicker
            showFullMonthYearPicker={!isMobile400}
            todayButton={pt("datepicker", "Current month")}
          >
            {isMobile ? (
              <div className="react-datepicker__actions">
                <Button minimal onClick={handleCancelCalendar}>
                  {pt("datepicker", "Cancel")}
                </Button>
              </div>
            ) : null}
          </DatePicker>
          <AdvControlLabel ref={labelRef} label={props.label} />
        </label>
      </span>
    </>
  );
});

const AdvDatePickerContainer = ({ children }) => {
  const el = document.body;

  return <Portal container={el}>{children}</Portal>;
};

const AdvDatePickerMobileContainer = (props) => {
  const el = document.body;

  return (
    <Portal container={el}>
      <div className="react-datepicker__portal">{props.children}</div>
    </Portal>
  );
};

const CustomInputComponent = forwardRef<any, any>((props, ref) => {
  return <input ref={ref} {...props} />;
});

AdvMonthPicker.defaultProps = {
  autocomplete: false,
  showIcon: true,
};

export default AdvMonthPicker;
