import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const usePopoverWrapper = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.popoverWrapper, [])
  );
};

export default usePopoverWrapper;
