import { Icon } from "@adv-libs/icons";
import { useTranslation } from "@adv-libs/l10n";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AdvToggler } from "../AdvToggler";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";
import AdvActiveButtonDates from "./AdvActiveButtonDates";
import AdvActiveButtonPopover from "./AdvActiveButtonPopover";

interface AdvActiveButtonCommonProps extends AdvCommonControlProps {
  format?: string;
}

interface AdvActiveButtonOnlyActiveProps extends AdvActiveButtonCommonProps {
  dateRange?: false;
  fieldName?: string;
}

interface AdvActiveButtonWithDateRangeProps extends AdvActiveButtonCommonProps {
  dateRange?: true;
  fieldName?: string[];
}

export type AdvActiveButtonProps =
  | AdvActiveButtonOnlyActiveProps
  | AdvActiveButtonWithDateRangeProps;

const name = "active-button";

const AdvActiveButton: React.FC<AdvActiveButtonProps> = (props) => {
  const { t } = useTranslation();

  const datesRef = useRef<any>();
  const [datesOpen, setDatesOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);

  const handleActiveChange = useCallback(
    (value: boolean) => {
      if (props.disabled || props.readOnly) return;
      if (value === false) {
        setDatesOpen(false);
      }
      const currentValue = [...(Array.isArray(props.value) ? props.value : [])];
      currentValue.shift();

      if (props.dateRange) {
        props.onCommit([value, ...currentValue]);
      } else {
        props.onCommit(value);
      }
    },
    [props.onCommit, props.value, props.disabled, props.readOnly]
  );

  const handleChange = useCallback(
    (dates) => {
      if (props.disabled || props.readOnly) return;
      const [startDate, endDate] = dates;
      props.onCommit([
        Array.isArray(props.value) ? props.value[0] : false,
        startDate,
        endDate,
      ]);
    },
    [props.onCommit, props.value, props.disabled, props.readOnly]
  );

  const datesValue = useMemo(() => {
    if (!props.value || !Array.isArray(props.value)) {
      return [null, null];
    }
    const startDate = props.value[1] ?? null;
    const endDate = props.value[2] ?? null;
    return [startDate, endDate];
  }, [props.value]);

  const formattedValue = useMemo(() => {
    if (!props.value || !Array.isArray(props.value)) {
      return "";
    }
    const startDate = props.value[1] ?? null;
    const endDate = props.value[2] ?? null;
    if (startDate && !endDate) {
      return t(`From`) + " " + startDate.trim();
    } else if (!startDate && endDate) {
      return t(`To`) + " " + endDate.trim();
    } else if (startDate && endDate) {
      return `${startDate ? startDate + " - " : ""}${endDate || ""}`.trim();
    } else {
      return null;
    }
  }, [props.value, props.format]);

  const { className } = useControl({
    name,
    value: null,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    disabled: props.disabled,
    notify: props.notify,
    className: props.className,
    autocomplete: props.autocomplete,
  });

  const handleDateRangeClick = useCallback(() => {
    if (props.disabled || props.readOnly) return;
    setDatesOpen((state) => !state);
  }, [datesOpen, props.disabled, props.readOnly]);

  const handleMouseDown = useCallback(
    (e) => {
      // Check if calendars is open
      let isCalendarOpen =
        datesRef.current?.from.current?.isCalendarOpen() ||
        datesRef.current?.to.current?.isCalendarOpen();
      if (isCalendarOpen) {
        return;
      }
      // Check if clicked inside the dates container
      let clickedInside = false;
      let el = e.target;
      while (el) {
        if (el && el.classList?.contains(cp("control-" + name))) {
          clickedInside = true;
          break;
        }
        el = el.parentNode;
      }
      if (datesOpen && !clickedInside) {
        setDatesOpen(false);
      }
    },
    [className, datesOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleMouseDown]);

  const isActive = props.value
    ? props.dateRange
      ? props.value[0]
      : props.value
    : false;

  return (
    <span ref={setReferenceElement} className={className}>
      <div className="active-toggler-container">
        <AdvToggler
          minimal
          value={isActive}
          onCommit={handleActiveChange}
          label={isActive ? t("Active") : t("Inactive")}
          fieldName={
            props.dateRange
              ? props.fieldName
                ? props.fieldName[0]
                : undefined
              : (props.fieldName as string)
          }
          noHeight
        />
      </div>
      {isActive && props.dateRange ? (
        <div className={"active-date-range"}>
          <div
            className="active-date-range-display"
            onClick={handleDateRangeClick}
          >
            <Icon
              icon={["calendar", 2199]}
              className="active-date-range-icon"
            />
            {formattedValue ? (
              <span className="date-range-value">{formattedValue}</span>
            ) : null}
          </div>
          {datesOpen ? (
            <AdvActiveButtonPopover reference={referenceElement}>
              <AdvActiveButtonDates
                ref={datesRef}
                value={datesValue}
                onChange={handleChange}
                fieldName={props.fieldName}
                // onSave={handleDatesSave}
              />
            </AdvActiveButtonPopover>
          ) : null}
        </div>
      ) : null}
    </span>
  );
};

AdvActiveButton.defaultProps = {
  format: "YYYY.MM.DD",
  autocomplete: false,
  dateRange: true,
};

export default AdvActiveButton;
