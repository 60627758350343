import React, { useCallback, useMemo, useState } from "react";
import { useCalendarSharedContext } from "../../CalendarSharedContext";
import getWeeksRanges from "../../utils/getWeeksRanges";
import groupDaysToWeeks from "../../utils/groupDaysToWeeks";
import mapEventsToWeeks from "../../utils/mapEventsToWeeks";
import CalendarMobileBodyWeek from "./CalendarMobileBodyWeek/CalendarMobileBodyWeek";

export interface CalendarMobileBodyWeeksProps {}

const CalendarMobileBodyWeeks: React.FC<CalendarMobileBodyWeeksProps> = (
  props
) => {
  const { days, data } = useCalendarSharedContext();

  const [weekExpanded, setWeekExpanded] = useState(null);

  const handleWeekCloseButtonClick = useCallback(() => {
    setWeekExpanded(null);
  }, []);

  const handleWeekExpandButtonClick = useCallback((weekIndex: number) => {
    setWeekExpanded(weekIndex);
  }, []);

  const weeks = useMemo(() => {
    return groupDaysToWeeks(days);
  }, [days]);

  const weeksRanges = useMemo(() => {
    return getWeeksRanges(weeks);
  }, [weeks]);

  const eventsGroupsByWeek = useMemo(() => {
    return mapEventsToWeeks(data.events, weeksRanges);
  }, [data.events, weeksRanges]);

  return (
    <div className="rivile-calendar-body-weeks">
      {weeks.map((week, weekIndex) => {
        const weekGroups = eventsGroupsByWeek[weekIndex];
        const isExpanded = weekExpanded === weekIndex;

        return (
          <CalendarMobileBodyWeek
            key={weekIndex}
            isExpanded={isExpanded}
            week={week}
            weekGroups={weekGroups}
            weekIndex={weekIndex}
            onWeekClose={handleWeekCloseButtonClick}
            onWeekExpand={handleWeekExpandButtonClick}
          />
        );
      })}
    </div>
  );
};

export default CalendarMobileBodyWeeks;
