import React, { useContext, useMemo } from "react";

export type CalendarTimelineDOMReferencesContextType = {
  calendarRef: React.RefObject<HTMLDivElement>;
  groupsSidebarRef: React.RefObject<HTMLDivElement>;
};

const CalendarTimelineDOMReferencesContext =
  React.createContext<CalendarTimelineDOMReferencesContextType>(null);

export interface CalendarDOMReferencesContextProviderProps {}

const CalendarTimelineDOMReferencesContextProvider: React.FC<
  CalendarDOMReferencesContextProviderProps
> = (props) => {
  const value = useMemo(() => {
    return {
      calendarRef: React.createRef<HTMLDivElement>(),
      groupsSidebarRef: React.createRef<HTMLDivElement>(),
    };
  }, []);

  return (
    <CalendarTimelineDOMReferencesContext.Provider value={value}>
      {props.children}
    </CalendarTimelineDOMReferencesContext.Provider>
  );
};

const useCalendarTimelineDOMReferencesContext = () => {
  return useContext(CalendarTimelineDOMReferencesContext);
};

export {
  CalendarTimelineDOMReferencesContextProvider,
  useCalendarTimelineDOMReferencesContext,
};
