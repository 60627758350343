import { Icon } from "@adv-libs/icons";
import { makeid } from "@adv-libs/utils";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import AdvControlLabel from "../AdvControlLabel";
import useControl from "../useControl";
import { cp } from "../utils";

export type AdvInputPasswordProps = {
  autoComplete?: string;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  label?: string;
  minimal?: boolean;
  onCommit?: (value: string) => void;
  readOnly?: boolean;
  required?: boolean;
  success?: boolean;
  value: string;
  warning?: boolean;
  fieldName?: string;
};

export const AdvInputPassword: React.FC<AdvInputPasswordProps> = (props) => {
  const [showPassword, showPasswordSet] = useState(false);

  const id = useMemo(() => {
    return !props.autoComplete ? makeid(5) : null;
  }, []);

  const fieldName = useMemo(() => {
    return id ? props.fieldName + "$" + id : props.fieldName;
  }, [id, props.fieldName]);

  const { onBlur, onFocus, onAnimationStart, onAnimationEnd, className } =
    useControl({
      name: "input",
      autocomplete: props.autoComplete,
      className: props.className,
      danger: props.danger,
      disabled: !props.readOnly && props.disabled,
      label: props.label,
      readOnly: props.readOnly,
      required: props.required,
      success: props.success,
      value: props.value,
      warning: props.warning,
    });

  return (
    <span className={className} data-field={props.fieldName}>
      <label>
        <input
          value={props.value ? String(props.value) : ""}
          type={showPassword ? "text" : "password"}
          onBlur={onBlur}
          onFocus={onFocus}
          onAnimationStart={onAnimationStart}
          onAnimationEnd={onAnimationEnd}
          name={fieldName}
          {...(props.readOnly && { disabled: true })}
          {...(props.required && { required: true })}
          {...(props.onCommit && {
            onChange: (e) => props.onCommit(e.currentTarget.value),
          })}
          {...(props.autoComplete
            ? { autoComplete: props.autoComplete }
            : { autoComplete: "new-password" })} // TODO look later how to include this in 'useControl'
        />

        <span
          className={clsx(cp("control__end-icon"))}
          style={{ cursor: "pointer" }}
          onClick={() => showPasswordSet(!showPassword)}
        >
          <Icon icon={showPassword ? ["eye", 2202] : ["eye-slash", 2201]} />
        </span>

        <AdvControlLabel label={props.label} />
      </label>
    </span>
  );
};
