import clsx from "clsx";
import React, { CSSProperties, useCallback, useContext, useMemo } from "react";
import cm from "../utils/cm";
import cp from "../utils/cp";
import TabsContext from "./TabsContext";

export interface TabProps {
  id: string;
  label: string;
  onClick?: (e) => any;
  onMouseDown?: (e) => any;
  onContentClick?: (e) => any;
  onContentMouseDown?: (e) => any;
  className?: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  contentRef?: React.MutableRefObject<any>;
}

const Tab = React.forwardRef<HTMLLIElement, React.PropsWithChildren<TabProps>>(
  (
    {
      id,
      label,
      onClick,
      onContentClick,
      onMouseDown,
      onContentMouseDown,
      contentRef,
      children,
      className,
      style,
      contentStyle,
      ...restProps
    },
    ref
  ) => {
    const {
      activeTab,
      onTabClick,
      isContent,
      flushContent,
      fullHeight,
    } = useContext(TabsContext);

    const handleClick = useCallback(
      (e) => {
        onTabClick(id);
        if (typeof onClick === "function") {
          onClick(e);
        }
      },
      [onTabClick, onClick, id]
    );

    const tabContentStyle = useMemo(() => {
      const defaultDisplayProperty =
        contentStyle?.display ?? (fullHeight ? "flex" : "block");
      const finalStyle = {
        ...contentStyle,
        display: id === activeTab ? defaultDisplayProperty : "none",
      };
      return finalStyle;
    }, [contentStyle, activeTab, id]);

    if (isContent) {
      if (flushContent && id !== activeTab) return null;
      return (
        <div
          key={id}
          data-id={id}
          ref={contentRef}
          onClick={onContentClick}
          onMouseDown={onContentMouseDown}
          style={tabContentStyle}
          className={clsx(
            cp("tabs__content-item"),
            cm(cp("tabs__content-item"), {
              active: id === activeTab,
            })
          )}
        >
          {children}
        </div>
      );
    }

    return (
      <li
        ref={ref}
        data-id={id}
        data-testid={"tab|" + id}
        title={label}
        className={clsx(
          cp("tab"),
          cm(cp("tab"), {
            active: activeTab === id,
          }),
          className
        )}
        style={style}
        onClick={handleClick}
        onMouseDown={onMouseDown}
        {...restProps}
      >
        <span className={clsx(cp("tab__label"))}>
          {label}
          <span className={clsx(cp("tab__label__notify"))}></span>
        </span>
      </li>
    );
  }
);

export default Tab;
