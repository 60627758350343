const getWeeksRanges = (weeks: Date[][]) => {
  return weeks.map((week) => {
    return {
      startDate: week[0],
      endDate: week[week.length - 1],
    };
  });
};

export default getWeeksRanges;
