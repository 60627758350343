import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const usIsFirstTimeLoading = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.state.firstTimeLoading, [])
  );
};

export default usIsFirstTimeLoading;
