import React, { memo, useEffect, useMemo } from "react";
import { useCalendarSharedContext } from "../../CalendarSharedContext";
import { useCalendarTimelineContext } from "../../CalendarTimeline/CalendarTimelineContext";
import { useCalendarTimelineGroupsHeightSyncContext } from "../../CalendarTimeline/CalendarTimelineGroupsHeightSyncContext";
import { CalendarEventType, CalendarGroupedEventsType } from "../../types";
import groupEventsByPropertyIfNotOverlaping from "../../utils/groupEventsByPropertyIfNotOverlaping";
import isDateRangesOverlaping from "../../utils/isDateRangesOverlaping";
import CalendarTimelineBodyGrid from "./CalendarTimelineBodyGrid";
import CalendarTimelineBodyGroupsSidebar from "./CalendarTimelineBodyGroupsSidebar/CalendarTimelineBodyGroupsSidebar";

export interface CalendarTimelineBodyContentProps {}

const CalendarTimelineBodyContent: React.FC<CalendarTimelineBodyContentProps> =
  memo((props) => {
    const { data } = useCalendarSharedContext();
    const { startDate, endDate, iteration } = useCalendarTimelineContext();

    const { clearMeasureCache } = useCalendarTimelineGroupsHeightSyncContext();

    const overlapingEventsWithTheMonth = useMemo(() => {
      const result = data.events.filter((event) => {
        return isDateRangesOverlaping(
          startDate,
          endDate,
          event.startDate,
          event.endDate
        );
      });
      return result;
    }, [data.events, startDate, endDate]);

    const groupedEventsObject = useMemo<CalendarGroupedEventsType>(() => {
      const result = groupEventsByPropertyIfNotOverlaping<CalendarEventType>(
        overlapingEventsWithTheMonth,
        data.groups.map((group) => group.id),
        (event) => event.groupId,
        (event) => event.startDate,
        (event) => event.endDate
      );

      return data.groups.map((group) => {
        return {
          group: group,
          groupedEvents: result[group.id],
        };
      });
    }, [data.groups, overlapingEventsWithTheMonth]);

    useEffect(() => {
      clearMeasureCache();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, startDate, endDate, iteration]);

    return (
      <CalendarTimelineBodyContentRender
        groupedEventsObject={groupedEventsObject}
      />
    );
  });

CalendarTimelineBodyContent.displayName = "CalendarTimelineBodyContent";

interface CalendarTimelineBodyContentRenderProps {
  groupedEventsObject: CalendarGroupedEventsType;
}

const CalendarTimelineBodyContentRender: React.FC<CalendarTimelineBodyContentRenderProps> =
  memo((props) => {
    return (
      <div className="rivile-calendar-body-content">
        <CalendarTimelineBodyGroupsSidebar />
        <CalendarTimelineBodyGrid
          groupedEventsObject={props.groupedEventsObject}
        />
      </div>
    );
  });

CalendarTimelineBodyContentRender.displayName =
  "CalendarTimelineBodyContentRender";

export default CalendarTimelineBodyContent;
