import { useMemo } from "react";
import { useCalendarSharedConfig } from "../CalendarSharedConfigContext";

const useSharedCSSProperties = () => {
  const { scrollBarWidth } = useCalendarSharedConfig();

  const cssProperties = useMemo<Record<string, any>>(() => {
    return {
      "--rivile-calendar-scrollbar-width": scrollBarWidth + "px",
    };
  }, [scrollBarWidth]);

  return cssProperties;
};

export default useSharedCSSProperties;
