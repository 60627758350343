import { useMemo } from "react";
import { CalendarWeekStartsOnType } from "../types";

const useWeekStartsOn = () => {
  const weekStartsOn = useMemo<CalendarWeekStartsOnType>(() => {
    return 1; // Monday
  }, []);

  return weekStartsOn;
};

export default useWeekStartsOn;
