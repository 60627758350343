import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useTabIndex = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.tabIndex, [])
  );
};

export default useTabIndex;
