import React from "react";
import { CalendarEventType, CalendarGroupType } from "../../types";
import CalendarTimelineBodyEvent from "./CalendarTimelineBodyEvent";

export interface CalendarTimelineBodyEventsGroupProps {
  events: CalendarEventType[];
  group: CalendarGroupType;
  startDate: Date;
  endDate: Date;
}

const CalendarTimelineBodyEventsGroup: React.FC<
  CalendarTimelineBodyEventsGroupProps
> = (props) => {
  return (
    <div className="rivile-calendar-body-events-group">
      {props.events.map((event, index) => {
        if (event.id === null) {
          return <React.Fragment key={index}>{null}</React.Fragment>;
        }
        return (
          <CalendarTimelineBodyEvent
            key={event.id}
            event={event}
            group={props.group}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        );
      })}
    </div>
  );
};

export default CalendarTimelineBodyEventsGroup;
