import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { useCalendarSharedConfig } from "../../../CalendarSharedConfigContext";
import { useCalendarSharedContext } from "../../../CalendarSharedContext";
import { CalendarEventInWeekType } from "../../../types";
import getEndDateDaysOffset from "../../../utils/getEndDateDaysOffset";
import getStartDateDaysOffset from "../../../utils/getStartDateDaysOffset";

export interface CalendarMobileBodyWeekEventProps {
  weekStart: Date;
  weekEnd: Date;
  weekEvent: CalendarEventInWeekType;
}

const CalendarMobileBodyWeekEvent: React.FC<
  CalendarMobileBodyWeekEventProps
> = (props) => {
  const { weekEvent, weekStart, weekEnd } = props;

  const {
    onEventClick,
    allowEventClick,
    eventTitle,
    eventStyle,
    data,
    groupsMap,
  } = useCalendarSharedContext();

  const sharedConfig = useCalendarSharedConfig();

  const startOffset = getStartDateDaysOffset(
    weekStart,
    weekEvent.eventStartInWeek,
    true
  );

  const endOffset = getEndDateDaysOffset(
    weekEnd,
    weekEvent.eventEndInWeek,
    true
  );

  const startWeekOffset = getStartDateDaysOffset(
    weekStart,
    weekEvent.event.startDate
  );

  const endWeekOffset = getEndDateDaysOffset(weekEnd, weekEvent.event.endDate);

  const isAllowedEventClick =
    typeof allowEventClick === "function"
      ? allowEventClick(weekEvent.event)
      : typeof onEventClick === "function";

  const handleEventClick = useCallback(() => {
    if (isAllowedEventClick && typeof onEventClick === "function") {
      onEventClick(weekEvent.event);
    }
  }, [weekEvent.event, isAllowedEventClick, onEventClick]);

  const title = eventTitle
    ? eventTitle(weekEvent.event)
    : weekEvent.event.title;

  const group = groupsMap[weekEvent.event.groupId];

  const eventStyles = useMemo(() => {
    // @TODO think about deep merge
    const customEventStyles: Record<string, any> = eventStyle
      ? eventStyle(weekEvent.event, group) || {}
      : {};

    return {
      eventStyle: {
        ...sharedConfig.defaultEventStyle.eventStyle,
        ...customEventStyles.eventStyle,
      },
      eventBackgroundStyle: {
        ...sharedConfig.defaultEventStyle.eventBackgroundStyle,
        ...customEventStyles.eventBackgroundStyle,
      },
      eventContentStyle: {
        ...sharedConfig.defaultEventStyle.eventContentStyle,
        ...customEventStyles.eventContentStyle,
      },
    };
  }, [eventStyle, weekEvent.event, group, sharedConfig]);

  return (
    <div
      className={clsx(
        "rivile-calendar-body-week-event",
        isAllowedEventClick && "clickable"
      )}
      style={
        {
          ...eventStyles.eventStyle,
          "--start-offset": startOffset,
          "--end-offset": endOffset,
          "--start-overlap": startWeekOffset < 0 ? 1 : 0,
          "--end-overlap": endWeekOffset < 0 ? 1 : 0,
        } as any
      }
      onClick={handleEventClick}
    >
      {weekEvent.event.mask ? (
        <div className="rivile-calendar-body-week-event-mask"></div>
      ) : null}
      <div
        className="rivile-calendar-body-week-event-background"
        style={eventStyles.eventBackgroundStyle}
      ></div>
      <div
        className="rivile-calendar-body-week-event-content"
        style={eventStyles.eventContentStyle}
      >
        {title}
      </div>
    </div>
  );
};

export default CalendarMobileBodyWeekEvent;
