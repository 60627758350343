const getWeekDayName = (
  date: Date,
  weekStartsOn: number,
  weekDayNames: string[]
) => {
  const weekDayIndex = date.getDay();
  const weekDayIndexWithOffset = weekDayIndex - weekStartsOn;
  const weekDayIndexWithOffsetNormalized =
    weekDayIndexWithOffset < 0
      ? weekDayIndexWithOffset + 7
      : weekDayIndexWithOffset;
  return weekDayNames[weekDayIndexWithOffsetNormalized];
};

export default getWeekDayName;
