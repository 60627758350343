import clsx from "clsx";
import cp from "./cp";

export type CGridColSize =
  | number
  | Record<string, number | "auto" | string>
  | "auto";
export type COrder = number | Record<string, number> | string;

const cgrid = (size?: CGridColSize) => {
  if (typeof size === "undefined" || size === null) return cp("col");
  if (typeof size === "number") {
    return cp("col-" + size);
  } else if (size === "auto") {
    return cp("col-auto");
  } else {
    return clsx(
      Object.keys(size).reduce((list, breakpoint) => {
        return [...list, cp("col-" + breakpoint + "-" + size[breakpoint])];
      }, [])
    );
  }
};

const corder = (order?: COrder) => {
  if (typeof order === "undefined") return null;
  if (typeof order === "number") {
    return cp("order-" + order);
  } else {
    return clsx(
      Object.keys(order).reduce((list, breakpoint) => {
        return [...list, cp("order-" + breakpoint + "-" + order[breakpoint])];
      }, [])
    );
  }
};

export { corder };

export default cgrid;
