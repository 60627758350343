import React from "react";
import {
  ToastContainer as ReactToastContainer,
  ToastContainerProps,
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultTransition from "./DefaultTransition";
import ToastCloseButton from "./ToastCloseButton";
export type { ToastOptions } from "react-toastify";

export interface ToastProps extends ToastContainerProps {}

const ToastContainer: React.FC<ToastProps> = ({
  className,
  bodyClassName,
  toastClassName,
  progressClassName,
  ...restProps
}) => {
  return (
    <ReactToastContainer
      // className={clsx(cp("toast-container"), className)}
      // bodyClassName={clsx(cp("toast-body"), bodyClassName)}
      // progressClassName={clsx(cp("toast-progress"), progressClassName)}
      // toastClassName={clsx(cp("toast"), toastClassName)}
      transition={DefaultTransition}
      hideProgressBar
      closeOnClick={false}
      autoClose={false}
      closeButton={ToastCloseButton}
      draggable={false}
      newestOnTop
      limit={1}
      {...restProps}
    />
  );
};

export default ToastContainer;
