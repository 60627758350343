import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useItemManipulation = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.itemManipulation, [])
  );
};

export default useItemManipulation;
