import React, { useEffect, useRef } from "react";
import { cp } from "../utils";
import toast from "./toast";

export interface ToastProgressBarProps {
  duration: number;
}

const ToastProgressBar: React.FC<ToastProgressBarProps> = (props) => {
  const barRef = useRef<HTMLDivElement>();
  const animationRef = useRef<boolean>();
  const startTime = useRef<number>(Date.now());

  const handleAnimation = () => {
    const deltaTime = Date.now() - startTime.current;
    const fraction = Math.min(deltaTime / props.duration, 1);
    if (barRef.current) {
      barRef.current.style["transform"] = `scaleX(${1 - fraction})`;
    }
    if (fraction >= 1) {
      toast.dismiss();
      animationRef.current = false;
    } else if (animationRef.current) {
      requestAnimationFrame(handleAnimation);
    }
  };

  useEffect(() => {
    animationRef.current = true;
    requestAnimationFrame(handleAnimation);
    return () => {
      animationRef.current = false;
    };
  }, []);

  return (
    <div className={cp("toast-progress-bar")}>
      <div ref={barRef}></div>
    </div>
  );
};

export default ToastProgressBar;
