import interpolateJexl from "./interpolateJexl";
import { dot } from "@adv-libs/utils";

const interpolateItem = (
  item: any | any[],
  param?: string | ((item: any) => string)
) => {
  if (Array.isArray(item)) {
    const itemArray = item;
    return itemArray
      .map((item) => {
        if (typeof param === "string" && param.startsWith("jexl:")) {
          return interpolateJexl(item, param);
        } else if (typeof param === "function") {
          return param(item);
        } else if (param) {
          return dot.get(item, param);
        } else {
          return item?.name;
        }
      })
      .join(", ");
  } else {
    if (typeof param === "string" && param.startsWith("jexl:")) {
      return interpolateJexl(item, param);
    } else if (typeof param === "function") {
      return param(item);
    } else if (param) {
      return dot.get(item, param);
    } else {
      return item?.name;
    }
  }
};

export default interpolateItem;
