import clsx from "clsx";
import React from "react";
import { cm, cp } from "../utils";

export interface MenuProps extends React.HTMLAttributes<any> {
  flat?: boolean;
  title?: string;
  minWidth?: any;
}

const Menu: React.FC<MenuProps> = ({
  flat,
  title,
  children,
  minWidth,
  ...props
}) => {
  return (
    <div
      className={clsx(cp("menu"), cm(cp("menu"), { flat }))}
      {...props}
      style={{ minWidth }}
    >
      {title ? <div className={clsx(cp("menu__title"))}>{title}</div> : null}
      {children}
    </div>
  );
};

export default Menu;
