import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import React, { useCallback } from "react";
import { cm, cp } from "../utils";

export interface ExpandButtonProps {
  expanded?: boolean;
  onChange?: (expanded: boolean) => any;
  disabled?: boolean;
  danger?: boolean;
  loading?: boolean;
  align?: "left" | "center" | "right";
}

const ExpandButton: React.FC<ExpandButtonProps> = (props) => {
  const handleChange = useCallback(
    (e) => {
      if (typeof props.onChange === "function" && !props.loading) {
        props.onChange(e.target.checked);
      }
    },
    [props.onChange, props.loading]
  );

  return (
    <label
      className={clsx(
        cp("expand-button"),
        cp("expand-button--" + props.align),
        cm(cp("expand-button"), {
          disabled: props.disabled,
          danger: props.danger,
          loading: props.loading,
        })
      )}
    >
      <input
        type="checkbox"
        checked={props.expanded === true}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <div className={cp("expand-button__indicator")}>
        {props.loading ? (
          <span className="loading-icon">
            <Icon icon={["spin", 2200]} spin />
          </span>
        ) : null}
      </div>
    </label>
  );
};

ExpandButton.defaultProps = {
  expanded: false,
  align: "left",
};

export default ExpandButton;
