import React, { memo, useCallback, useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import {
  AutoSizer,
  CellMeasurer,
  Index,
  List,
} from "react-virtualized/dist/commonjs";
import { useCalendarTimelineGroupsHeightSyncContext } from "../../../CalendarTimeline/CalendarTimelineGroupsHeightSyncContext";
import { useCalendarSharedContext } from "../../../CalendarSharedContext";
import { useCalendarTimelineContext } from "../../../CalendarTimeline/CalendarTimelineContext";
import { useCalendarTimelineScrollableContext } from "../../../CalendarTimeline/CalendarTimelineScrollableContext";
import { CalendarGroupType } from "../../../types";
import CalendarTimelineBodyGroup from "./CalendarTimelineBodyGroup";

export interface CalendarTimelineBodyGroupsProps {
  groups: CalendarGroupType[];
}

const CalendarTimelineBodyGroups: React.FC<CalendarTimelineBodyGroupsProps> =
  memo((props) => {
    const { sidebarRef } = useCalendarTimelineScrollableContext();
    const { data } = useCalendarSharedContext();
    const { startDate, endDate } = useCalendarTimelineContext();
    const listRef = useRef<List>(null);

    const { sidebarGroupsMeasureCache, getMaxRowHeight } =
      useCalendarTimelineGroupsHeightSyncContext();

    const rowRerender = useCallback(
      ({ key, index, style, parent }) => {
        const group = props.groups[index];

        return (
          <CellMeasurer
            key={key}
            cache={sidebarGroupsMeasureCache}
            rowIndex={index}
            parent={parent}
          >
            {({ registerChild }) => {
              return (
                <div style={style} data-index={index}>
                  <CalendarTimelineBodyGroup
                    group={group}
                    registerChild={registerChild}
                  />
                </div>
              );
            }}
          </CellMeasurer>
        );
      },
      [props.groups, sidebarGroupsMeasureCache]
    );

    const rowMeasure = useCallback(
      (rowIndex: Index) => {
        return getMaxRowHeight(rowIndex.index);
      },
      [getMaxRowHeight]
    );

    const handleScroll = useCallback(() => {
      requestAnimationFrame(() => {
        if (listRef.current) {
          listRef.current.recomputeRowHeights();
        }
      });
    }, []);

    useEffect(() => {
      listRef.current.forceUpdate();
      listRef.current.recomputeRowHeights();
    }, [startDate, endDate, data]);

    useEffect(() => {
      if (listRef.current) {
        listRef.current.scrollToRow(0);
      }
    }, [startDate, endDate]);

    return (
      <div className="rivile-calendar-body-groups">
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                ref={(node) => {
                  (sidebarRef as any).current = findDOMNode(node);
                  listRef.current = node;
                }}
                width={width}
                height={height}
                rowCount={props.groups.length}
                deferredMeasurementCache={sidebarGroupsMeasureCache}
                onScroll={handleScroll}
                rowHeight={rowMeasure}
                rowRenderer={rowRerender}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  });

export default CalendarTimelineBodyGroups;
