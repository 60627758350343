import { useCallback } from "react";
import { AdvSelectCommonProps } from "../../types";

export interface UseCreateReloadItemOptions {
  getItems: AdvSelectCommonProps["getItems"];
  multiple: AdvSelectCommonProps["multiple"];
  paginated: AdvSelectCommonProps["paginated"];
  onCommit: AdvSelectCommonProps["onCommit"];
}

const useCreateReloadItem = (options: UseCreateReloadItemOptions) => {
  const reloadItem = useCallback(
    async (id, updateSilent: boolean, ignoreDiff: boolean = true) => {
      if (
        !options.multiple &&
        typeof options.getItems === "function" &&
        options.paginated
      ) {
        // Try to load item
        const result = await options.getItems({
          query: null,
          ids: [id],
          size: 1,
          page: 0,
        });
        // Expect paginated result
        if ("content" in result && result.content[0]) {
          options.onCommit(result.content[0] as any, {
            silent: updateSilent,
            ignoreDiff: ignoreDiff,
          });
        }
      }
    },
    [options.getItems, options.paginated, options.multiple, options.onCommit]
  );

  return reloadItem;
};

export default useCreateReloadItem;
