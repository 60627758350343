import { eachDayOfInterval } from "date-fns";
import React, { memo } from "react";
import { CalendarSharedConfigContextProvider } from "../CalendarSharedConfigContext";
import { CalendarSharedContextProvider } from "../CalendarSharedContext";
import useWeekDayNames from "../hooks/useWeekDayNames";
import useWeekStartsOn from "../hooks/useWeekStartsOn";
import "../style";
import { CalendarGroupType, CalendarSharedProps } from "../types";
import { CalendarTimelineConfigContextProvider } from "./CalendarTimelineConfigContext";
import { CalendarTimelineContextProvider } from "./CalendarTimelineContext";
import CalendarTimelineCore from "./CalendarTimelineCore";
import { CalendarTimelineDOMReferencesContextProvider } from "./CalendarTimelineDOMReferencesContext";
import { CalendarTimelineViewsContextProvider } from "./CalendarTimelineViewsContext";

interface CalendarTimelineProps extends CalendarSharedProps {
  startDate: Date;
  endDate: Date;
  showMonthsHeader?: boolean;
  groupView?: React.ComponentType<any>;
  groupEventsOverlay?: React.ComponentType<any>;
  groupStyle?: (group: CalendarGroupType) => React.CSSProperties;
  cellView?: React.ComponentType<any>;
  eventView?: React.ComponentType<any>;
  minCellWidth?: number;
  minGroupsWidth?: number;
  defaultGroupsWidth?: number;
  tooltip?: boolean;
}

const CalendarTimeline: React.FC<CalendarTimelineProps> = memo((props) => {
  const weekStartsOn = useWeekStartsOn();
  const weekDayNames = useWeekDayNames();

  const days = eachDayOfInterval({
    start: props.startDate,
    end: props.endDate,
  });

  return (
    <CalendarSharedContextProvider
      isLoading={props.isLoading}
      data={props.data}
      days={days}
      weekStartsOn={weekStartsOn}
      weekDayNames={weekDayNames}
      onDayClick={props.onDayClick}
      onEventClick={props.onEventClick}
      allowEventClick={props.allowEventClick}
      eventTitle={props.eventTitle}
      eventStyle={props.eventStyle}
      tooltip={props.tooltip}
    >
      <CalendarTimelineContextProvider
        startDate={props.startDate}
        endDate={props.endDate}
        showMonthsHeader={props.showMonthsHeader}
        groupStyle={props.groupStyle}
      >
        <CalendarSharedConfigContextProvider
          scrollBarWidth={props.scrollBarWidth}
          defaultEventStyle={props.defaultEventStyle}
        >
          <CalendarTimelineConfigContextProvider
            minCellWidth={props.minCellWidth}
            minGroupsWidth={props.minGroupsWidth}
            defaultGroupsWidth={props.defaultGroupsWidth}
          >
            <CalendarTimelineViewsContextProvider
              groupView={props.groupView}
              groupEventsOverlay={props.groupEventsOverlay}
              cellView={props.cellView}
              eventView={props.eventView}
            >
              <CalendarTimelineDOMReferencesContextProvider>
                <CalendarTimelineCore />
              </CalendarTimelineDOMReferencesContextProvider>
            </CalendarTimelineViewsContextProvider>
          </CalendarTimelineConfigContextProvider>
        </CalendarSharedConfigContextProvider>
      </CalendarTimelineContextProvider>
    </CalendarSharedContextProvider>
  );
});

export default CalendarTimeline;
