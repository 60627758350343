import { Icon } from "@adv-libs/icons";
import { useIsMobile } from "@adv-libs/utils";
import clsx from "clsx";
import React, { useMemo } from "react";
import { defaultStatusName } from "./constants";
import {
  addStatusTemplate,
  clearStatusTemplates,
  getStatusTemplate,
} from "./templatesRegister";
import { AdvStatusDefinition } from "./types";
import { formatName } from "./utils";

export interface AdvStaticStatusProps extends AdvStatusDefinition {
  fill?: boolean;
  narrow?: boolean;
  responsive?: number | boolean;
  iconSize?: number;
}

const getTemplateStyle = (
  status: { id: any },
  templateName: string = "default"
) => {
  const template = getStatusTemplate(templateName);
  if (template && template[status.id]) {
    return template[status.id];
  } else {
    return null;
  }
};

const AdvStaticStatus: React.FC<AdvStaticStatusProps> & {
  addTemplate: typeof addStatusTemplate;
  clearTemplates: typeof clearStatusTemplates;
  getTemplateStyle: typeof getTemplateStyle;
} = (props) => {
  const style = useMemo(() => {
    const templateName = props.template;
    let style: { color?: string; background?: string; border?: string } = {};
    if (templateName) {
      const template = getStatusTemplate(templateName);
      if (template && template[props.id]) {
        style = template[props.id];
      }
    }
    if (props.color) {
      style.color = props.color;
    }
    if (props.background) {
      style.background = props.background;
    }
    if (props.border) {
      style.border = props.border;
    }
    return style;
  }, [props.name, props.id, props.background, props.color, props.template]);

  const responsiveBreakpoint =
    typeof props.responsive === "boolean" ? 768 : props.responsive;

  const isMobile = useIsMobile(responsiveBreakpoint, !!props.responsive);

  return (
    <div
      className={clsx("r365-status", {
        "r365-status--fill": props.fill,
        "r365-status--narrow": props.narrow,
        "r365-status--mobile": isMobile,
      })}
      style={style}
    >
      {props.icon ? (
        <span
          className="r365-status__icon"
          style={{ fontSize: props.iconSize }}
        >
          <Icon icon={props.icon} />
        </span>
      ) : null}
      <span className="r365-status__label">
        {props.name ? formatName(props.name) : defaultStatusName}
      </span>
    </div>
  );
};

AdvStaticStatus.defaultProps = {
  template: "default",
};

AdvStaticStatus.addTemplate = addStatusTemplate;
AdvStaticStatus.clearTemplates = clearStatusTemplates;

AdvStaticStatus.getTemplateStyle = getTemplateStyle;

export default AdvStaticStatus;
