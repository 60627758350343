import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { useCalendarSharedConfig } from "../../CalendarSharedConfigContext";
import { useCalendarSharedContext } from "../../CalendarSharedContext";
import { CalendarEventType, CalendarGroupType } from "../../types";
import getEndDateDaysOffset from "../../utils/getEndDateDaysOffset";
import getStartDateDaysOffset from "../../utils/getStartDateDaysOffset";
import { useCalendarTimelineViewsContext } from "../../CalendarTimeline/CalendarTimelineViewsContext.js";

export interface CalendarTimelineBodyEventProps {
  event: CalendarEventType;
  group: CalendarGroupType;
  startDate: Date;
  endDate: Date;
}

const CalendarTimelineBodyEvent: React.FC<CalendarTimelineBodyEventProps> = (
  props
) => {
  const { event, group, endDate, startDate } = props;

  const { onEventClick, allowEventClick, eventTitle, eventStyle, tooltip } =
    useCalendarSharedContext();

  const config = useCalendarSharedConfig();

  const { eventView: EventView } = useCalendarTimelineViewsContext();

  const startOffset = getStartDateDaysOffset(startDate, event.startDate, true);
  const endOffset = getEndDateDaysOffset(endDate, event.endDate, true);

  const startMonthOffset = getStartDateDaysOffset(startDate, event.startDate);
  const endMonthOffset = getEndDateDaysOffset(endDate, event.endDate);

  const isAllowedEventClick =
    typeof allowEventClick === "function"
      ? allowEventClick(event)
      : typeof onEventClick === "function";

  const handleEventClick = useCallback(() => {
    if (isAllowedEventClick && typeof onEventClick === "function") {
      onEventClick(event);
    }
  }, [event, isAllowedEventClick, onEventClick]);

  const title = eventTitle ? eventTitle(event) : event.title;

  const eventStyles = useMemo(() => {
    // @TODO think about deep merge
    const customEventStyles: Record<string, any> = eventStyle
      ? eventStyle(event, group) || {}
      : {};

    return {
      eventStyle: {
        ...config.defaultEventStyle.eventStyle,
        ...customEventStyles.eventStyle,
      },
      eventBackgroundStyle: {
        ...config.defaultEventStyle.eventBackgroundStyle,
        ...customEventStyles.eventBackgroundStyle,
      },
      eventContentStyle: {
        ...config.defaultEventStyle.eventContentStyle,
        ...customEventStyles.eventContentStyle,
      },
    };
  }, [eventStyle, event, group, config]);

  const tooltipProps = useMemo(() => {
    const tooltipProps: Record<string, any> = {};
    if (tooltip) {
      tooltipProps["data-tooltip-id"] = "rivile-calendar-tooltip";
      tooltipProps["data-tooltip-place"] = "bottom";
      tooltipProps["data-tooltip-content"] = title;
    }
    return tooltipProps;
  }, [tooltip]);

  return (
    <div
      {...tooltipProps}
      className={clsx(
        "rivile-calendar-body-event",
        isAllowedEventClick && "clickable"
      )}
      onClick={handleEventClick}
      style={
        {
          ...eventStyles.eventStyle,
          "--start-offset": startOffset,
          "--end-offset": endOffset,
          "--start-overlap": startMonthOffset < 0 ? 1 : 0,
          "--end-overlap": endMonthOffset < 0 ? 1 : 0,
        } as any
      }
    >
      {event.mask ? (
        <div className="rivile-calendar-body-event-mask"></div>
      ) : null}
      <div
        className="rivile-calendar-body-event-background"
        style={eventStyles.eventBackgroundStyle}
      ></div>
      <div
        className="rivile-calendar-body-event-content"
        style={eventStyles.eventContentStyle}
      >
        {EventView ? <EventView event={event} group={group} /> : title}
      </div>
    </div>
  );
};

export default CalendarTimelineBodyEvent;
