import clsx from "clsx";
import React, { CSSProperties } from "react";
import cm from "../utils/cm";
import cp from "../utils/cp";

export interface AdvFormGroupProps {
  align?: "left" | "right" | "center" | "space-between" | string;
  style?: CSSProperties;
  border?: boolean;
  title?: string;
  noMargin?: boolean;
  minHeight?: any;
  fullHeight?: boolean;
  onClick?: (e?: any) => any;
}

const AdvFormGroup = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AdvFormGroupProps>
>((props, ref) => {
  return (
    <div
      ref={ref}
      onClick={props.onClick}
      style={{ minHeight: props.minHeight, ...props.style }}
      className={clsx(
        cp("form-group"),
        cm(cp("form-group"), {
          titled: props.title,
          bordered: props.border,
          "full-height": props.fullHeight,
          "align-left": props.align === "left",
          "align-right": props.align === "right",
          "align-center": props.align === "center",
          "align-space-between": props.align === "space-between",
          "no-margin": props.noMargin,
        }),
        cp("wrap")
      )}
    >
      {props.children}
      {props.title ? (
        <span className={cp("form-group__title")}>{props.title}</span>
      ) : null}
    </div>
  );
});

export default AdvFormGroup;
