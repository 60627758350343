import { defined } from "@adv-libs/utils";
import { useCallback, useLayoutEffect, useRef } from "react";
import { AdvSelect2State, AdvSelectCommonProps } from "../../types";

export interface UseSelectByDefaultOptions {
  prependItems: AdvSelectCommonProps["prependItems"];
  items: AdvSelect2State["items"];
  value: AdvSelectCommonProps["value"];
  selectByDefault: AdvSelectCommonProps["selectByDefault"];
  onCommit: AdvSelectCommonProps["onCommit"];
}

const useSelectByDefault = (options: UseSelectByDefaultOptions) => {
  const itemsLoadedInitial = useRef(false);

  const selectByDefault = useCallback(() => {
    const items = [];
    if (Array.isArray(options.prependItems)) {
      items.push(...options.prependItems);
    }
    if (Array.isArray(options.items)) {
      items.push(...options.items);
    }

    if (
      items.length &&
      !itemsLoadedInitial.current &&
      !defined(options.value) &&
      typeof options.selectByDefault === "number"
    ) {
      if (options.selectByDefault < items.length) {
        options.onCommit(items[options.selectByDefault], {
          silent: true,
          ignoreDiff: true,
        });
      }
    }

    if (!itemsLoadedInitial.current) {
      itemsLoadedInitial.current = items.length > 0;
    }
  }, [options.items, options.selectByDefault]);

  useLayoutEffect(() => {
    selectByDefault();
  }, [selectByDefault]);
};

export default useSelectByDefault;
