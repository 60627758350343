import { defined } from "@adv-libs/utils";

const hasValue = (value: any, nullIsValue: boolean = false) => {
  if (nullIsValue && value === null) return true;
  if (!defined(value)) return false;
  if (typeof value === "string") {
    return !!value.length;
  } else if (typeof value === "number") {
    return true;
  } else if (value) {
    return true;
  }
  return false;
};

export default hasValue;
