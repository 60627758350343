import { Icon } from "@adv-libs/icons";
import React from "react";
import { cp } from "../utils";

export interface ToastCloseButtonProps {
  closeToast: () => any;
}

const ToastCloseButton: React.FC<ToastCloseButtonProps> = ({ closeToast }) => {
  return (
    <div className={cp("toast-close-button")} onClick={closeToast}>
      <Icon icon={["close", 1833]} />
    </div>
  );
};

export default ToastCloseButton;
