import { Icon } from "@adv-libs/icons";
import { useTranslation } from "@adv-libs/l10n";
import { defined } from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "../Button";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";

export interface AdvFileUploadProps extends AdvCommonControlProps {
  fieldName?: string;
  accept?: string;
}

export interface AdvFileUploadInstance {
  getFile: () => File | null;
}

const name = "fileUpload";

function eventHasFiles(event: DragEvent | React.DragEvent<HTMLElement>) {
  // In most browsers this is an array, but in IE11 it's an Object :(
  let hasFiles = false;
  if (event.dataTransfer) {
    const { types } = event.dataTransfer;
    // eslint-disable-next-line no-restricted-syntax
    for (const keyOrIndex in types) {
      if (types[keyOrIndex] === "Files") {
        hasFiles = true;
        break;
      }
    }
  }
  return hasFiles;
}

const AdvFileUpload = React.forwardRef<
  AdvFileUploadInstance,
  React.PropsWithChildren<AdvFileUploadProps>
>((props, ref) => {
  const { t } = useTranslation();

  const controlRef = useRef<HTMLDivElement>();

  const value = useMemo(() => {
    return defined(props.value) ? props.value : "";
  }, [props.value]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [chosenFile, setChosenFile] = useState<File | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      getFile: () => chosenFile,
    }),
    [chosenFile]
  );

  const { className } = useControl({
    name,
    value,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    notify: props.notify,
  });

  const chooseFileClickHandler = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const inputFileChangeHandler: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.target.files.length) {
          const currentFile = e.target.files.item(0);
          setChosenFile(currentFile);
          props.onCommit(currentFile.name);
        } else {
          setChosenFile(null);
          props.onCommit(null);
        }
      },
      [props.onCommit]
    );

  const clearChosenFileHandler = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setChosenFile(null);
    props.onCommit(null);
  }, [props.onCommit]);

  const refFrameDragCounter = useRef(0);
  const [draggingOver, setDraggingOver] = useState(false);
  const [draggingOverTarget, setDraggingOverTarget] = useState(false);
  const refDraggingOverTarget = useRef(draggingOverTarget);
  refDraggingOverTarget.current = draggingOverTarget;

  const resetDragging = useCallback(() => {
    refFrameDragCounter.current = 0;
    setDraggingOver(false);
    setDraggingOverTarget(false);
  }, []);

  useEffect(() => {
    function preventDefaults(e: DragEvent) {
      if (eventHasFiles(e)) e.preventDefault();
    }

    function handleFrameDrag(e: DragEvent) {
      if (!eventHasFiles(e)) return;
      refFrameDragCounter.current += e.type === "dragenter" ? 1 : -1;
      if (refFrameDragCounter.current === 1) setDraggingOver(true);
      if (refFrameDragCounter.current === 0) setDraggingOver(false);
    }

    function handleFrameDrop() {
      if (!refDraggingOverTarget.current) resetDragging();
    }

    window.addEventListener("dragover", preventDefaults);
    window.addEventListener("drop", preventDefaults);
    window.document.addEventListener("dragenter", handleFrameDrag);
    window.document.addEventListener("dragleave", handleFrameDrag);
    window.document.addEventListener("drop", handleFrameDrop);
    return () => {
      window.document.removeEventListener("drop", handleFrameDrop);
      window.document.removeEventListener("dragleave", handleFrameDrag);
      window.document.removeEventListener("dragenter", handleFrameDrag);
      window.removeEventListener("drop", preventDefaults);
      window.removeEventListener("dragover", preventDefaults);
    };
  }, [resetDragging]);

  const handleTargetDragOver: React.DragEventHandler<HTMLDivElement> =
    useCallback((event) => {
      if (eventHasFiles(event)) {
        event.dataTransfer.dropEffect = "copy";
        setDraggingOverTarget(true);
      }
    }, []);

  // const handleTargetDragLeave: React.DragEventHandler<HTMLDivElement> = useCallback(() => {
  //   setDraggingOverTarget(false);
  // }, []);

  const handleTargetDrop: React.DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (eventHasFiles(event)) {
        const currentFile = event.dataTransfer.files.item(0);
        setChosenFile(currentFile);
        props.onCommit(currentFile.name);
      }
      resetDragging();
    },
    [resetDragging]
  );

  let fileDropTargetClassName = className; // "file-drop-target";
  if (draggingOver) fileDropTargetClassName += " file-drop-dragging-over";

  return (
    <span
      className={fileDropTargetClassName}
      data-field={props.fieldName}
      data-testid={"field|" + props.fieldName}
      // data-type={name}
      ref={controlRef}
      /** @TODO check after release */
      // {...props.tooltip}
      onDragOver={handleTargetDragOver}
      // onDragLeave={handleTargetDragLeave}
      onDrop={handleTargetDrop}
    >
      <Icon icon={["file-plus", 2148]} style={{ fontSize: 20 }} />
      <label>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={inputFileChangeHandler}
          accept={props.accept}
        />
        {chosenFile ? (
          <span>{chosenFile.name}</span>
        ) : (
          <span>{t("Choose file or drag&drop")}</span>
        )}
      </label>
      {chosenFile ? (
        <Icon
          icon={["times-circle-regular", 1867]}
          onClick={clearChosenFileHandler}
          className="clear-icon"
        />
      ) : (
        <Button
          className="choose-file-button"
          title={t("Choose file")}
          onClick={chooseFileClickHandler}
        />
      )}
    </span>
  );
});

export default AdvFileUpload;
