import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import React, { memo, useMemo } from "react";
import { CalendarSharedConfigContextProvider } from "../CalendarSharedConfigContext";
import { CalendarSharedContextProvider } from "../CalendarSharedContext";
import useWeekDayNames from "../hooks/useWeekDayNames";
import useWeekStartsOn from "../hooks/useWeekStartsOn";
import "../style";
import { CalendarSharedProps } from "../types";
import getFirstMonthDay from "../utils/getFirstMonthDay";
import { CalendarMobileConfigContextProvider } from "./CalendarMobileConfigContext";
import { CalendarMobileContextProvider } from "./CalendarMobileContext";
import CalendarMobileCore from "./CalendarMobileCore";

interface CalendarMobileProps extends CalendarSharedProps {
  onSwipedLeft?: () => any;
  onSwipedRight?: () => any;
  currentYear: number;
  currentMonth: number;
}

const CalendarMobile: React.FC<CalendarMobileProps> = memo((props) => {
  const weekStartsOn = useWeekStartsOn();
  const weekDayNames = useWeekDayNames();

  const firstMonthDay = useMemo(() => {
    return getFirstMonthDay(props.currentYear, props.currentMonth);
  }, [props.currentMonth, props.currentYear]);

  const monthStartDate = useMemo(() => {
    return startOfMonth(firstMonthDay);
  }, [firstMonthDay]);

  const monthEndDate = useMemo(() => {
    return endOfMonth(firstMonthDay);
  }, [firstMonthDay]);

  const startDate = useMemo(() => {
    return startOfWeek(monthStartDate, {
      weekStartsOn: weekStartsOn,
    });
  }, [monthStartDate, weekStartsOn]);

  const endDate = useMemo(() => {
    return endOfWeek(monthEndDate, {
      weekStartsOn: weekStartsOn,
    });
  }, [monthEndDate, weekStartsOn]);

  const days = eachDayOfInterval({ start: startDate, end: endDate });

  return (
    <CalendarSharedContextProvider
      isLoading={props.isLoading}
      data={props.data}
      days={days}
      weekStartsOn={weekStartsOn}
      weekDayNames={weekDayNames}
      onDayClick={props.onDayClick}
      onEventClick={props.onEventClick}
      allowEventClick={props.allowEventClick}
      eventTitle={props.eventTitle}
      eventStyle={props.eventStyle}
    >
      <CalendarMobileContextProvider
        currentMonth={props.currentMonth}
        currentYear={props.currentYear}
        onSwipedLeft={props.onSwipedLeft}
        onSwipedRight={props.onSwipedRight}
      >
        <CalendarSharedConfigContextProvider
          scrollBarWidth={props.scrollBarWidth}
          defaultEventStyle={props.defaultEventStyle}
        >
          <CalendarMobileConfigContextProvider>
            <CalendarMobileCore />
          </CalendarMobileConfigContextProvider>
        </CalendarSharedConfigContextProvider>
      </CalendarMobileContextProvider>
    </CalendarSharedContextProvider>
  );
});

export default CalendarMobile;
