import React, { useContext, useMemo } from "react";

export type CalendarTimelineViewsContextType = {
  groupView: React.ComponentType<any>;
  cellView: React.ComponentType<any>;
  eventView: React.ComponentType<any>;
  groupEventsOverlay: React.ComponentType<any>;
};

const CalendarTimelineViewsContext =
  React.createContext<CalendarTimelineViewsContextType>(null);

export interface CalendarViewsContextProviderProps {
  groupView: React.ComponentType<any>;
  cellView: React.ComponentType<any>;
  eventView: React.ComponentType<any>;
  groupEventsOverlay: React.ComponentType<any>;
}

const CalendarTimelineViewsContextProvider: React.FC<
  CalendarViewsContextProviderProps
> = (props) => {
  const value = useMemo(() => {
    return {
      groupView: props.groupView,
      cellView: props.cellView,
      eventView: props.eventView,
      groupEventsOverlay: props.groupEventsOverlay,
    };
  }, [
    props.groupView,
    props.cellView,
    props.eventView,
    props.groupEventsOverlay,
  ]);

  return (
    <CalendarTimelineViewsContext.Provider value={value}>
      {props.children}
    </CalendarTimelineViewsContext.Provider>
  );
};

const useCalendarTimelineViewsContext = () => {
  return useContext(CalendarTimelineViewsContext);
};

export {
  CalendarTimelineViewsContextProvider,
  useCalendarTimelineViewsContext,
};
