import { Icon } from "@adv-libs/icons";
import React from "react";
import { useCalendarSharedContext } from "./CalendarSharedContext";

export interface CalendarLoadingOverlayProps {}

const CalendarLoadingOverlay: React.FC<CalendarLoadingOverlayProps> = (
  props
) => {
  const { isLoading } = useCalendarSharedContext();

  if (!isLoading) return null;

  return (
    <div className="rivile-calendar-loading-overlay">
      <Icon icon={["spin", 2200]} spin />
    </div>
  );
};

export default CalendarLoadingOverlay;
