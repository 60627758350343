import { usePropRef } from "@adv-libs/utils";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { usePopper } from "react-popper-2";

export interface AdvActiveButtonPopoverProps {
  reference: HTMLElement | null;
}

const offsetModifier: any = {
  name: "offset",
  options: {
    offset: [0, 5],
  },
};

const AdvActiveButtonPopover = React.forwardRef<
  any,
  React.PropsWithChildren<AdvActiveButtonPopoverProps>
>((props, ref) => {
  const [popperElement, setPopperElement] = useState(null);

  const {
    update: popperUpdate,
    styles: popperStyles,
    attributes: popperAttributes,
  } = usePopper(props.reference, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [offsetModifier],
  });

  const animationFrameId = useRef(null);
  const popperUpdateRef = usePropRef(popperUpdate);

  const popperAnimationFrame = useCallback(() => {
    animationFrameId.current = requestAnimationFrame(popperAnimationFrame);
    if (popperUpdateRef.current) {
      popperUpdateRef.current();
    }
  }, []);

  useLayoutEffect(() => {
    popperAnimationFrame();
    return () => {
      cancelAnimationFrame(animationFrameId.current);
    };
  }, []);

  const handleRef = useCallback(
    (node) => {
      setPopperElement(node);
      if (typeof ref === "function") {
        (ref as any)(node);
      }
    },
    [ref]
  );

  return (
    <div
      ref={handleRef}
      tabIndex={-1}
      style={{ ...popperStyles.popper, zIndex: 999 }}
      {...popperAttributes.popper}
    >
      {props.children}
    </div>
  );
});

export default AdvActiveButtonPopover;
