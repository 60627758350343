import { useSecondEffect } from "@adv-libs/utils";
import { useCallback, useEffect, useRef } from "react";
import ccMenu from "../ccMenu";
import { StateActions } from "../hooks/create/useCreateSelectState";
import usePaginated from "../hooks/usePaginated";
import useSelectDispatch from "../hooks/useSelectDispatch";
import useSelectState from "../hooks/useSelectState";
import useValue from "../hooks/useValue";

const DEFAULT_INFINITE_SCROLL_THRESHOLD = 200;

const useMenuScrollHandler = () => {
  const menuToolbarRef = useRef<HTMLDivElement>(null);
  const scrollMenuRef = useRef<HTMLDivElement>(null);

  const paginated = usePaginated();

  const state = useSelectState();
  const dispatch = useSelectDispatch();

  const paginatedSize = paginated ? paginated.size || 30 : null;

  const value = useValue();

  const checkMenuScroll = useCallback(() => {
    /** If select is not paginated, do not check scroll */
    if (!paginated) return;
    /** If menu already reached bottom, do not check */
    if (state.menuReachedBottom) return;

    const scrollHeight = scrollMenuRef.current.scrollHeight;
    const clientHeight = scrollMenuRef.current.clientHeight;
    const scrollTop = scrollMenuRef.current.scrollTop;

    if (
      scrollTop + clientHeight >
      scrollHeight - DEFAULT_INFINITE_SCROLL_THRESHOLD
    ) {
      /** If scrolled to the threshold and more pages exists, trigger next page */
      if (state.totalElements > (state.page + 1) * paginatedSize) {
        dispatch(StateActions.nextPage());
      }
    }
  }, [state.totalElements, state.menuReachedBottom, state.page, paginated]);

  /**
   * Check menu after new items loaded but only if menu is opened
   */
  useEffect(() => {
    if (state.menuIsOpen) {
      checkMenuScroll();
    }
  }, [state.items]);

  /** Check menu after value is changed for R365-8108 */
  useEffect(() => {
    if (state.menuIsOpen) {
      checkMenuScroll();
    }
  }, [value]);

  /**
   * If query changed, scroll to top and reset cursor
   */
  useSecondEffect(() => {
    if (scrollMenuRef.current) {
      scrollMenuRef.current.scrollTop = 0;
      dispatch(StateActions.resetCursor());
    }
  }, [state.query]);

  /**
   * Trigger scroll recalculation when cursor change
   */
  useEffect(() => {
    if (state.menuIsOpen && scrollMenuRef.current) {
      const item = scrollMenuRef.current.querySelector(
        "." + ccMenu("item--highlighted")
      ) as HTMLDivElement;
      if (item) {
        const itemTop =
          item.offsetTop +
          item.offsetHeight / 2 -
          menuToolbarRef.current.clientHeight;

        if (
          itemTop >
          scrollMenuRef.current.clientHeight + scrollMenuRef.current.scrollTop
        ) {
          scrollMenuRef.current.scrollTop =
            scrollMenuRef.current.scrollTop + item.offsetHeight;
        } else if (itemTop <= scrollMenuRef.current.scrollTop) {
          scrollMenuRef.current.scrollTop -= item.offsetHeight;
        }

        // if it is not enough, just scroll into view
        if (
          itemTop >=
            scrollMenuRef.current.clientHeight +
              scrollMenuRef.current.scrollTop ||
          itemTop <= scrollMenuRef.current.scrollTop
        ) {
          item.scrollIntoView();
        }
      }
    }
  }, [state.menuItemCursor]);

  /**
   * Check scroll on scroll event
   */
  const onScroll = useCallback(() => {
    checkMenuScroll();
  }, [checkMenuScroll]);

  return { onScroll, scrollMenuRef, menuToolbarRef };
};

export default useMenuScrollHandler;
