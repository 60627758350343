import { cp } from "../utils";

const name = "select2";

const cc = (className?: string) => {
  if (className) {
    return cp(`control-${name}__${className}`);
  } else {
    return cp(`control-${name}`);
  }
};

export default cc;
