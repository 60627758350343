import { useTranslation } from "@adv-libs/l10n";
import { uploadFile } from "@adv-libs/utils";
import React, { useCallback, useMemo, useState } from "react";
import { Button, ButtonProps } from "../Button";
import { toast } from "../Toast";

export interface AdvUploadButtonProps extends Omit<ButtonProps, "onClick"> {
  onFile: (data: string) => any;
  maxSize?: number;
  accept?: string;
}

const AdvUploadButton: React.FC<AdvUploadButtonProps> = (props) => {
  const { onFile, maxSize, accept, ...restProps } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const result = await uploadFile({ maxSize, accept });
        onFile(result);
      } catch (err) {
        toast.error({ title: t("Error"), description: err.toString() });
      } finally {
        setLoading(false);
      }
    },
    [onFile, maxSize, accept]
  );

  const icon = useMemo<any>(() => {
    if (loading) return ["spin", 2200];
    else return restProps.icon;
  }, [restProps.icon, loading]);

  return (
    <Button
      {...restProps}
      icon={icon}
      disabled={loading || restProps.disabled}
      spin={loading}
      onClick={handleClick}
    />
  );
};

export default AdvUploadButton;
