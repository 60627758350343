import { fuzzyFilter } from "@adv-libs/utils";
import { useMemo } from "react";
import {
  AdvSelect2State,
  AdvSelectCommonProps,
  AdvSelectOption,
  ItemManipulation,
} from "../../types";

export interface UseFilteredItemsOptions {
  items: AdvSelect2State["items"];
  prependItems: AdvSelectCommonProps["prependItems"];
  filter: AdvSelectCommonProps["filter"];
  multiple: AdvSelectCommonProps["multiple"];
  multipleCheckboxes: AdvSelectCommonProps["multipleCheckboxes"];
  singleSelect: AdvSelectCommonProps["singleSelect"];
  detectResponse: AdvSelectCommonProps["detectResponse"];
  itemIsDisabled: AdvSelectCommonProps["itemIsDisabled"];
  isPaginated: boolean;
  value: AdvSelectCommonProps["value"];
  itemManipulation: ItemManipulation;
  debouncedQuery: string;
}

interface GetFinalFilterOptions {
  detectResponse: AdvSelectCommonProps["detectResponse"];
  isPaginated: boolean;
  filter: AdvSelectCommonProps["filter"];
}

const getFinalFilter = (options: GetFinalFilterOptions) => {
  const { detectResponse, isPaginated, filter } = options;

  if (detectResponse && !isPaginated) {
    return filter === "server" ? "fuzzy" : filter;
  }

  return filter;
};

const useCreateFilteredItems = (options: UseFilteredItemsOptions) => {
  const itemManipulation = options.itemManipulation;

  const filteredItems = useMemo(() => {
    let items: AdvSelectOption[] = [...options.items];
    if (options.prependItems) {
      items = [...options.prependItems, ...items];
    }

    const clientSideFilter = getFinalFilter(options);

    /** Filter with client side filter */
    if (clientSideFilter !== "server" && options.debouncedQuery) {
      if (clientSideFilter === "equals") {
        items = items.filter((item) => {
          return itemManipulation
            .getItemLabel(item)
            .toLowerCase()
            .startsWith(options.debouncedQuery.toLowerCase());
        });
      } else if (clientSideFilter === "contains") {
        items = items.filter((item) => {
          return itemManipulation
            .getItemLabel(item)
            .toLowerCase()
            .includes(options.debouncedQuery.toLowerCase());
        });
      } else if (clientSideFilter === "fuzzy") {
        // should be improved for performance
        const itemsMap = items.reduce((result, item) => {
          result[itemManipulation.getItemValue(item)] = item;
          return result;
        }, {});
        const itemsForFuzzy = items.map((item) => {
          return {
            id: itemManipulation.getItemValue(item),
            text: itemManipulation.getItemLabel(item),
          };
        });
        const itemsFiltered = fuzzyFilter(
          itemsForFuzzy,
          options.debouncedQuery,
          "text"
        );

        items = itemsFiltered.map((item) => {
          return itemsMap[item.id];
        });

        /** Disabled items should go after */
        if (typeof options.itemIsDisabled === "function") {
          items = items.sort((a, b) => {
            const isADisabled = options.itemIsDisabled(a);
            const isBDisabled = options.itemIsDisabled(b);
            if (isADisabled && !isBDisabled) {
              return 1;
            } else if (!isADisabled && isBDisabled) {
              return -1;
            } else {
              return 0;
            }
          });
        }
      }
    }

    /**
     * Filter out current value (only on multiple)
     *
     * Do not filter out if checkboxes or single select
     * used in multiple mode
     */
    if (
      options.multiple &&
      Array.isArray(options.value) &&
      !options.singleSelect &&
      !options.multipleCheckboxes
    ) {
      items = items.filter((item) => {
        return !options.value.find(
          (value) =>
            itemManipulation.getSelectedItemValue(value) ===
            itemManipulation.getItemValue(item)
        );
      });
    }
    return items;
  }, [
    options.items,
    options.prependItems,
    options.isPaginated,
    options.detectResponse,
    options.filter,
    options.filter !== "server" ? options.debouncedQuery : null,
    options.value,
    options.filter,
    options.multiple,
    itemManipulation.getItemValue,
    itemManipulation.getItemLabel,
    itemManipulation.getSelectedItemValue,
  ]);

  return filteredItems;
};

export default useCreateFilteredItems;
