import "@popperjs/core";
import React from "react";
import AdvSelect2Core from "./AdvSelect2Core";
import { AdvSelectContextProvider } from "./AdvSelectContext";
import { setErrorHandler } from "./errorHandler";
import interpolateItem from "./interpolateItem";
import { AdvSelect2Instance, AdvSelectProps2 } from "./types";
import validateProps from "./validateProps";

export enum AdvSelectType {
  Single = "single",
  Multiple = "multiple",
  Tags = "tags",
}

// @TODO prevent for showing the primitive field until detection if pagination is enabled
const AdvSelect2: React.ForwardRefExoticComponent<
  AdvSelectProps2 & { children?: React.ReactNode }
> & {
  interpolate: typeof interpolateItem;
  setErrorHandler: typeof setErrorHandler;
} = React.forwardRef<
  AdvSelect2Instance,
  React.PropsWithChildren<AdvSelectProps2>
>((props, ref) => {
  validateProps(props);

  return (
    <AdvSelectContextProvider
      labelParam={props.labelParam}
      valueParam={props.valueParam}
      selectedLabelParam={props.selectedLabelParam}
      selectedValueParam={props.selectedValueParam}
      multiple={props.multiple}
      multipleCheckboxes={props.multipleCheckboxes}
      onBeforeSelect={props.onBeforeSelect}
      onCommit={props.onCommit}
      singleSelect={props.singleSelect}
      value={props.value}
      cacheTime={props.cacheTime}
      detectPrimitive={props.detectPrimitive}
      detectResponse={props.detectResponse}
      filter={props.filter}
      getItems={props.getItems}
      paginated={props.paginated}
      params={props.params}
      showMenuConfirm={props.showMenuConfirm}
      prependItems={props.prependItems}
      selectByDefault={props.selectByDefault}
      actions={props.actions}
      tabIndex={props.tabIndex}
      autoWidth={props.autoWidth}
      minWidth={props.minWidth}
      multipleHideSearch={props.multipleHideSearch}
      itemRender={props.itemRender}
      itemIsDisabled={props.itemIsDisabled}
      itemIcon={props.itemIcon}
      popoverWrapper={props.popoverWrapper}
      selectedItemRender={props.selectedItemRender}
    >
      <AdvSelect2Core {...props} ref={ref} />
    </AdvSelectContextProvider>
  );
}) as any;

AdvSelect2.defaultProps = {
  valueParam: "id",
  labelParam: "name",
  multiple: false,
  cacheTime: 5000,
  autocomplete: false,
  filter: "server",
  detectResponse: true,
  wrapTags: false,
  paginated: {
    size: 30,
  },
};

AdvSelect2.interpolate = interpolateItem;
AdvSelect2.setErrorHandler = setErrorHandler;

export default AdvSelect2;
