export const dateAllowedInputFormats = [
  "yyyyMMdd",
  "yyyy-MM-dd",
  "yyyy.MM.dd",
  "yyyy/MM/dd",
];

export const dateAllowedInputFormatsWithTime = [
  "yyyyMMdd HH:mm",
  "yyyy-MM-dd HH:mm",
  "yyyy.MM.dd HH:mm",
  "yyyy/MM/dd HH:mm",
];
