import clsx from "clsx";
import React, { CSSProperties } from "react";
import { cm } from "../utils";
import cp from "../utils/cp";

export interface AdvSectionHeaderProps {
  className?: string;
  title?: string;
  border?: boolean;
  titleStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  style?: CSSProperties;
}

const AdvSectionHeader: React.FC<AdvSectionHeaderProps> = (props) => {
  return (
    <div
      className={clsx(
        cp("section-header"),
        cm(cp("section-header"), {
          border: props.border,
        }),
        props.className
      )}
      style={props.style}
    >
      <div
        className={clsx(cp("section-header__title"))}
        style={props.titleStyle}
      >
        {props.title}
      </div>
      <div
        className={clsx(cp("section-header__content"))}
        style={props.contentStyle}
      >
        {props.children}
      </div>
    </div>
  );
};

export default AdvSectionHeader;
