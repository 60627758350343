import React from "react";
import CalendarMobileBodyContent from "./CalendarMobileBodyContent";

export interface CalendarMobileBodyProps {}

const CalendarMobileBody: React.FC<CalendarMobileBodyProps> = (props) => {
  return (
    <div className="rivile-calendar-body rivile-calendar-body--mobile">
      <CalendarMobileBodyContent />
    </div>
  );
};

export default CalendarMobileBody;
