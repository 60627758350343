import React, { memo } from "react";
import { useCalendarTimelineViewsContext } from "../../../CalendarTimeline/CalendarTimelineViewsContext";
import { CalendarGroupType } from "../../../types";
import { useCalendarTimelineContext } from "../../../CalendarTimeline/CalendarTimelineContext.js";

export interface CalendarTimelineBodyGroupProps {
  group: CalendarGroupType;
  registerChild: (element: Element) => any;
}

const CalendarTimelineBodyGroup: React.FC<CalendarTimelineBodyGroupProps> =
  memo((props) => {
    return (
      <CalendarTimelineBodyGroupRender
        group={props.group}
        registerChild={props.registerChild}
      />
    );
  });

CalendarTimelineBodyGroup.displayName = "CalendarTimelineBodyGroup";

interface CalendarTimelineBodyGroupRenderProps {
  group: CalendarGroupType;
  registerChild: (element: Element) => any;
}

const CalendarTimelineBodyGroupRender: React.FC<CalendarTimelineBodyGroupRenderProps> =
  memo((props) => {
    const { groupView: GroupView } = useCalendarTimelineViewsContext();
    const { groupStyle } = useCalendarTimelineContext();

    const style =
      typeof groupStyle === "function" ? groupStyle(props.group) : null;

    return (
      <div className="rivile-calendar-body-group-wrapper">
        <div className="rivile-calendar-body-group" style={style}>
          <div
            className="rivile-calendar-body-group__measure"
            ref={props.registerChild}
          >
            <div className="rivile-calendar-body-group-content">
              {GroupView ? (
                <GroupView group={props.group} />
              ) : (
                props.group.title
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });

CalendarTimelineBodyGroupRender.displayName = "CalendarTimelineBodyGroupRender";

export default CalendarTimelineBodyGroup;
