import { useCombinedRef, useIsMobile, usePropRef } from "@adv-libs/utils";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper-2";
import { cm, cp } from "../utils";
import useItemsCount from "./hooks/useItemsCount";
import useMenuIsOpen from "./hooks/useMenuIsOpen";
import usePopoverWrapper from "./hooks/usePopoverWrapper";
import useRefs from "./hooks/useRefs";
import useValue from "./hooks/useValue";

interface AdvSelectPopoverProps {
  reference: HTMLElement | null;
}

export interface AdvSelectPopoverInstance {
  update: () => any;
}

const sameWidthModifier: any = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn({ state }) {
    state.styles.popper.maxWidth = `${400}px`;
  },
  effect({ state }) {
    const maxWidth = Math.min(state.elements.reference.offsetWidth, 400);
    state.elements.popper.style.minWidth = `${maxWidth}px`;
    state.elements.popper.style.maxWidth = `${maxWidth}px`;
  },
};

const offsetModifier: any = {
  name: "offset",
  options: {
    offset: [0, 10],
  },
};

const preventOverflowModifier: any = {
  name: "preventOverflow",
  options: {
    altAxis: true,
    boundariesElement: "viewport",
  },
};

const ignoreScrollModifier: any = {
  name: "eventListeners",
  options: { scroll: false },
};

const repositionModifier: any = {
  name: "flip",
  options: {
    fallbackPlacements: ["right", "left"],
  },
};

const name = "select2";

const cc = (className: string) => {
  return cp(`control-${name}__${className}`);
};

const AdvSelectPopover = React.forwardRef<
  AdvSelectPopoverInstance,
  React.PropsWithChildren<AdvSelectPopoverProps>
>((props, ref) => {
  const [popperElement, setPopperElement] = useState(null);
  const [viewportHeight, setViewportHeight] = useState(
    window.visualViewport.height
  );

  const menuIsOpen = useMenuIsOpen();
  const itemsCount = useItemsCount();
  const value = useValue();

  const PopoverWrapper = usePopoverWrapper();

  const refs = useRefs();

  const {
    update: popperUpdate,
    styles: popperStyles,
    attributes: popperAttributes,
  } = usePopper(props.reference, popperElement, {
    placement: "bottom-start",
    strategy: "absolute",
    modifiers: [
      repositionModifier,
      preventOverflowModifier,
      ignoreScrollModifier,
      sameWidthModifier,
      offsetModifier,
    ],
  });

  const popperUpdateRef = usePropRef(popperUpdate);

  useEffect(() => {
    if (menuIsOpen) {
      if (popperUpdateRef.current) {
        popperUpdateRef.current();
      }
    }
  }, [value, menuIsOpen, itemsCount]);

  const combinedRef = useCombinedRef(setPopperElement, refs.popoverRef);

  const isMobile = useIsMobile();

  const handleMobileViewportResize = useCallback(() => {
    if (isMobile) {
      setViewportHeight(window.visualViewport.height);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("resize", handleMobileViewportResize);
    return () => {
      window.removeEventListener("resize", handleMobileViewportResize);
    };
  }, [handleMobileViewportResize]);

  if (isMobile) {
    const popoverChildren = (
      <div
        tabIndex={-1}
        data-testid="select2-popover"
        style={{ height: viewportHeight }}
        className={clsx(
          cc("popover"),
          cm(cc("popover"), {
            mobile: true,
          })
        )}
      >
        <div className={cc("popover__content")}>{props.children}</div>
      </div>
    );

    return ReactDOM.createPortal(
      PopoverWrapper ? (
        <PopoverWrapper>{popoverChildren}</PopoverWrapper>
      ) : (
        popoverChildren
      ),
      document.body
    );
  } else {
    const popoverChildren = (
      <div
        ref={combinedRef}
        tabIndex={-1}
        data-testid="select2-popover"
        className={cc("popover")}
        style={{ ...popperStyles.popper, zIndex: 99999 }}
        {...popperAttributes.popper}
      >
        <div className={cc("popover__content")}>{props.children}</div>
      </div>
    );

    return ReactDOM.createPortal(
      PopoverWrapper ? (
        <PopoverWrapper>{popoverChildren}</PopoverWrapper>
      ) : (
        popoverChildren
      ),
      document.body
    );
  }
});

export default AdvSelectPopover;
