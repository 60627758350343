import { useCallback, useEffect, useRef } from "react";
import { useCalendarTimelineConfig } from "../CalendarTimeline/CalendarTimelineConfigContext";
import { useCalendarTimelineDOMReferencesContext } from "../CalendarTimeline/CalendarTimelineDOMReferencesContext";
import { useCalendarTimelineGroupsResizeContext } from "../CalendarTimeline/CalendarTimelineGroupsResizeContext";

const useCalendarGroupsDrag = () => {
  const handlerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const initialXRef = useRef(0);
  const initialGroupsWidthRef = useRef(0);

  const { onGroupsResize, setIsOver, setIsDragging } =
    useCalendarTimelineGroupsResizeContext();

  const { minGroupsWidth } = useCalendarTimelineConfig();

  const domRefs = useCalendarTimelineDOMReferencesContext();

  const onDragStart = useCallback(
    (e) => {
      isDraggingRef.current = true;
      initialXRef.current = e.clientX;
      initialGroupsWidthRef.current =
        domRefs.groupsSidebarRef.current.clientWidth;
      setIsDragging(true);
    },
    [domRefs.groupsSidebarRef, setIsDragging]
  );

  const onDragEnd = useCallback(() => {
    isDraggingRef.current = false;
    initialXRef.current = 0;
    initialGroupsWidthRef.current = 0;
    setIsDragging(false);
  }, [setIsDragging]);

  const onDrag = useCallback(
    (e) => {
      if (isDraggingRef.current) {
        const delta = e.clientX - initialXRef.current;
        const targetGroupsWidth = initialGroupsWidthRef.current + delta;

        onGroupsResize(Math.max(targetGroupsWidth, minGroupsWidth));
      }
    },
    [onGroupsResize]
  );

  const onMouseEnter = useCallback(() => {
    setIsOver(true);
  }, [setIsOver]);

  const onMouseLeave = useCallback(() => {
    setIsOver(false);
  }, [setIsOver]);

  useEffect(() => {
    const $handler = handlerRef.current;
    $handler.addEventListener("mousedown", onDragStart);
    $handler.addEventListener("mouseleave", onMouseLeave);
    $handler.addEventListener("mouseenter", onMouseEnter);
    document.addEventListener("mouseup", onDragEnd);
    document.addEventListener("mousemove", onDrag);

    return () => {
      if ($handler) {
        $handler.removeEventListener("mousedown", onDragStart);
        $handler.removeEventListener("mouseleave", onMouseLeave);
        $handler.removeEventListener("mouseenter", onMouseEnter);
      }
      document.removeEventListener("mouseup", onDragEnd);
      document.removeEventListener("mousemove", onDrag);
    };
  }, [onDrag, onDragEnd, onDragStart, onMouseEnter, onMouseLeave]);

  return {
    dragRef: handlerRef,
  };
};

export default useCalendarGroupsDrag;
