import { useContext } from "@adv-libs/utils";
import { useCallback } from "react";
import { AdvSelectContext } from "../AdvSelectContext";

const useMultipleHideSearch = () => {
  return useContext(
    AdvSelectContext,
    useCallback((value) => value.multiple && value.multipleHideSearch, [])
  );
};

export default useMultipleHideSearch;
