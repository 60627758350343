import { Icon } from "@adv-libs/icons";
import { defined } from "@adv-libs/utils";
import clsx from "clsx";
import React, { CSSProperties, useMemo } from "react";
import { cm, cp } from "../utils";

export interface CalloutProps extends React.HTMLAttributes<any> {
  intent?: "info" | "error" | "danger" | "warning" | "success" | string;
  transparent?: boolean;
  title?: string;
  icon?: any;
  noMargin?: boolean;
  light?: boolean;
  style?: CSSProperties;
}

const Callout: React.FC<CalloutProps> = (props) => {
  const {
    icon,
    intent,
    light,
    title,
    noMargin,
    className,
    transparent,
    style,
    ...htmlProps
  } = props;

  const calloutIcon = useMemo(() => {
    if (defined(icon)) {
      return icon;
    } else if (intent === "info") {
      return ["information", 2136];
    } else if (intent === "error") {
      return "warning";
    } else if (intent === "danger") {
      return "warning";
    } else if (intent === "success") {
      return "success";
    } else if (intent === "warning") {
      return "warning";
    } else {
      return icon;
    }
  }, [intent, icon]);

  return (
    <div
      className={clsx(
        cp("callout"),
        cm(cp("callout"), {
          info: intent === "info",
          error: intent === "error" || intent === "danger",
          success: intent === "success",
          warning: intent === "warning",
          light: light,
          icon: calloutIcon,
          "no-margin": noMargin,
          transparent: transparent,
        }),
        className
      )}
      style={style}
      {...htmlProps}
    >
      {calloutIcon ? (
        <span className={cp("callout__icon")}>
          <Icon icon={calloutIcon} />
        </span>
      ) : null}
      {props.title ? (
        <div className={cp("callout__title")}>{props.title}</div>
      ) : null}
      {props.children ? (
        <div className={cp("callout__content")}>{props.children}</div>
      ) : null}
    </div>
  );
};

Callout.defaultProps = {
  noMargin: false,
  transparent: true,
};

export default Callout;
