import { defined, makeid, useSecondEffect } from "@adv-libs/utils";
import CompactColorPicker from "@uiw/react-color-compact";
import clsx from "clsx";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdvControlLabel from "../AdvControlLabel";
import EndIcon from "../AdvInput/EndIcon";
import StartIcon from "../AdvInput/StartIcon";
import { AdvTooltip, AdvTooltipInstance } from "../AdvTooltip";
import { Dropdown } from "../Dropdown";
import { Menu } from "../Menu";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";

export interface AdvColorPickerProps extends AdvCommonControlProps {
  type?: string;
  height?: number;
  fieldName?: string;
  onBlur?: (e?) => any;
  onFocus?: (e?) => any;
  isClearable?: boolean;
  autoWidth?: boolean;
  minWidth?: number;
}

const name = "color-picker";

const AdvColorPicker = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvColorPickerProps>
>((props, ref) => {
  const controlRef = useRef<HTMLSpanElement>();
  const labelRef = useRef<HTMLSpanElement>();
  const tooltipRef = useRef<AdvTooltipInstance>(null);
  const [focused, setFocused] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipIsBig =
    typeof props.tooltip === "string" ? false : !!props.tooltip?.isBig;

  const id = useMemo(() => {
    return !props.autocomplete ? makeid(5) : null;
  }, []);

  const fieldName = useMemo(() => {
    return id ? props.fieldName + "$" + id : props.fieldName;
  }, [id, props.fieldName]);

  const handleChange = useCallback(
    (color: any) => {
      const hex = color?.hex;
      props.onCommit(hex);
    },
    [props]
  );

  const value = useMemo(() => {
    return defined(props.value) ? props.value : "";
  }, [props.value]);

  const hasValue = value;

  const { onBlur, onFocus, className, style } = useControl({
    name,
    value,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    height: props.height,
    notify: props.notify,
  });

  const handleStartIconClick = useCallback(
    (e) => {
      e.preventDefault();

      if (typeof props.onStartIconClick === "function") {
        props.onStartIconClick(props.value, e);
      }
    },
    [props.onStartIconClick, props.value]
  );

  const handleStopClearClickPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const handleClearButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.onCommit(null);

      const $input = controlRef.current.querySelector("input");
      if ($input) {
        $input.blur();
      }
    },
    [props.onCommit, onBlur]
  );

  const handleMouseEnter = useCallback((e) => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback((e?) => {
    setShowTooltip(false);
  }, []);

  let finalShowTooltip = showTooltip;
  if (props.tooltipShowOnValue) {
    finalShowTooltip = hasValue || focused ? showTooltip : false;
  }

  useEffect(() => {
    if (tooltipIsBig) return;
    if (showTooltip && props.tooltip && tooltipRef.current) {
      tooltipRef.current.setReferenceElement(controlRef.current);
    }
  }, [showTooltip, tooltipIsBig, props.tooltip, hasValue, focused]);

  /**
   * If component comes disabled or readonly, blur it
   */
  useSecondEffect(() => {
    if (props.disabled || props.readOnly) {
      onBlur();
    }
  }, [props.disabled, props.readOnly]);

  useLayoutEffect(() => {
    (controlRef.current as any).commit = (value: any) => {
      props.onCommit(value);
    };
  }, [props.onCommit]);

  const handleFocused = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(true);
  }, [props.tooltip]);

  const handleBlurred = useCallback(() => {
    if (!props.tooltip) return;
    setFocused(false);
  }, [props.tooltip]);

  return (
    <>
      {props.tooltip && !tooltipIsBig && finalShowTooltip ? (
        <AdvTooltip
          ref={tooltipRef}
          tooltip={props.tooltip}
          small
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
      <span
        className={className}
        style={style}
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={controlRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onFocus={handleFocused}
        onBlur={handleBlurred}
      >
        {props.startIcon ? (
          <StartIcon
            startIcon={props.startIcon}
            startIconSpin={props.startIconSpin}
            startIconSize={props.startIconSize}
            onStartIconClick={
              props.onStartIconClick ? handleStartIconClick : undefined
            }
            startIconCount={props.startIconCount}
            startIconActive={props.startIconActive}
            value={props.value}
          />
        ) : null}
        {props.endIcon ? (
          <EndIcon
            endIcon={props.endIcon}
            endIconSpin={props.endIconSpin}
            endIconTooltip={props.endIconTooltip}
            onEndIconClick={props.onEndIconClick}
          />
        ) : null}
        <Dropdown disabled={props.disabled || props.readOnly} usePortal>
          <label>
            <div
              className={clsx(
                cp(`control__indicator-container`),
                "r365-prevent-dropdown"
              )}
            >
              {props.readOnly || props.disabled ? null : props.isClearable &&
                props.value ? (
                <div
                  className={cp(`control__indicator-clear`)}
                  onMouseDown={handleClearButtonClick}
                  onClick={handleStopClearClickPropagation}
                >
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    focusable="false"
                  >
                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                  </svg>
                </div>
              ) : null}
            </div>
            <div
              className={"color-value-preview"}
              style={{ backgroundColor: props.value }}
            ></div>
            <input
              name={fieldName}
              type="text"
              readOnly
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <AdvControlLabel ref={labelRef} label={props.label} />
          </label>
          <Menu>
            <CompactColorPicker
              className="r365-color-picker-compact"
              color={props.value}
              onChange={handleChange}
            />
          </Menu>
        </Dropdown>
      </span>
    </>
  );
});

AdvColorPicker.defaultProps = {
  type: "text",
  autocomplete: false,
};

export default AdvColorPicker;
