import React, { useContext, useMemo } from "react";
import type { CalendarTimelineConfigType } from "../types";

export type CalendarTimelineConfigContextType = CalendarTimelineConfigType;

const CalendarTimelineConfigContext =
  React.createContext<CalendarTimelineConfigContextType>(null);

export interface CalendarTimelineConfigContextProviderProps
  extends CalendarTimelineConfigType {}

const CalendarTimelineConfigContextProvider: React.FC<
  CalendarTimelineConfigContextProviderProps
> = (props) => {
  const value = useMemo<CalendarTimelineConfigType>(() => {
    return {
      minCellWidth: props.minCellWidth || 30,
      defaultGroupsWidth: props.defaultGroupsWidth || 300,
      minGroupsWidth: props.minGroupsWidth || 50,
    };
  }, [props.minCellWidth, props.defaultGroupsWidth, props.minGroupsWidth]);

  return (
    <CalendarTimelineConfigContext.Provider value={value}>
      {props.children}
    </CalendarTimelineConfigContext.Provider>
  );
};

const useCalendarTimelineConfig = () => {
  return useContext(CalendarTimelineConfigContext);
};

export { CalendarTimelineConfigContextProvider, useCalendarTimelineConfig };
