import { cp } from "../utils";

const name = "simple-select";

const cc = (className?: string) => {
  if (className) {
    return cp(`control-${name}__${className}`);
  }
};

export default cc;
