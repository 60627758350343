import { differenceInDays } from "date-fns";

const getStartDateDaysOffset = (
  rangeStart: Date,
  itemStart: Date,
  onlyPositive?: boolean
) => {
  const diff = differenceInDays(itemStart, rangeStart);

  if (onlyPositive) {
    return diff < 0 ? 0 : diff;
  }

  return diff;
};

export default getStartDateDaysOffset;
