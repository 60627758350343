import { Icon } from "@adv-libs/icons";
import { useTranslation } from "@adv-libs/l10n";
import React, { useCallback } from "react";
import { Button } from "../../Button";
import { cp } from "../../utils";
import { StateActions } from "../hooks/create/useCreateSelectState";
import useActions from "../hooks/useActions";
import useFilteredItemsCount from "../hooks/useFilteredItemsCount";
import usIsFirstTimeLoading from "../hooks/useIsFirstTimeLoading";
import useQuery from "../hooks/useQuery";
import useReloadItem from "../hooks/useReloadItem";
import useSelectDispatch from "../hooks/useSelectDispatch";
import useShowMenuConfirm from "../hooks/useShowMenuConfirm";
import useValue from "../hooks/useValue";
import { AdvSelect2ActionType, AdvSelectOption } from "../types";

export interface AdvSelectMenuFooterProps {}

const name = "select2__menu-footer";

const cc = (className?: string) => {
  return cp(`control-${name}${className ? `-${className}` : ""}`);
};

const AdvSelectMenuFooter: React.FC<AdvSelectMenuFooterProps> = (props) => {
  const dispatch = useSelectDispatch();
  const showMenuConfirm = useShowMenuConfirm();

  const handleClose = useCallback(() => {
    dispatch(StateActions.closeMenu());
  }, []);

  const { t } = useTranslation();

  const isFirstTimeLoading = usIsFirstTimeLoading();
  const actions = useActions();
  const query = useQuery();
  const value = useValue();
  const filteredItemsCount = useFilteredItemsCount();
  const reloadItem = useReloadItem();

  const handleActionResult = useCallback(
    async (value) => {
      if (typeof value !== "undefined") {
        dispatch(StateActions.closeMenu());
        await reloadItem(value, false, false);
      }
    },
    [reloadItem]
  );

  if (isFirstTimeLoading) {
    return null;
  }

  return (
    <div className={cc()}>
      {showMenuConfirm ? (
        <div className={cc("confirm")}>
          <Button primary fill onClick={handleClose}>
            {t("Select")}
          </Button>
        </div>
      ) : null}
      {Array.isArray(actions) && actions.length > 0 ? (
        <div className={cc("actions")}>
          {actions.map((action, index) => {
            return (
              <AdvSelectItemsControlAction
                key={index}
                action={action}
                query={query}
                value={value}
                itemsCount={filteredItemsCount}
                onActionResult={handleActionResult}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

interface AdvSelectItemsControlActionProps {
  action: AdvSelect2ActionType;
  query: string;
  value: AdvSelectOption | AdvSelectOption[];
  itemsCount: number;
  onActionResult: (value?: any) => any;
}

const AdvSelectItemsControlAction: React.FC<
  AdvSelectItemsControlActionProps
> = (props) => {
  const handleAction = useCallback(async () => {
    if (props.action.action) {
      const result = await props.action.action(
        props.value,
        props.query,
        props.itemsCount
      );
      props.onActionResult(result);
    }
  }, [props.action.action, props.query, props.itemsCount]);

  const title =
    typeof props.action.title === "function"
      ? props.action.title(props.value, props.query, props.itemsCount)
      : props.action.title;

  const visible =
    typeof props.action.visible === "function"
      ? props.action.visible(props.value, props.query, props.itemsCount)
      : true;

  if (visible === false) {
    return null;
  }

  return (
    <div className={cc("actions-action")} onClick={handleAction}>
      {props.action.icon ? <Icon icon={props.action.icon} /> : null}
      <div className={cc("actions-action-title")}>{title}</div>
    </div>
  );
};

export default AdvSelectMenuFooter;
