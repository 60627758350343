const defaultHandler = (err) => {
  return String(err);
};

let errorHandler = defaultHandler;

const setErrorHandler = (handler: (err: any) => string) => {
  errorHandler = handler || defaultHandler;
};

const getHandledError = (err: any) => {
  if (!err) return err;
  return errorHandler(err);
};

export { getHandledError, setErrorHandler };
