import { Icon } from "@adv-libs/icons";
import { createJexl } from "@adv-libs/utils";
import { dot } from "@adv-libs/utils";
import React, { useCallback, useMemo } from "react";
import AdvControlLabel from "../AdvControlLabel";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";
import { cp } from "../utils";

export interface AdvFieldButtonProps extends AdvCommonControlProps {
  onClick?: (value?) => any;
  displayValue?: string | ((value: any) => string);
  fieldName?: string;
}

const name = "field-button";

const AdvFieldButton = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvFieldButtonProps>
>((props, ref) => {
  const handleClick = useCallback(async () => {
    if (!props.disabled) {
      if (typeof props.onClick === "function") {
        const result = await props.onClick(props.value);
        props.onCommit(result);
      }
    }
  }, [props.disabled, props.value, props.onCommit]);

  const jexlLabelTemplate = useMemo(() => {
    if (
      typeof props.displayValue === "string" &&
      props.displayValue.startsWith("jexl:")
    ) {
      const jexl = createJexl();
      return jexl.compile(props.displayValue.replace("jexl:", "").trim());
    } else {
      return null;
    }
  }, [props.displayValue]);

  const displayValue = useMemo(() => {
    if (jexlLabelTemplate) {
      return jexlLabelTemplate.evalSync(props.value);
    } else if (typeof props.displayValue === "function") {
      return props.displayValue(props.value);
    } else {
      return dot.get(props.value, props.displayValue);
    }
  }, [props.value, props.displayValue, jexlLabelTemplate]);

  const { onBlur, onFocus, className } = useControl({
    name,
    value: displayValue,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    disabled: props.disabled,
    notify: props.notify,
    className: props.className,
    autocomplete: props.autocomplete,
    readOnly: true,
  });

  return (
    <span
      className={className}
      data-field={props.fieldName}
      data-testid={name + "|" + props.fieldName}
    >
      <label>
        {props.startIcon ? (
          <span className={cp("control__start-icon")}>
            <Icon icon={props.startIcon} spin={props.startIconSpin} />
          </span>
        ) : null}
        {props.endIcon ? (
          <span className={cp("control__end-icon")}>
            <Icon icon={props.endIcon} spin={props.endIconSpin} />
          </span>
        ) : null}
        <input
          ref={ref}
          type="text"
          value={displayValue ?? ""}
          onClick={handleClick}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={props.disabled}
          readOnly
        />
        <AdvControlLabel label={props.label} />
      </label>
    </span>
  );
});

AdvFieldButton.defaultProps = {
  autocomplete: false,
};

export default AdvFieldButton;
