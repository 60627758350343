import { useLayoutEffect } from "react";
import { AdvSelectContextType } from "../../types";

export interface UseAutoWidthMeasureOptions {
  autoWidth: boolean;
  refs: AdvSelectContextType["refs"];
  hasValue: boolean;
  minWidth?: number;
}

const useAutoWidthMeasure = (options: UseAutoWidthMeasureOptions) => {
  useLayoutEffect(() => {
    if (!options.autoWidth) return;

    const labelRef = options.refs.labelRef;

    if (!labelRef.current) return;

    const $text = labelRef.current.querySelector("span");

    const labelWidth = $text.scrollWidth;

    const computedStyle = window.getComputedStyle(labelRef.current, null);

    const paddingLeft = parseInt(
      computedStyle.getPropertyValue("padding-left")
    );
    const paddingRight = parseInt(
      computedStyle.getPropertyValue("padding-right")
    );

    const offset = 50;

    const finalWidthLabel = paddingLeft + labelWidth + paddingRight + offset;

    const minWidth = options.minWidth || 0;

    const finalWidth = Math.max(finalWidthLabel, minWidth);

    options.refs.controlRef.current.style["minWidth"] = finalWidth + "px";
  }, [options.autoWidth, options.hasValue, options.minWidth]);
};

export default useAutoWidthMeasure;
