import React from "react";
import { CalendarEventInWeekType } from "../../../types";
import getEventsCountInDay from "../../../utils/getEventsCountInDay";

export interface CalendarMobileBodyWeekExpandButtonsProps {
  onExpand: () => any;
  week: Date[];
  allWeekEvents: CalendarEventInWeekType[][];
  visibleWeekEvents: CalendarEventInWeekType[][];
}

const CalendarMobileBodyWeekExpandButtons: React.FC<
  CalendarMobileBodyWeekExpandButtonsProps
> = (props) => {
  return (
    <div className="rivile-calendar-body-week-expand-buttons">
      {props.week.map((day) => {
        const monthDay = day.getDate();

        const flatAllEvents = props.allWeekEvents.flatMap((weekEvents) =>
          weekEvents.map((weekEvent) => weekEvent.event)
        );

        const flatAllVisibleEvents = props.visibleWeekEvents.flatMap(
          (weekEvents) => weekEvents.map((weekEvent) => weekEvent.event)
        );

        const allDayEventsCount = getEventsCountInDay(day, flatAllEvents);

        const visibleDayEventsCount = getEventsCountInDay(
          day,
          flatAllVisibleEvents
        );

        const hiddenEventsCount = allDayEventsCount - visibleDayEventsCount;

        if (hiddenEventsCount <= 0) {
          return (
            <div
              key={monthDay}
              className="rivile-calendar-body-expand-button-cell hidden"
            ></div>
          );
        }

        return (
          <div
            key={monthDay}
            className="rivile-calendar-body-grid-expand-button-cell"
          >
            <div
              className="rivile-calendar-body-grid-expand-button"
              onClick={props.onExpand}
            >
              +{hiddenEventsCount}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CalendarMobileBodyWeekExpandButtons;
