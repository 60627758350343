import { Icon } from "@adv-libs/icons";
import { defined } from "@adv-libs/utils";
import React, { useCallback, useMemo, useRef, useState } from "react";
import AdvControlLabel from "../AdvControlLabel";
import { AdvCommonControlProps } from "../types";
import useControl from "../useControl";

export interface AdvTagsProps extends AdvCommonControlProps {
  fieldName?: string;
}

const name = "tags";

const AdvTags = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<AdvTagsProps>
>((props, ref) => {
  const inputRef = useRef<HTMLSpanElement>();
  const [tagValue, setTagValue] = useState("");

  const value = useMemo(() => {
    return defined(props.value)
      ? Array.isArray(props.value) && props.value.length
        ? props.value
        : false
      : false;
  }, [props.value]);

  const { onBlur, onFocus, className } = useControl({
    name,
    value: value || tagValue.length > 0,
    label: props.label,
    minimal: props.minimal,
    required: props.required,
    success: props.success,
    warning: props.warning,
    danger: props.danger,
    className: props.className,
    autocomplete: props.autocomplete,
    disabled: !props.readOnly && props.disabled,
    readOnly: props.readOnly,
    notify: props.notify,
  });

  const commitTag = useCallback(() => {
    const tagValue = inputRef.current;
    if (tagValue && (tagValue.innerText || "").trim()) {
      const trimmedTag = tagValue.innerText.trim();
      if (Array.isArray(props.value)) {
        props.onCommit([...props.value, trimmedTag]);
      } else {
        props.onCommit([trimmedTag]);
      }
      setTagValue("");
      tagValue.innerText = "";
      tagValue.focus();
    }
  }, [props.onCommit, props.value]);

  const handleFocus = useCallback(
    (e) => {
      return onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      commitTag();
      return onBlur(e);
    },
    [commitTag, onBlur]
  );

  const handleMouseDown = useCallback((e) => {
    if (!e.target.classList.contains("tag-input")) {
      e.preventDefault();
      e.stopPropagation();
      inputRef.current.focus();
    }
  }, []);

  const handleChange = useCallback((e) => {
    setTagValue((e.target.innerText || "").trim());
  }, []);

  const handleTagRemove = useCallback(
    (index) => {
      const value = [...props.value];
      value.splice(index, 1);
      props.onCommit(value);
    },
    [props.onCommit, props.value]
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> =
    useCallback(
      (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          commitTag();
        } else if (e.key === "Escape") {
          e.preventDefault();
          e.stopPropagation();
          setTagValue("");
          inputRef.current.innerText = "";
          inputRef.current.blur();
        }
      },
      [commitTag]
    );

  const handleTagChange = useCallback(
    (index: number, newValue: string) => {
      const value = [...props.value];
      value[index] = newValue;
      props.onCommit(value);
    },
    [props.onCommit, props.value]
  );

  return (
    <span
      className={className}
      data-field={props.fieldName}
      data-testid={name + "|" + props.fieldName}
      onMouseDown={handleMouseDown}
    >
      <label>
        <div className="tags-content">
          {Array.isArray(props.value)
            ? props.value.map((tag, index) => {
                return (
                  <Tag
                    disabled={props.disabled}
                    key={index}
                    index={index}
                    tag={tag}
                    onChange={handleTagChange}
                    onRemove={handleTagRemove}
                  />
                );
              })
            : null}
          <span
            tabIndex={-1}
            ref={inputRef}
            className="tag-input"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            contentEditable={!props.disabled}
            onInput={handleChange}
          ></span>
          {!props.disabled && tagValue.length ? (
            <div className="add-tag" onClick={commitTag}>
              <Icon icon={["plus-circle", 1852]} />
            </div>
          ) : null}
        </div>
        <AdvControlLabel label={props.label} />
      </label>
    </span>
  );
});

interface TagProps {
  tag: string;
  index: number;
  onChange: (index: number, newValue: string) => unknown;
  onRemove: (index: number) => any;
  disabled: boolean;
}

const Tag: React.FC<TagProps> = (props) => {
  const inputRef = useRef<HTMLSpanElement>();

  const [editing, setEditing] = useState(false);

  const handleRemove = useCallback(() => {
    setEditing(false);
    props.onRemove(props.index);
  }, [props.onRemove, props.index]);

  const handleBlur: React.FocusEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      setEditing(false);
      const trimmedTag = (e.target.innerText ?? "").trim();
      if (trimmedTag.length) {
        if (trimmedTag !== props.tag) {
          props.onChange(props.index, trimmedTag);
        }
      } else {
        props.onRemove(props.index);
      }
    },
    [props.onChange, props.onRemove, props.tag, props.index]
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> =
    useCallback(
      (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          inputRef.current.blur();
        } else if (e.key === "Escape") {
          e.preventDefault();
          e.stopPropagation();
          inputRef.current.innerText = props.tag;
          inputRef.current.blur();
        }
      },
      [props.tag]
    );

  const handleMouseDown: React.MouseEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setEditing(true);
    },
    []
  );

  return (
    <span className="tag">
      <span
        tabIndex={editing ? -1 : undefined}
        ref={inputRef}
        className={editing ? "tag-input" : "tag-content"}
        contentEditable={editing && !props.disabled}
        dangerouslySetInnerHTML={{ __html: props.tag }}
        onBlur={editing ? handleBlur : undefined}
        onKeyDown={editing ? handleKeyDown : undefined}
        onMouseDown={editing ? undefined : handleMouseDown}
      ></span>
      {!props.disabled ? (
        <Icon icon={["close", 1833]} onClick={handleRemove} />
      ) : null}
    </span>
  );
};

AdvTags.defaultProps = {};

export default AdvTags;
