import { Icon } from "@adv-libs/icons";
import React from "react";
import cc from "./cc";

export interface AdvSelectLoadingIndicatorProps {}

const AdvSelectLoadingIndicator: React.FC<AdvSelectLoadingIndicatorProps> = (
  props
) => {
  return (
    <div className={cc(`indicator-loading`)}>
      <Icon icon={["spin", 2200]} spin />
    </div>
  );
};

export default AdvSelectLoadingIndicator;
