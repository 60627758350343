import { useMemo } from "react";

const useMobileCSSProperties = () => {
  const cssProperties = useMemo<Record<string, any>>(() => {
    return {};
  }, []);

  return cssProperties;
};

export default useMobileCSSProperties;
