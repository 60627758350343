import { defined } from "@adv-libs/utils";
import React from "react";
import cp from "./utils/cp";

export interface AdvControlLabelProps {
  label?: string;
}

const AdvControlLabel = React.forwardRef<HTMLSpanElement, AdvControlLabelProps>(
  (props, ref) => {
    if (!defined(props.label)) return null;

    return (
      <span ref={ref} className={`${cp("control__label")}`}>
        <span className={`${cp("control__label")}-text`}>{props.label}</span>
      </span>
    );
  }
);

export default AdvControlLabel;
