import { createJexl } from "@adv-libs/utils";

const jexl = createJexl();
const cache = {};

const interpolateJexl = (value: any, jexlString: string) => {
  if (!cache[jexlString]) {
    cache[jexlString] = jexl.compile(jexlString.replace("jexl:", "").trim());
  }
  const expression = cache[jexlString];
  return expression.evalSync(value);
};

export default interpolateJexl;
