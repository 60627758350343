import clsx from "clsx";
import React from "react";
import { cm } from "../utils";

export interface CardProps {
  fullHeight?: boolean;
  shadow?: boolean;
  border?: boolean;
  growHeight?: boolean;
}

const Card: React.FC<CardProps> = ({ children, ...props }) => {
  return (
    <div
      className={clsx("r365-card", {
        "r365-card--full-height": props.fullHeight,
        "r365-card--shadow": props.shadow,
        "r365-card--border": props.border,
        // maybe should be default
        "r365-card--grow-height": props.growHeight,
      })}
    >
      {children}
    </div>
  );
};

export interface CardHeaderProps {}

export const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  ...props
}) => {
  return <div className={clsx("r365-card-header")}>{children}</div>;
};

export interface CardBodyProps {
  flex?: boolean;
}

export const CardBody: React.FC<CardBodyProps> = ({ children, ...props }) => {
  return (
    <div
      className={clsx(
        "r365-card-body",
        cm("r365-card-body", {
          flex: props.flex,
        })
      )}
    >
      {children}
    </div>
  );
};

CardBody.defaultProps = {
  flex: false,
};

export interface CardTitleProps {}

export const CardTitle: React.FC<CardTitleProps> = ({ children, ...props }) => {
  return <div className={clsx("r365-card-title")}>{children}</div>;
};

export default Card;
