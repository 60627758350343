import clsx from "clsx";
import React, {
  CSSProperties,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { AdvCommonControlProps } from "../types";
import cm from "../utils/cm";
import cp from "../utils/cp";

export interface AdvCheckboxProps extends AdvCommonControlProps {
  align?: "left" | "center" | "right";
  noHeight?: boolean;
  style?: CSSProperties;
  fieldName?: string;
  height?: number;
  preventFocus?: boolean;
  indeterminate?: boolean;
  label?: any;
}

const name = "checkbox";

const AdvCheckbox = React.forwardRef<HTMLInputElement, AdvCheckboxProps>(
  (props, ref) => {
    const controlRef = useRef<HTMLSpanElement>();

    const handleChange = useCallback(
      (e) => {
        if (props.readOnly) return;
        props.onCommit(e.target.checked);
      },
      [props]
    );

    const handleMouseClick = useCallback(
      (e) => {
        if (props.preventFocus) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [props.preventFocus]
    );

    const value = useMemo(() => {
      return props.value;
    }, [props.value]);

    useLayoutEffect(() => {
      (controlRef.current as any).commit = (value: any) => {
        props.onCommit(value);
      };
    }, [props.onCommit]);

    const controlClassName = cp("control");

    const style = useMemo(() => {
      const style = props.style ?? {};
      if (typeof props.height === "number") {
        style["--control-height"] = props.height + "px";
      }
      return style;
    }, [props.height, props.style]);

    return (
      <span
        data-field={props.fieldName}
        data-testid={"field|" + props.fieldName}
        data-type={name}
        ref={controlRef}
        className={clsx(
          cp("control"),
          cp("control-" + name),
          cp("control-" + name + "--" + props.align),
          cm(cp("control-" + name), {
            "no-label": !props.label,
            readonly: props.readOnly,
            "no-height": props.noHeight,
          }),
          cm(controlClassName, {
            disabled: props.disabled,
            danger: props.danger,
            indetermine: props.indeterminate,
          })
        )}
        style={style}
      >
        <label onClick={handleMouseClick}>
          {props.label}
          <input
            ref={ref}
            type="checkbox"
            checked={value === true}
            onChange={handleChange}
            disabled={props.disabled}
            data-value={value === true}
            tabIndex={props.preventFocus ? -1 : undefined}
          />
          <div className={cp("control-" + name + "__indicator")}></div>
        </label>
      </span>
    );
  }
);

AdvCheckbox.defaultProps = {
  align: "left",
};

export default AdvCheckbox;
