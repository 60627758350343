import { AdvFormMessage } from "@adv-libs/adv-form";
import React from "react";
import { Callout } from "../Callout";

export interface FormMessagesProps {
  formMessages: AdvFormMessage[];
}

const FormMessages: React.FC<FormMessagesProps> = ({ formMessages }) => {
  if (!formMessages.length) return null;
  return (
    <>
      {formMessages.map((formMessage, key) => {
        return (
          <Callout
            light
            noMargin
            style={{ marginBottom: 20, fontSize: 16 }}
            key={key}
            intent={
              typeof formMessage === "string"
                ? "danger"
                : (formMessage.intent as any)
            }
            title={
              typeof formMessage === "string"
                ? formMessage
                : formMessage.title || null
            }
          >
            {typeof formMessage === "string"
              ? formMessage
              : formMessage.description}
          </Callout>
        );
      })}
    </>
  );
};

export default FormMessages;
