import { defined, getInputTextWidth } from "@adv-libs/utils";
import React, { useLayoutEffect } from "react";

export interface UseAutoWidthMeasureOptions {
  autoWidth: boolean;
  hasValue: boolean;
  value: any;
  label: any;
  minWidth?: number;
  labelRef: React.MutableRefObject<HTMLSpanElement>;
  controlRef: React.MutableRefObject<HTMLSpanElement>;
}

const useAutoWidthMeasure = (options: UseAutoWidthMeasureOptions) => {
  useLayoutEffect(() => {
    if (!options.autoWidth) return;

    const labelRef = options.labelRef;
    const controlRef = options.controlRef;

    if (!labelRef.current) return;

    const $input = controlRef.current.querySelector("input");

    const valueWidth =
      defined(options.value) && options.value ? getInputTextWidth($input) : 0;

    const $text = labelRef.current.querySelector("span");

    const labelWidth = $text.scrollWidth;

    const computedStyle = window.getComputedStyle(labelRef.current, null);

    const paddingLeft = parseInt(
      computedStyle.getPropertyValue("padding-left")
    );
    const paddingRight = parseInt(
      computedStyle.getPropertyValue("padding-right")
    );

    const offset = 20;

    const labelFullWidth = paddingLeft + labelWidth + paddingRight + offset;

    const delta = labelFullWidth - labelWidth + 20;

    const minWidth = options.minWidth || 0;

    const finalWidth = Math.max(labelFullWidth, valueWidth + delta, minWidth);

    controlRef.current.style["width"] = finalWidth + "px";
  }, [
    options.autoWidth,
    options.hasValue,
    options.value,
    options.label,
    options.minWidth,
  ]);
};

export default useAutoWidthMeasure;
