import {
  defined,
  isEqual,
  isPrimitive,
  useCompEffect,
  useDelayed,
} from "@adv-libs/utils";
import React, { useCallback, useEffect, useRef } from "react";
import { AdvSelect2State, AdvSelectCommonProps } from "../../types";
import { StateActions } from "../create/useCreateSelectState";

export interface UseClearFetchedItemsOptions {
  cacheTime: number;
  getItemsIsFunction: boolean;
  fetchItems: (replace?: boolean) => any;
  dispatch: React.Dispatch<any>;
  state: AdvSelect2State;
  detectPrimitive: AdvSelectCommonProps["detectPrimitive"];
  selectByDefault: AdvSelectCommonProps["selectByDefault"];
  value: AdvSelectCommonProps["value"];
  filter: AdvSelectCommonProps["filter"];
  getItems: AdvSelectCommonProps["getItems"];
  params: AdvSelectCommonProps["params"];
  debouncedQuery: string;
}

const useItemsFetching = (options: UseClearFetchedItemsOptions) => {
  const dispatch = options.dispatch;
  const state = options.state;
  const fetchItems = options.fetchItems;
  const mountedRef = useRef<boolean>(true);

  const clearFetchedItems = useCallback(() => {
    if (!mountedRef.current) return;
    dispatch(StateActions.clearFetchedItems());
  }, []);

  const [clearCachedItems, cancelClearCachedItems] =
    useDelayed(clearFetchedItems);

  /**
   * Trigger fetch after menu is opened.
   */
  useEffect(() => {
    if (state.menuIsOpen) {
      /**
       * If menu opened
       * Cancel cache clearing
       */
      cancelClearCachedItems();

      if (!state.itemsIsCached) {
        /**
         * If no cached items, fetch it
         */
        fetchItems(true);
      }
    } else {
      /**
       * If menu closed
       */
      if (state.itemsIsCached) {
        /**
         * If there is cached items
         * Clear query and trigger cached items clear after 'cacheTime'
         */
        dispatch(StateActions.clearQuery());
        clearCachedItems(options.cacheTime);
      } else if (options.getItemsIsFunction) {
        /**
         * If there is no cached items and 'getItems' is a function (not array)
         * clear items and reset state
         */
        dispatch(StateActions.clearFetchedItems());
      }
    }
  }, [state.menuIsOpen]);

  /**
   * Trigger items fetching immediately if 'detectPrimitive' or 'selectByDefault' is enabled.
   *
   * Also trigger items fetching when value is changed with following conditions:
   * - value is primitive, when 'detectPrimitive' enabled
   * - value is not defined, when 'selectByDefault' enabled
   */
  useEffect(() => {
    if (
      (options.detectPrimitive && isPrimitive(options.value)) ||
      (typeof options.selectByDefault === "number" && !defined(options.value))
    ) {
      fetchItems(true);
    }
  }, [options.value]);

  /**
   * Trigger items fetching on debounced query change.
   * Skip fetching if debounced query is null (cleared with button)
   */
  useEffect(() => {
    if (options.debouncedQuery !== null && options.filter === "server") {
      fetchItems(true);
    }
  }, [options.debouncedQuery]);

  /**
   * Trigger items fetching when page is changed and more items exists
   */
  useEffect(() => {
    if (state.page > 0 && state.totalElements > state.items.length) {
      fetchItems();
    }
  }, [state.page]);

  /**
   * When 'getItems' is changed, clear cached items
   */
  useEffect(() => {
    if (!Array.isArray(options.getItems)) {
      dispatch(StateActions.clearFetchedItems());
    }
  }, [options.getItems]);

  /**
   * Deep compare received 'params'.
   * If 'getItems' is not an direct array, clear cached items, and if menu
   * is open refetch items
   */
  useCompEffect(
    () => {
      if (!Array.isArray(options.getItems)) {
        dispatch(StateActions.clearFetchedItems());
        if (state.menuIsOpen) {
          fetchItems();
        }
      }
    },
    options.params,
    isEqual
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
};

export default useItemsFetching;
