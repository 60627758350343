import { Icon } from "@adv-libs/icons";
import clsx from "clsx";
import React, { CSSProperties, memo, useMemo } from "react";
import { AdvCommonControlProps } from "../types";
import { cm, cp } from "../utils";

export interface StartIconProps {
  startIcon?: AdvCommonControlProps["startIcon"];
  startIconSize?: AdvCommonControlProps["startIconSize"];
  startIconSpin?: AdvCommonControlProps["startIconSpin"];
  onStartIconClick?: AdvCommonControlProps["onStartIconClick"];
  startIconCount?: AdvCommonControlProps["startIconCount"];
  startIconActive?: AdvCommonControlProps["startIconActive"];
  value?: any;
}

const StartIcon: React.FC<StartIconProps> = memo((props) => {
  const style = useMemo(() => {
    const style: CSSProperties = {};
    if (props.startIconSize) {
      return {
        fontSize: props.startIconSize,
      };
    }
    return style;
  }, []);

  return (
    <span
      className={clsx(
        cp("control__start-icon"),
        cm(cp("control__start-icon"), {
          clickable: !!props.onStartIconClick,
          active: props.startIconActive,
        })
      )}
      onMouseDown={
        typeof props.onStartIconClick === "function"
          ? props.onStartIconClick
          : undefined
      }
      data-count={props.startIconCount > 0 ? props.startIconCount : undefined}
      style={style}
    >
      {typeof props.startIcon === "function" ? (
        <props.startIcon value={props.value} />
      ) : (
        <Icon icon={props.startIcon} spin={props.startIconSpin} />
      )}
    </span>
  );
});

export default StartIcon;
