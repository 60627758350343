import { isPlainObject } from "@adv-libs/utils";
import { Placement } from "@popperjs/core";
import React, { useCallback, useMemo, useState } from "react";
import { usePopper } from "react-popper-2";
import { cp } from "../utils";

export interface PopoverProps {
  reference: HTMLElement | null;
  strategy?: "fixed" | "absolute";
  sameWidth?: boolean;
  sameTop?: boolean;
  align?: "start" | "end";
  position?: "top" | "bottom" | "right" | "left";
  offsetX?: number;
  offsetY?: number;
}

const repositionModifier: any = {
  name: "flip",
  options: {
    fallbackPlacements: ["right", "left"],
  },
};

const sameWidthModifier: any = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn({ state }) {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect({ state }) {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

const sameTopModifier: any = {
  name: "sameTop",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn({ state }) {
    const basePlacement = state.placement.split("-")[0];
    if (basePlacement === "right") {
      state.styles.popper.top = "0px";
      state.styles.popper.bottom = "auto";
      state.styles.popper.left = "0px";
      state.styles.popper.right = "auto";
      let translateX = state.rects.reference.x + state.rects.reference.width;
      let translateY = state.rects.reference.y;

      translateX += state.modifiersData.offset.right.y;

      state.styles.popper.transform = `translate(${translateX}px, ${translateY}px)`;
    } else if (basePlacement === "left") {
      state.styles.popper.top = "0px";
      state.styles.popper.bottom = "auto";
      state.styles.popper.left = "auto";
      state.styles.popper.right = "0px";
      state.styles.popper.transform = `translate(0px, 0px)`;

      let translateX = -state.rects.reference.width;
      let translateY = state.rects.reference.y;

      translateX -= state.modifiersData.offset.right.y;

      state.styles.popper.transform = `translate(${translateX}px, ${translateY}px)`;
    }
  },
};

const preventOverflowModifier: any = {
  name: "preventOverflow",
  options: {
    altAxis: true,
    boundariesElement: "viewport",
  },
};

const ignoreScrollModifier: any = {
  name: "eventListeners",
  options: { scroll: false },
};

const Popover = React.forwardRef<any, React.PropsWithChildren<PopoverProps>>(
  (props, ref) => {
    const [popperElement, setPopperElement] = useState(null);

    const offsetModifier = useMemo(() => {
      return {
        name: "offset",
        options: {
          offset: [props.offsetX, props.offsetY],
        },
      };
    }, [props.offsetX, props.offsetY]);

    const {
      // update: popperUpdate,
      styles: popperStyles,
      attributes: popperAttributes,
    } = usePopper(props.reference, popperElement, {
      placement: (props.position + "-" + props.align) as Placement,
      strategy: props.strategy,
      modifiers: [
        props.sameTop ? repositionModifier : null,
        preventOverflowModifier,
        ignoreScrollModifier,
        props.sameWidth ? sameWidthModifier : null,
        props.sameTop ? sameTopModifier : null,
        offsetModifier,
      ].filter(Boolean),
    });

    // const animationFrameId = useRef(null);
    // const popperUpdateRef = usePropRef(popperUpdate);

    // const popperAnimationFrame = useCallback(() => {
    //   animationFrameId.current = requestAnimationFrame(popperAnimationFrame);
    //   if (popperUpdateRef.current) {
    //     popperUpdateRef.current();
    //   }
    // }, []);

    // useLayoutEffect(() => {
    //   popperAnimationFrame();
    //   return () => {
    //     cancelAnimationFrame(animationFrameId.current);
    //   };
    // }, []);

    const handleRef = useCallback(
      (node) => {
        setPopperElement(node);

        if (ref) {
          if (typeof ref === "function") {
            (ref as any)(node);
          } else if (isPlainObject(ref)) {
            ref.current = node;
          }
        }
      },
      [ref]
    );

    return (
      <div
        ref={handleRef}
        className={cp("popover")}
        style={popperStyles.popper}
        {...popperAttributes.popper}
      >
        {props.children}
      </div>
    );
  }
);

Popover.defaultProps = {
  strategy: "fixed",
  align: "start",
  position: "bottom",
  offsetX: 0,
  offsetY: 10,
};

export default Popover;
