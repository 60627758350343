import {
  createActionCreators,
  createReducerFunction,
  ImmerReducer,
} from "immer-reducer";
import { useMemo, useReducer } from "react";
import { AdvSelect2State, AdvSelectOption } from "../../types";

/** Only for debug purposes. It prevents the dropdown to close */
(window as any).keepDropdownOpen = false;
(window as any).keepDropdownOpenToggle = () => {
  (window as any).keepDropdownOpen = !(window as any).keepDropdownOpen;
  console.log("keepDropdownOpen", (window as any).keepDropdownOpen);
};

const initialAdvSelectState: AdvSelect2State = {
  items: [],
  itemsIsCached: false,
  query: null,
  page: null,
  totalElements: null,
  isLoading: false,
  firstTimeLoading: true,
  error: null,
  menuIsOpen: false,
  menuItemCursor: null,
  menuReachedBottom: false,
  isPaginated: false,
  focused: false,
};

class SelectStateReducer extends ImmerReducer<AdvSelect2State> {
  clearFetchedItems() {
    this.draftState.items = [];
    this.draftState.itemsIsCached = false;
    this.draftState.page = 0;
    this.draftState.firstTimeLoading = true;
    this.draftState.totalElements = 0;
    this.draftState.isPaginated = false;
    this.draftState.menuItemCursor = null;
  }

  beforeDataFetch() {
    this.draftState.isLoading = true;
    this.draftState.error = null;
  }

  afterDataFetch(options: {
    items: AdvSelectOption[];
    totalElements?: number;
    replace?: boolean;
    cache?: boolean;
  }) {
    this.draftState.isLoading = false;
    this.draftState.firstTimeLoading = false;
    this.draftState.menuReachedBottom = false;

    if (options.replace) {
      this.draftState.items = options.items;
    } else {
      this.draftState.items = [...this.draftState.items, ...options.items];
    }

    this.draftState.totalElements = options.totalElements ?? null;
    this.draftState.itemsIsCached = options.cache || false;

    if (typeof options.totalElements === "number") {
      this.draftState.isPaginated = true;
    } else {
      this.draftState.isPaginated = false;
    }
  }

  dataFetchError(err: any) {
    this.draftState.isLoading = false;
    this.draftState.error = err;
  }

  setItems(items: AdvSelectOption[]) {
    this.draftState.items = items;
    this.draftState.firstTimeLoading = false;
  }

  clearQuery() {
    this.draftState.query = null;
  }

  changeQuery(query: string) {
    if (query) {
      this.openMenu();
    }
    this.draftState.query = query;
    this.draftState.page = 0;
  }

  openMenu() {
    this.draftState.menuIsOpen = true;
  }

  openMenuWithFocus() {
    this.openMenu();
    this.setFocused(true);
  }

  closeMenu() {
    if ((window as any).keepDropdownOpen) {
      return;
    }

    this.draftState.isLoading = false;
    this.draftState.menuIsOpen = false;
    this.draftState.menuItemCursor = null;
    this.draftState.query = null;
  }

  closeMenuWithBlur() {
    this.closeMenu();
    this.setFocused(false);
  }

  resetCursor() {
    this.draftState.menuItemCursor = null;
  }

  moveCursorToBegin(forceOpenMenu?: boolean) {
    if (forceOpenMenu) {
      this.openMenu();
    }
    this.draftState.menuItemCursor = 0;
  }

  moveCursorDown() {
    this.draftState.menuItemCursor++;
  }

  moveCursorUp() {
    this.draftState.menuItemCursor--;
  }

  nextPage() {
    this.draftState.page++;
    this.draftState.menuReachedBottom = true;
  }

  updateItemAtIndex(index: number, item: AdvSelectOption) {
    this.draftState.items[index] = item;
  }

  setFocused(focused: boolean) {
    this.draftState.focused = focused;
  }
}

const StateActions = createActionCreators(SelectStateReducer);

const reducerFunction = createReducerFunction(SelectStateReducer);

const useCreateSelectState = (
  initialOverrideState: Partial<AdvSelect2State>
) => {
  const finalInitialState = useMemo(() => {
    return {
      ...initialAdvSelectState,
      ...initialOverrideState,
    };
  }, []);

  const [state, dispatch] = useReducer(reducerFunction, finalInitialState);

  return { state, dispatch };
};

export { StateActions };

export default useCreateSelectState;
