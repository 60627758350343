import React from "react";
import { useCalendarSharedContext } from "../../CalendarSharedContext";
import CalendarTimelineBodyGridCell from "./CalendarTimelineBodyGridCell";
import getDayKey from "../../utils/getDayKey";

export interface CalendarTimelineBodyGridCellsProps {}

const CalendarTimelineBodyGridCells: React.FC<
  CalendarTimelineBodyGridCellsProps
> = (props) => {
  const { days } = useCalendarSharedContext();

  return (
    <div className="rivile-calendar-body-grid-cells">
      {days.map((day, index) => {
        return <CalendarTimelineBodyGridCell key={getDayKey(day)} day={day} />;
      })}
    </div>
  );
};

export default CalendarTimelineBodyGridCells;
